<template>
  <main class="main">
    <h1>Skift password</h1>
    <p>Klik på <i>Send link</i> for at modtage et link til at skifte dit password.</p>
    <p class="link-section">Linket bliver sendt til <strong>{{props.email}}</strong></p>
    <div class="button-container">
      <secondary-button :disabled="isLoading" @click="handleCancel">Annuller</secondary-button>
      <primary-button :disabled="isLoading" @click="handleSendLink" class="margin-left">{{isLoading ? 'Sending...' : 'Send link'}}</primary-button>
    </div>
  </main>
</template>

<script setup>
import PrimaryButton from './PrimaryButton.vue';
import SecondaryButton from './SecondaryButton.vue';
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from '../firebase/config'
import {ref} from 'vue'
import { useToast } from 'vue-toastification'

const props = defineProps(['email'])
const emit = defineEmits(['cancel', 'confirm'])
const isLoading = ref(false)
const toast = useToast()

function handleCancel () {
    emit('cancel')
}

async function handleSendLink () {
    isLoading.value = true
    try {
    await sendPasswordResetEmail(auth, props.email)
    toast.success('Der er blevet sendt et link til din email');
    emit('confirm')    
    } catch (error) {
        if (error.code == 'auth/invalid-email') toast.error('Ugyldig email')
        else if (error.code == 'auth/user-not-found') toast.error('Der findes ingen bruger med den email')
        else if (error.code == 'auth/missing-email') toast.error('Indtast en email')
        else toast.error('Fejl')
    }
    isLoading.value = false
    emit('confirm')
}

</script>

<style scoped>
.link-section {
  margin-bottom: 20px;
}
.main {
  border-radius: 4px;
  width: 80%;
  height: 100vh;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.margin-left {
  margin-left: 4px;
}
.button-container {
  display: flex;
  margin-top: 10px;
}

h1 {
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 10px;

}
p {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 1rem;
    text-align: left;
}
</style>