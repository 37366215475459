export default [
  {
    label: "Navn, forening eller CVR-nummer",
    id: "invoice-name",
    placeholder: "Navn",
    type: "text",
    errorMessage: "Faktura: Navn mangler",
    formName: "invoiceName",
  },
  {
    label: "Gadenavn",
    id: "invoice-street-address",
    placeholder: "Gade",
    type: "text",
    errorMessage: "Faktura: Gade mangler",
    formName: "invoiceStreetAddress",
  },
  {
    label: "Gadenummer",
    id: "invoice-street-no",
    placeholder: "Nummer",
    type: "text",
    errorMessage: "Faktura: Nummer mangler",
    formName: "invoiceStreetNo",
  },
  {
    label: "Postnummer",
    id: "invoice-zip-code",
    placeholder: "Post",
    type: "number",
    errorMessage: "Faktura: Postnummer mangler",
    formName: "invoiceZipCode",
  },
  {
    label: "By",
    id: "invoice-city",
    placeholder: "By",
    type: "text",
    errorMessage: "Faktura: By mangler",
    formName: "invoiceCity",
  },
];
