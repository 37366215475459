<template>
    <main v-if="!confirmDelete" class="main">
        <h1 v-if="isAdmin" >Slet bruger og tilhørende vaskerum</h1>
        <h1 v-else>Slet bruger</h1>
        <p v-if="isAdmin">
            Din bruger er administrator. Hvis du sletter din bruger, så vil vaskerummet med følgende ID også blive slettet <b>{{ dbUser.laundryroom }}</b>.<br><br>
            Hvis du ønsker at overdrage administratorrollen til en anden bruger, skal du kontakte info@vaskerum.dk<br><br>
            Vil du slette din bruger og det tilhørende vaskerum?
            <!-- Your user in an admin. Deleting your user will also delete the laundry room with ID {{ dbUser.laundryroom }}.
            If you do so, all users assigned to the laundry room will loose access to it.
            If you want to transfer the admin role to another user, contact info@vaskerum.dk
            Do you want to delete your user and the laundry room? -->
        </p>
        <p v-else>Du er ved at slette din bruger, dette kan ikke fortrydes.</p>
        <div class="button-container">
            <secondary-button :disabled="isLoading" @click="handleCancel">Annuller</secondary-button>
            <primary-button :disabled="isLoading" @click="handleDeleteClick" class="margin-left">{{ isAdmin ? "Slet bruger og vaskerum" : "Slet bruger" }}</primary-button>
        </div>
    </main>
    <main v-if="confirmDelete && !confirmPassword" class="main">
        <h1 class="second-header">Er du sikker på, du vil slette din bruger?</h1>
        <div class="button-container margin-top">
            <secondary-button :disabled="isLoading" @click="handleCancel">Nej</secondary-button>
            <primary-button :disabled="isLoading" @click="handleConfirmDelete" class="margin-left">{{ isLoading ? 'Sletter...' : 'Ja, slet bruger.' }}</primary-button>
        </div>
    </main>
    <main v-if="confirmDelete" class="main">
        <h1>Der er brug for dit password</h1>
        <p>Du skal skrive dit password, for at kunne slette din bruger.</p>
        <!-- <label for="password">Password</label> -->
        <input v-model="password" id="password" placeholder="Password" type="password" />
        <div v-if="passwordError" class="error">Fejl</div>
        <div class="button-container margin-top">
            <secondary-button :disabled="isLoading" @click="handleCancel">Annuller</secondary-button>
            <primary-button :disabled="isLoading" @click="handleConfirmPassword" class="margin-left">OK, slet bruger</primary-button>
        </div>
    </main>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import PrimaryButton from '@/components/PrimaryButton.vue';
import SecondaryButton from '@/components/SecondaryButton.vue';
import { getAuth, deleteUser, reauthenticateWithCredential, EmailAuthProvider} from '@firebase/auth';

const store = useStore();
const router = useRouter();

const isAdmin = computed(() => store.state.user.uid === store.state.roomSettings.admin);
const dbUser = computed(() => store.state.userDBInfo);

const isLoading = ref(false);
const confirmDelete = ref(false);
const confirmPassword = ref(false);
const passwordError = ref(null);
const password = ref('');

const handleCancel = () => {
    router.push('/userinfo')
}

const handleDeleteClick = () => {
    confirmDelete.value = true;
}

const handleDeleteUserRelatedData = async (user, roomId) => {
    await store.dispatch('deleteBookings', {roomId: roomId, owner: user.uid} );
    if(isAdmin.value) {
            // Delete laundryroom
            await store.dispatch('deleteLaundryroom', roomId);     
    }
    await store.dispatch('deleteUser', user.uid);
}

const handleConfirmDelete = () => {
    confirmPassword.value = true;
}

const handleConfirmPassword = async () => {
    // Re-authenticate the user
    isLoading.value = true;
    const auth = getAuth();
    const user = auth.currentUser;
    const credential = EmailAuthProvider.credential(user.email, password.value);
    reauthenticateWithCredential(user, credential).then(async () => {
        // User re-authenticated. Delete bookings, delete user from realtime database, if admin, also update admin and roomIsActive in laundryrooms
        await handleDeleteUserRelatedData(user,dbUser.value.laundryroom);
        await deleteUser(user);
        await store.dispatch('logout');
        router.push('/login');
    }).catch((error) => {
        // An error ocurred
        passwordError.value = error.message;
    }).finally(() => {
        isLoading.value = false;
    })
}
</script>

<style scoped>
.second-header {
    margin-bottom: 40px;
}
.main {
  border-radius: 4px;
  width: 80%;
  height: 100vh;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.margin-left {
  margin-left: 4px;
}
.button-container {
    margin-top: 20px;
    display: flex;
}

.margin-top {
    margin-top: 16px;
}

h1 {
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0px;
}
p {
    margin-top: 24px;
    margin-bottom: 24px;
    font-size: 1rem;
    text-align: left;
}

input {
  display: block;
  margin: 6px 0;
  max-width: 100%;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  background-color: white;
  font-size: 16px;
  padding: 7px 18px;
  height: 40px;
}
.error {
  font-size: 16px;
  color: #ff5a5f;
}

input:focus {
  outline: none;
}
label {
  margin-bottom: 0px;
  color: #545454;
  font-weight: 600;
  font-size: 1rem;
}
</style>