<template>
  <div class="backgroundClass">
    <div class="outerWrapBox">
      <transition name="error-box">
        <div @click="errorMsg = ''" class="error-message" v-if="errorMsg">
          <font-awesome-icon class="exclamation-icon" icon="exclamation-circle" size="lg"/>
          {{ errorMsg }}
          <font-awesome-icon class="close-icon" icon="close" size="lg"/>
          </div>
      </transition>
      <!-- <img class="logo-wrap" src="/static/LogoTest.png" alt="Mountain View"> -->
      <p class="stepText">Step: {{stepCounter}} / 4</p>

      <div v-if="stepCounter == 1" class="whiteBackground">
        <div class="headerText">
          Vælg åbningstid
        </div>
        <div class="descriptionText">
          Åbningstiden er den periode hvor vaskerummet må benyttes.
        </div>
        <div class="timeSelectorWrap">
          <div class="timerWrap">
            <div class="clockWrap hours">
              <button v-on:click="changeHour('+', 'start')" class="iconWrapPlus">
                <font-awesome-icon class="angle-up" icon="angle-up"></font-awesome-icon>
              </button>
              <button v-on:click="changeHour('-', 'start')" class="iconWrapMinus">
                <font-awesome-icon class="angle-down" icon="angle-down"></font-awesome-icon>
              </button>
              {{bookingTimeComputed.startHour}}
            </div>
            <div class="colon">
              :
            </div>
            <div class="clockWrap minutes">
              <button v-on:click="changeMinutes('+', 'start')" class="iconWrapPlus">
                <font-awesome-icon class="angle-up" icon="angle-up"></font-awesome-icon>
              </button>
              <button v-on:click="changeMinutes('-', 'start')" class="iconWrapMinus">
                <font-awesome-icon class="angle-down" icon="angle-down"></font-awesome-icon>
              </button>
              {{bookingTimeComputed.startMinutes}}
            </div>
            -
            <div class="clockWrap hours">
              <button v-on:click="changeHour('+', 'end')" class="iconWrapPlus">
                <font-awesome-icon class="angle-up" icon="angle-up"></font-awesome-icon>
              </button>
              <button v-on:click="changeHour('-', 'end')" class="iconWrapMinus">
                <font-awesome-icon class="angle-down" icon="angle-down"></font-awesome-icon>
              </button>
              {{bookingTimeComputed.endHour}}
            </div>
            <div class="colon">
              :
            </div>
            <div class="clockWrap minutes">
              <button v-on:click="changeMinutes('+', 'end')" class="iconWrapPlus">
                <font-awesome-icon class="angle-up" icon="angle-up"></font-awesome-icon>
              </button>
              <button v-on:click="changeMinutes('-', 'end')" class="iconWrapMinus">
                <font-awesome-icon class="angle-down" icon="angle-down"></font-awesome-icon>
              </button>
              {{bookingTimeComputed.endMinutes}}
            </div>
          </div>
        </div> 
      </div>
      <div v-if="stepCounter == 2" class="whiteBackground">
        <div class="headerText">
          Vælg maskiner
        </div>
        <div class="descriptionText">
          Vælg antallet af vaskemaskiner og tørretumbler i vaskerummet.<br />
        </div>
        <div class="machineSelectorWrap">
          <div class="headTextMachineSelection">
            Antal vaskemaskiner
          </div>
          <div class="machineNumberWrap">
            <button v-for="(button, index) in laundryBtn"
            v-on:click="laundryBtnClick(index)"
            :key="index"
            v-bind:class="{ machineNumberSelection: laundryBtn[index].isActive }"
            class="machineNumberBtn">
              {{laundryBtn[index].value}}
            </button>
          </div>
          <div class="headTextMachineSelection">
            Antal tørretumblere
          </div>
          <div class="machineNumberWrap">
            <button v-for="(button, index) in dryerBtn"
            v-on:click="dryerBtnClick(index)"
            :key="index"
            v-bind:class="{ machineNumberSelection: dryerBtn[index].isActive }"
            class="machineNumberBtn">
              {{laundryBtn[index].value}}
            </button>
          </div>
        </div>
      </div>
      <!--Admin start -->
      <div v-if="stepCounter == 3" class="whiteBackground">
        <div class="headerText">
          Admin oplysninger
        </div>
        <div class="descriptionText">
          Indtast oplysningerne for <br />administrator brugeren
        </div>
        <div class="inputWrap">
          <input v-model="name" placeholder="Fornavn" type="text" >
          <!-- <div v-if="errorMsgName" class="errorMsg">
            {{errorMsgName}}
          </div> -->

          <input v-model="email" placeholder="E-mail" type="email" >
          <!-- <div v-if="errorMsgEmail" class="errorMsg">
            {{errorMsgEmail}}
          </div> -->

          <input v-model="password" placeholder="Password" type="password">
          <!-- <div v-if="errorMsgPassword" class="errorMsg">
            {{errorMsgPassword}}
          </div> -->

          <input v-model="rePassword" placeholder="Retype Password" type="password">
          <!-- <div v-if="errorMsgRePassword" class="errorMsg">
            {{errorMsgRePassword}}
          </div> -->
        </div>
        <!-- <div v-if="errorMsg" class="errorMsg">
          {{errorMsg}}
        </div> -->
      </div>
      <!--Admin end -->
      <div v-if="stepCounter == 4" class="whiteBackground">
        <div class="headerText">
          Valgte indstillinger
        </div>
        <div class="infoOverview">
          <div class="descriptionText">
            <div class="headText">
              VaskerumsID
            </div>
            <div class="contentText">
              {{laundryRoomId}}
            </div>
            <div class="headText">
              Åbningstid
            </div>
            <div class="contentText">
              Åben fra kl. {{bookingTimeComputed.startHour}}:{{bookingTimeComputed.startMinutes}} til
              {{bookingTimeComputed.endHour}}:{{bookingTimeComputed.endMinutes}}
            </div>
            <div class="headText">
              Maskiner
            </div>
            <div class="contentText">
              <div v-if="laundryMachine > 1">
                Vaskemaskiner: {{laundryMachine}}
              </div>
              <div v-else>
                Vaskemaskine: {{laundryMachine}}
              </div>
              <div v-if="dryerMachine > 1">
                Tørretumblere: {{dryerMachine}}
              </div>
              <div v-else>
                Tørretumbler: {{dryerMachine}}
              </div>
            </div>
              <div class="headText">
                Admin
              </div>
              <div class="contentText">
                <div>
                  {{email}}
                </div>
                <div>
                  {{name}}
                </div>
            </div>
          </div>
        </div>
      </div>
      <button v-if="stepCounter > 1" v-on:click="backBtn" class="btnBack">Tilbage</button>
      <button v-if="stepCounter < 4" v-on:click="nextBtn" class="btnNext">Næste</button>
      <button v-if="stepCounter == 4" v-on:click="createBtn" class="btnNext" :disabled="disabledActive">Opret</button>
      <router-link class="loginLink" to="/login">Cancel</router-link>
    </div>
    <!-- <transition name="fade">
      <info-modal v-if="showModal" @close="showModal = false">
        <div slot="body">{{modalInfoText}}</div>
      </info-modal>
    </transition> -->
  </div>
</template>

<script>
import { getDatabase, ref, child, get, set } from "firebase/database"
import { createUserWithEmailAndPassword } from 'firebase/auth'
import { auth } from '../firebase/config'
// import infoModal from '@/components/infoModal.vue'
export default {
  data () {
    return {
      laundryBtn: {
        btn1: { isActive: false, value: 0},
        btn2: { isActive: true, value: 1},
        btn3: { isActive: false, value: 2},
        btn4: { isActive: false, value: 3},
        btn5: { isActive: false, value: 4},
        btn6: { isActive: false, value: 5},
      },
      dryerBtn: {
        btn1: { isActive: false, value: 0},
        btn2: { isActive: true, value: 1},
        btn3: { isActive: false, value: 2},
        btn4: { isActive: false, value: 3},
        btn5: { isActive: false, value: 4},
        btn6: { isActive: false, value: 5},
      },
      bookingTime: {startHour: 7, startMinutes: 0, endHour: 22, endMinutes: 0},
      iconScale: 1.8,
      stepCounter: 1,
      laundryMachine: 1,
      dryerMachine: 1,
      laundryRoomId: '',
      name: '',
      email: '',
      password: '',
      rePassword: '',
      laundryRoom: '',
      errorMsgLaundryRoom: '', //Forkert vaskerums id
      errorMsgPassword: '', //Koden er ikke stærk nok
      errorMsgRePassword: '', //Kodeord er ikke ens
      errorMsgEmail: '', //Ugyldig E-mail
      errorMsg: '',
      disabledActive: false,
      showModal: false,
      modalInfoText: '',
      errorMessageObj: [
        {EN: 'auth/weak-password', DK: 'Koden skal være mindst 6 tegn'},
        {EN: 'auth/email-already-in-use', DK: 'E-mailen er allerede i brug'},
        {EN: 'auth/invalid-email', DK: 'Ugyldig email'},
      ]
    }
  },
  // components: {
  //   'info-modal': infoModal
  // },
  methods: {
    createUnickId: function() {
      //Create RandomID
      var randomId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
      randomId = randomId.substring(1, 7);
      var self = this
      //Check if RandomID exisit in DB
      const dbRef = ref(getDatabase())
      get(child(dbRef, `list/${randomId}`)).then((snapshot) => {
        if (snapshot.exists()) {
          var randomId2 = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
          randomId2 = randomId.substring(1, 7);
          get(child(dbRef, `list/${randomId2}`)).then((snapshot2) => {
            //If it also exsist a second time, create an error alert
            if (snapshot2.exists()) {
              this.$router.push('/')
            } else {
              self.laundryRoomId = randomId2
            }
          })
        } else {
          self.laundryRoomId = randomId
        }   
      })
    },
    validateEmail: function() {
      var x = this.email
      var atpos = x.indexOf("@")
      var dotpos = x.lastIndexOf(".")
      if (atpos <1 || dotpos<atpos+2 || dotpos+2>=x.length) {
        return false
      } else {
        return true
      }
    },
    checkUser: function() {
      // var isEmailValid = this.validateEmail()
      if (this.name.length < 2) {
        this.errorMsg = 'Indtast fornavn'
        return false
      } else if (this.validateEmail() == false) {
        this.errorMsg = 'Ugyldig email'
        return false
      } else if (this.password.length < 6) {
        this.errorMsg = 'Koden skal være mindst 6 tegn'
        return false
      } else if (this.password != this.rePassword) {
        this.errorMsg = 'Koderne er ikke ens'
        return false
      } else {
        return true
      }
    },
    laundryBtnClick(btnName) {
      let buttons = this.laundryBtn
      for (var button in buttons) {
        if(button == btnName) {
          buttons[button].isActive = true
          this.laundryMachine = buttons[button].value
        } else {
          buttons[button].isActive = false
        }
      }
      this.laundryBtn = buttons
    },
    dryerBtnClick(btnName) {
      let buttons = this.dryerBtn
      for (var button in buttons) {
        if(button == btnName) {
          buttons[button].isActive = true
          this.dryerMachine = buttons[button].value
        } else {
          buttons[button].isActive = false
        }
      }
      this.dryerBtn = buttons
    },
    changeHour(plusMinus, startEnd) {
      let start = this.bookingTime.startHour
      let end = this.bookingTime.endHour
      if(plusMinus == '+'){
        if (startEnd == 'start') {
          if (start == 23 ) {
            start = 1
          } else {
            start = start + 1
          }
        } else if (startEnd == 'end') {
          if (end == 23 ) {
            end = 1
          } else {
            end = end + 1
          }
        }
      } else if (plusMinus == '-') {
        if (startEnd == 'start') {
          if (start == 1) {
            start = 23
          } else {
            start = start - 1
          }
        } else if (startEnd == 'end') {
          if (end == 1) {
            end = 23
          } else {
            end = end - 1
          }
        }
      }
      this.bookingTime.startHour = start
      this.bookingTime.endHour = end
    },
    changeMinutes(plusMinus, startEnd) {
      let start = this.bookingTime.startMinutes
      let end = this.bookingTime.endMinutes
      if(plusMinus == '+'){
        if (startEnd == 'start') {
          if (start == 45) {
            start = 0
          } else {
            start = start + 15
          }
        } else if (startEnd == 'end') {
          if (end == 45) {
            end = 0
          } else {
            end = end + 15
          }
        }
      } else if (plusMinus == '-') {
        if (startEnd == 'start') {
          if (start == 0) {
            start = 45
          } else {
            start = start - 15
          }
        } else if (startEnd == 'end') {
          if (end == 0) {
            end = 45
          } else {
            end = end - 15
          }
        }
      }
      this.bookingTime.startMinutes = start
      this.bookingTime.endMinutes = end
    },
    nextBtn() {
      if(this.stepCounter == 3) {
        var promis = this.checkUser()
        if(promis != false) {
          this.stepCounter = this.stepCounter + 1
        }
      } else {
        this.stepCounter = this.stepCounter + 1
      }
    },
    backBtn() {
      this.stepCounter = this.stepCounter - 1
    },
    async createBtn()  {
      // alert('Create Room!!! ')
      //Create the user in google auth
      this.disabledActive = true
      var self = this
      try {
        const res = await createUserWithEmailAndPassword(auth, this.email, this.password)
        if (res) {
          const activeUser = res.user.uid
          const laundryRoomId = this.laundryRoomId
          const openingPeriod = this.bookingTime
          const name = this.name
          const email = this.email
          const settingsObj = {
            startHour: openingPeriod.startHour,
            startMinutes: openingPeriod.startMinutes,
            endHour: openingPeriod.endHour,
            endMinutes: openingPeriod.endMinutes,
            admin: activeUser,
            washMachineAmount: this.laundryMachine,
            dryerMachineAmount: this.dryerMachine,
            subscription: false,
            createdTimeDate: new Date().getTime(),
            roomIsActive: true,
          }
          const db = getDatabase()
          try {
            await set(ref(db, 'users/' + activeUser), {laundryroom: laundryRoomId, name: name, email: email}) 
          } catch (error) {
            throw 1
          }
          try {
            await set(ref(db, 'list/' + laundryRoomId), true) 
          } catch (error) {
            throw 2
          }
          try {
           await set(ref(db,'laundryrooms/' + laundryRoomId + '/settings/'), settingsObj) 
          } catch (error) {
            throw 3
          }
          this.disabledActive = false
          this.$store.dispatch('setAllInitialInfo')
          this.$router.push('/')
        } else {
          console.log('Fejl in CreateUserWithEmailAndPassword FB')
        }
      } catch (error) {
        //If err is 1 Delete user
        //If err is 2 Delete user + delete user DB Info + Create new Room ID
        //If err is 3 Delete user + delete user DB Info + Create new Room ID
        this.errorMsg = ''
        if(error == 1) this.errorMsg = `Fejl. Kontakt info@vaskerum.dk med fejlkoden: 101`  //Error: 1 Problem with set user
        if(error == 2) this.errorMsg = `Fejl. Kontakt info@vaskerum.dk med fejlkoden: 102` //`Error: 2 Problem with set list`
        if(error == 3) this.errorMsg = `Fejl. Kontakt info@vaskerum.dk med fejlkoden: 103` //`Error: 3 Problem with set room settings`
        if(error == 1 || 2 || 3 ) this.$store.dispatch('logout')
        for (const element of this.errorMessageObj) {
          if(element.EN == error.code) this.errorMsg = element.DK
        }
        if(this.errorMsg == '') this.errorMsg = 'Fejl, prøv igen. Hvis fejlen fortsætter kontakt info@vaskerum.dk'
        this.disabledActive = false
      }
    }
  },

  computed: {
    isActive: function() {

    },
    bookingTimeComputed: function() {
      let startHour = this.bookingTime.startHour.toString()
      let startMinutes = this.bookingTime.startMinutes.toString()
      let endHour = this.bookingTime.endHour.toString()
      let endMinutes = this.bookingTime.endMinutes.toString()

      if (startHour.length == 1) {
        startHour = '0' + startHour
      }
      if (startMinutes.length == 1) {
        startMinutes = '0' + startMinutes
      }
      if (endHour.length == 1) {
        endHour = '0' + endHour
      }
      if (endMinutes.length == 1) {
        endMinutes = '0' + endMinutes
      }

      const bookingTime = {
        startHour: startHour,
        startMinutes: startMinutes,
        endHour: endHour,
        endMinutes: endMinutes
      }
      return bookingTime
    }
  },
  mounted(){
  this.createUnickId()
  },
  watch: {
    name: function () {
      if (this.name.length >= 2 )
      {this.errorMsg = ''}
    },
    password: function () {
      if (this.password.length >= 6 )
      {this.errorMsg = ''}
    },
    rePassword: function () {
      if (this.password == this.rePassword )
        {this.errorMsg = ''}
    },
    email: function () {
      if (this.validateEmail()) {
        this.errorMsg = ''
      }
    }
  }
}
</script>
<style scoped>
* {
  box-sizing: border-box;
}
.close-icon {
  color: rgb(0 0 0 / 30%);
  margin-left: 10px;

}

.exclamation-icon {
  margin-right: 10px;

}

.error-message {
  cursor: pointer;
  text-align: left;
  position: absolute;
  top: -45px;
  color: #000000;
  font-weight: 500;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-color: black;
  padding: 11px;
  border-radius: 4px;
  box-shadow: 5px 5px 5px 0px rgb(0 0 0 / 30%);
  
}
/* .errmsg-enter-from{
  opacity: 0;
  transform: translateY(-300px);
} 
.errmsg-enter-to{
  opacity: 1;
  transform: translateY(0);
}  */
.error-box-enter-active {
  /* transition: all 1.5s ease; */
  animation: wobble 0.5s ease;
}
.error-box-leave-from {
  opacity: 1;
}
.error-box-leave-to {
  opacity: 0;
}
.error-box-leave-active {
  transition: all 1s ease;
}

@keyframes wobble {
  0% {transform: translateY(-300px); opacity: 0}
  50% {transform: translateY(0); opacity: 1}
  60% {transform: translateX(8px)}
  70% {transform: translateX(-8px)}
  80% {transform: translateX(4px)}
  90% {transform: translateX(-4px)}
  100% {transform: translateX(0)}
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .4s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}


.backgroundClass {
  text-align: center;
  position: absolute;
  height: 100vh;
  left: 0;
  right: 0;
  background: linear-gradient(rgba(0, 186, 224, 1), rgba(0, 186, 224, 0.5));
}

.outerWrapBox {
  border-radius: 4px;
  width: 300px;
  height: 460px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.loginLink {
  position: absolute;
  bottom: 5px;
  right: 5px;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.9);
  font-size: 14.4px;
}

.btnNext, .btnBack {
  margin-top: 28px;
  padding: 10px 20px;
  cursor: pointer;
  /* color: #00BAE0; */
  /* color: darkgrey; */
  color: rgba(0, 0, 0, 0.6);
  border: 0;
  /* font-size: 20px; */
  font-weight: 400;
  /* background: #68b3c8; */
  background-color: white;
  border-radius: 4px;
  /* box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.2); */
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.3);
}

.btnBack {
 margin-right: 10px;
}

.infoOverview {
  text-align: left;
  margin-top: 0px;
}

.headText {
  text-align: left;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 3px;
}

.headTextMachineSelection {
  color: rgba(0,0,0,0.8);
  text-align: left;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 5px;
}

.timeSelectorWrap button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

button {
  outline: inherit;
  cursor: pointer;
}

.machineNumberWrap {
  /* background-color: orange; */
  background-color: white;
  width: 100%;
}
.machineNumberBtn {
  color: rgba(0,0,0,0.8);
  background-color: white;
  width: 15%;
  padding: 0;
  border: 0;
  outline: none;
}

.machineSelectorWrap {
  margin-top: 35px;
}

.machineNumberSelection {
  /* background-color: orange; */
  border: 1px solid rgba(0,0,0,0.3);
  border-radius: 4px;
}

p {
  /* font-weight: 600; */
  color: rgba(255, 255, 255, 0.9);
  /* font-size: 22.4px; */
}

.stepText {
  /* font-weight: 600; */
  font-size: 22.4px;
  margin-top: 0px;
}

.logo-wrap {
  display: block;
  width: 100%;
  margin-bottom: 30px;
}

.headerText {
  /* text-align: left; */
  font-size: 24px;
  font-weight: 400;
  color: rgba(0,0,0,0.8);
  margin-bottom: 15px;
}
.contentText {
  text-align: left;
  padding-left: 0px;
}

.descriptionText {
  text-align: center;
  font-size: 14.4px;
  color: rgba(0,0,0,0.8);
}

/* time selection */
.whiteBackground {
  height: 280px;
  background: white;
  padding: 10px 20px;
  border-radius: 4px;
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.3);
}
.timeSelectorWrap {
  margin-top: 65px;
  margin-bottom: 50px;
}
.timerWrap {
  /* background-color: orange; */
  font-size: 24px;
  font-weight: bold;
  width: 250px;
  height: 50px;
  line-height: 50px;
  margin: 0 auto;
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 4px;
  color: rgba(0,0,0,0.8);
}

.clockWrap {
  width: 30px;
  display: inline-block;
  position: relative;
}
.hours {
  text-align: center;
  /* margin-right: -5px; */
}
.minutes {
  text-align: center;
  /* margin-left: -5px; */
}
.timeSelectorWrap .iconWrapPlus {
  cursor: pointer;
  width: 30px;
  position: absolute;
  top: -40px;
  color: rgba(0,0,0,0.4);
  /* left: 6px; */
}

.timeSelectorWrap  .iconWrapMinus {
  cursor: pointer;
  width: 30px;
  position: absolute;
  top: 40px;
  color: rgba(0,0,0,0.4);
  /* left: 6px; */
}

.colon {
  display: inline-block;
}

.inputWrap {
  margin-top: 20px;
}

.inputWrap input {
  margin: 2px 0;
  width: 100%;
  background-color: white;
  border: 0;
  border-bottom: 1.3px solid #e8e7e3;
  border-radius: 0px;
  /* color: #66615b; */
  font-size: 16px;
  padding: 7px 10px;
  height: 30px;
}
.inputWrap input:focus {
  outline: none;
}
.errorMsg {
  color: rgba(223,77,80,0.9);
  text-align: left;
}


</style>
