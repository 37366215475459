<template>
  <div class="backgroundClass">    
    <div class="loginBox">  
      <transition name="error-box">
        <div @click="error = null" class="error-message" v-if="error">
          <font-awesome-icon class="exclamation-icon" icon="exclamation-circle" size="lg"/>
          {{ error }}
          <font-awesome-icon class="close-icon" icon="close" size="lg"/>
          </div>
      </transition>
      <img class="logo-wrap" src="../assets/LogoWhite.png" alt="Vaskerum Logo">
      <input v-model="email" placeholder="E-mail" type="email" >
      <input v-model="password" placeholder="Password" type="password">
      <button type="button" @click="handleSubmit" :disabled="loginBtnDisabled">Log in</button>
      <router-link class="createuser" to="/choose">Opret bruger</router-link>
      <router-link class="resetPassword" to="/resetpassword">Glemt kode</router-link>
    </div>
    <!-- <button @click="error = !error"> click </button> -->
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  setup() {
    const email = ref('')
    const password = ref('')
    const error = ref(null)
    const loginBtnDisabled = ref(false)
    const errorMessageObj = [
      {EN: 'auth/invalid-email', DK: 'Ugyldig Email'},
      {EN: 'auth/user-disabled', DK: 'Brugeren er deaktiveret'},
      {EN: 'auth/user-not-found', DK: 'Brugeren blev ikke fundet'},
      {EN: 'auth/wrong-password', DK: 'Forket kode'},
      {EN: 'auth/too-many-requests', DK: 'For mange forsøg'}
    ]

    const store = useStore()
    const router = useRouter()

    const handleSubmit = async () => {
      error.value = null
      try {
        loginBtnDisabled.value = true
        await store.dispatch('login', {
          email: email.value,
          password: password.value
        })
        router.push('/')
        loginBtnDisabled.value = false
      }
      catch (err) {
        for (const element of errorMessageObj) {
          if(element.EN == err.code) error.value = element.DK
        }
        if (!error.value) error.value = 'Fejl'
        loginBtnDisabled.value = false
      }
    }
    return { handleSubmit, email, password, error, loginBtnDisabled}
  }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.close-icon {
  position: absolute;
  right: 12px;
  color: rgb(0 0 0 / 30%);
}

.exclamation-icon {
  margin-right: 10px;

}

.error-message {
  cursor: pointer;
  text-align: left;
  position: absolute;
  top: -63px;
  color: #000000;
  font-weight: 500;
  width: 300px;
  height: 42px;
  background-color: white;
  border-color: black;
  padding: 11px;
  border-radius: 4px;
  box-shadow: 5px 5px 5px 0px rgb(0 0 0 / 30%);
  
}
/* .errmsg-enter-from{
  opacity: 0;
  transform: translateY(-300px);
} 
.errmsg-enter-to{
  opacity: 1;
  transform: translateY(0);
}  */
.error-box-enter-active {
  /* transition: all 1.5s ease; */
  animation: wobble 0.5s ease;
}
.error-box-leave-from {
  opacity: 1;
}
.error-box-leave-to {
  opacity: 0;
}
.error-box-leave-active {
  transition: all 1s ease;
}

@keyframes wobble {
  0% {transform: translateY(-300px); opacity: 0}
  50% {transform: translateY(0); opacity: 1}
  60% {transform: translateX(8px)}
  70% {transform: translateX(-8px)}
  80% {transform: translateX(4px)}
  90% {transform: translateX(-4px)}
  100% {transform: translateX(0)}
}

.backgroundClass {
  position: absolute;
  height: 100vh;
  left: 0;
  right: 0;
  background: linear-gradient(rgba(0, 186, 224, 1), rgba(0, 186, 224, 0.5));
}

.loginBox {
  border-radius: 4px;
  width: 300px;
  height: 300px;
  text-align:center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.userCreatedMsg {
  color: green;
  text-align: left;
  margin-bottom: 10px;
  font-weight: 600;
}

.createuser {
  display: inline-block;
  position: absolute;
  bottom: 5px;
  right: 5px;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.9rem;
  border-radius: 2px;
  padding: 2px 0px 2px 10px;
}

.resetPassword {
  display: inline-block;
  position: absolute;
  bottom: 5px;
  left: 5px;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.9rem;
  border-radius: 2px;
  padding: 2px 0px 2px 10px;
}

button {
  margin-top: 20px;
  padding: 10px 20px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
  border: 0;
  font-weight: 400;
  background-color: white;
  border-radius: 4px;
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.3);
}

input {
  margin: 6px 0;
  width: 100%;
  border: 0px;
  border-radius: 4px;
  background-color: white;
  font-size: 16px;
  padding: 7px 18px;
  height: 40px;
}

input:focus {
  outline: none;
}

.logo-wrap {
  display: block;
  width: 100%;
  margin-bottom: 30px;
}
</style>
