<template>
  <div class="main">
    <div class="title">
      {{ title }}
    </div>
    <div :class="`content-section ${isEditable && showBorder ? 'border-bottom' : ''}`">
      <input
        v-if="!showButton && !customSlot"
        :value="content"
        :placeholder="placeholder"
        :type="type"
        class="content"
        readonly
      />
      <slot v-if="customSlot"> </slot>
      <div @click="$emit('edit', title)" v-if="isEditable && !showButton" class="edit">
        <font-awesome-icon icon="edit" />
      </div>
     <secondary-button
        v-if="showButton"
        @click="$emit('buttonClick', $event)"
        class="changePasswordButton"
      >
        {{ buttonText }}
      </secondary-button>
    </div>
  </div>
</template>

<script>
import SecondaryButton from "@/components/SecondaryButton";

export default {
  components: { SecondaryButton },
  props: {
    title: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    to: {
      type: String,
      default: "#",
    },
    placeholder: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    showButton: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: "",
    },
    customSlot: {
      type: Boolean,
      default: false,
    },
    showBorder: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped>
.main {
  text-align: left;
  width: 100%;
  margin: 6px 0px;
}
.title {
  color: #545454;
  font-weight: 600;
  font-size: 1rem;
}
.content {
  color: #838383;
  font-size: 0.9rem;
  margin-top: 4px;
  padding-bottom: 4px;
  flex: 1;
  height: 40px;
  border: none;
  background: transparent;
}
input:focus {
  outline: none;
}
.edit {
  color: #9ca3af;
  cursor: pointer;
  flex: 0;
  margin-bottom: 2px;
  scale: 130%
}
.content-section {
  display: flex;
  align-items: center;
}
.border-bottom {
  border-bottom: 2px solid #e5e7eb;
}
.changePasswordButton {
  margin-top: 16px;
  margin-bottom: 16px;
}
</style>
