<template>
  <main class="main">
    <h1>Forklaring på vaskerumsregler</h1>

    <h2>Tidsbegrænsning på bookinger</h2>
      <p>
      Tidsbegrænsning på bookinger fastsætter den minimale og/eller maksimale længde for hver enkelt booking. 
      <br>Den minimale længde på en booking kan ikke være mindre end 15 minutter.
      <br>
      <span v-if="isAdmin">
        <br>
        Vær opmærksom på, at hvis du angiver en minimums- eller maksimumslængde for bookinger, 
        vil et eventuelt bookinginterval under <i>"Bookinginterval"</i> blive nulstillet.
      </span>
    </p>
    <h2>Bookinginterval</h2>
    <p>
      Bookinginterval fastsætter en foruddefineret længde for hver enkelt reservation. For eksempel, hvis dette er indstillet til at være 45 minutter, vil en booking altid være 45 minutter og kan ikke ændres af brugerne.
    <br>
      <span v-if="isAdmin">
        <br>
        Vær opmærksom på, at hvis du angiver en foruddefineret reservationslængde, 
        vil eventuelle minimums- og maksimumslængder for booking under <i>"Tidsbegrænsning på bookinger"</i> blive nulstillet.
      </span>
    </p>
    <h2>Timeslots</h2>
    <p>
      Timeslots er foruddefinerede vasketider, som brugerne af vaskerummet kan vælge mellem, når de skal bestille tid. Når Timeslots er aktiv, er det derfor ikke muligt for brugerne at bestemme start- og sluttidspunktet samt længden af deres bookinger.
    <br>
      <span v-if="isAdmin">
        <br>
        Vær opmærksom på, at hvis Timeslots er aktiv, vil de andre regler i vaskerummet blive nulstillet. Vaskerummets indstillinger for åbningstiden vil dog ikke blive ændret. Derfor vil eventuelle timeslots, som er uden for åbningstiden, ikke kunne reserveres af brugerne.
      </span>
    </p>
    <h2>Tilladte aktive bookinger</h2>
    <p>
      Tilladte aktive bookinger angiver det samlede antal aktive bookinger, 
      en bruger kan have. Dette gælder på tværs af alle maskiner, både vaskemaskiner og 
      tørremaskiner. Denne grænse tager kun højde for antallet af bookinger og ikke for 
      varigheden af hver enkelt booking.
    </p>

    



    <!-- <h2>Predefined timeslots</h2>
    <p>
      This is predefined timeslots that the users can select from. When this i
      active it is not possible for the users to customize their own bookings.
      They can only select from the list of predefined timeslots.
    </p>

    <h2>Max daily time</h2>
    <p>
      This defines the total amount of booked time a user is allowed to have on
      the same day. E.g. if the limit is four hours and the user has two
      bookings of two hours on the same day, then the user has reached the
      limit.
    </p>

    <h2>Max daily bookings</h2>
    <p>
      This limit only takes into account the amount of bookings on the same day
      and it does not look at the time of each booking. This means that if the
      limit is three bookings the user cannot have more than three active
      bookings on the same day, regardless of the length of each of them.
    </p>

    <h2>Tilladte aktive bookinger</h2>
    <p>
      This limit defines the totalt amount of bookings a user can have in the
      future at any given time. Meaning from the current date/time and forward.
      This limit only consider the amount of bookings and does not take into
      account the length of each of them.
    </p> -->

    <secondary-button @click="emit('close')">OK</secondary-button>
  </main>
</template>

<script setup>
import SecondaryButton from "../components/SecondaryButton.vue";
import { computed } from 'vue';
import { useStore } from 'vuex';


const store = useStore();
const isAdmin = computed(() => store.state.user.uid === store.state.roomSettings.admin);
const emit = defineEmits(["close"]);
</script>

<style scoped>
.main {
  padding: 30px 10%;
  margin: auto;
}
h1 {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 16px;
}
h2 {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0px;
}
p {
  margin-top: 6px;
  margin-bottom: 30px;
  color: #545454;
}
</style>
