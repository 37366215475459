<template>
  <div class="main">
    <h1>Bookinginterval</h1>
    <p>
      Bookinginterval fastsætter en foruddefineret længde for hver enkelt reservation. For eksempel, hvis dette er indstillet til at være 45 minutter, vil en booking altid være 45 minutter og kan ikke ændres af brugerne.
    </p>
    <p>
      Vær opmærksom på, at hvis du angiver en foruddefineret reservationslængde, 
      vil eventuelle minimums- og maksimumslængder for booking under <i>"Tidsbegrænsning på bookinger"</i> blive nulstillet.
    </p>

    

    <h2>Bookinginterval</h2>
    <div class="list">
      <button
        v-for="(value, label) in possibleIntervals"
        class="button"
        :class="{ active: currentInterval === value }"
        @click="currentInterval = value"
      >
        {{ label }}
      </button>
    </div>

    <div class="action-buttons">
      <secondary-button :disabled="isLoading" @click="emit('close')">
        Annuller
      </secondary-button>
      <primary-button
        :disabled="isLoading"
        @click="handleChangeBookingInterval"
      >
        Gem
      </primary-button>
    </div>
  </div>
</template>

<script setup>
import PrimaryButton from "./PrimaryButton.vue";
import SecondaryButton from "./SecondaryButton.vue";
import { ref } from "vue";
import { useToast } from "vue-toastification";
import { getDatabase, ref as firebaseRef, update } from "firebase/database";
import { useStore } from "vuex";

const possibleIntervals = {
  "30 min": 30,
  "45 min": 45,
  "1 time": 60,
  "1t 15 min": 75,
  "1t 30 min": 90,
  "1t 45 min": 105,
  "2 timer": 120,
  "2t 30 min": 150,
  "3 timer": 180,
  Ingen: "No limit",
};

const emit = defineEmits(["close"]);

const store = useStore();
const toast = useToast();
const isLoading = ref(false);
const currentInterval = ref(
  store.state.roomSettings.rules?.bookingInterval || "No limit"
);

const handleChangeBookingInterval = async () => {
  const bookingInterval = parseInt(currentInterval.value) || null;

  try {
    isLoading.value = true;

    const db = getDatabase();
    const laundryRoomId = store.state.userDBInfo.laundryroom;

    await update(
      firebaseRef(db, `laundryrooms/${laundryRoomId}/settings/rules/`),
      { bookingInterval }
    );

    store.dispatch("disableRulesExceptFor", "bookingInterval");

    toast.success("Vaskerummets regler er blevet opdateret.");

    store.commit("setRules", {
      ...store.state.roomSettings.rules,
      bookingInterval,
    });

    emit("close");
  } catch (error) {
    toast.error("Vaskerummets regler blev ikke gemt.");
  } finally {
    isLoading.value = false;
  }
};
</script>

<style scoped>
.main {
  padding: 30px 10%;
  margin: auto;
}
h1 {
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0px;
}
h2 {
  margin-top: 20px;
  color: #545454;
  font-weight: 600;
  font-size: 1rem;
}
p {
  margin-top: 24px;
  margin-bottom: 6px;
  font-size: 1rem;
  color: #545454;
}
.list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 6px;
}
.button {
  background-color: white;
  border: 1px solid #808080;
  border-radius: 4px;
  color: #545454;
  width: 100%;
  font-size: 14px;
}
.active {
  background-color: #808080;
  color: white;
}
.action-buttons {
  margin-top: 32px;
  display: flex;
  gap: 20px;
}
</style>
