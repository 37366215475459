<template>
  <div>
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">

            <div class="modal-header">
              <slot name="header">
                <icon class="iconClass" name="exclamation-circle" scale="2.5"></icon>
              </slot>
            </div>

            <div class="modal-body">
              <slot name="body">

              </slot>
            </div>
            <div class="modal-small-text">
              <slot name="smalltext">

              </slot>
            </div>

            <div class="modal-footer">
              <slot name="footer">

                <button class="modal-default-button" @click="$emit('close')">
                  OK
                </button>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
}
</script>

<style scoped>
  button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

  .modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
  }

  .modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  }

  .iconClass {
    /* display: none; */
    color: rgba(0, 0, 0, 0.8);
  }

  .modal-container {
    /* width: 300px;
    margin: 0px auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    font-family: inherit; */

    position: relative;
    border-radius: 4px;
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    /* border: 1px solid #888; */
    width: 80%;
    max-width: 300px;
    /* min-width: 300px; */
  }

  .modal-header h3 {
    margin-top: 0;
    color: #42b983;
  }

  .modal-header {

  }

  .modal-body, .modal-small-text {
    font-size: 1.5rem;
    font-weight: 400;
    color: rgba(0,0,0,0.8);
    /* margin-bottom: 5px; */
    margin: 0px 0px 40px 0px;
  }

  .modal-small-text {
    text-align: left;
    margin-top: -20px;
    font-size: 1rem;
  }

  .modal-footer {
    margin-top: 20px;
  }

  /* .modal-default-button {
    cursor: pointer;
    font-weight: bold;
    font-size: 1.4rem;
    color: white;
    border-radius: 4px;
    height: 40px;
    line-height: 40px;
    background-color: #00BAE0;
    margin: 0 auto;
    width: 100px;
  } */

  .modal-default-button {
    /* padding: 10px 20px;
    cursor: pointer;
    color: rgba(255, 255, 255, 1);
    border: 0;
    font-weight: 400;
    background-color: #00BAE0;
    border-radius: 4px;
    box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.1); */


    cursor: pointer;
    font-weight: bold;
    font-size: 1.4rem;
    color: white;
    border-radius: 4px;
    height: 40px;
    line-height: 40px;
    background-color: #00BAE0;
    margin: 0 auto;
    width: 100%;
  }

  /*
  * The following styles are auto-applied to elements with
  * transition="modal" when their visibility is toggled
  * by Vue.js.
  *
  * You can easily play with the modal transition by editing
  * these styles.
  */

  .modal-enter {
  opacity: 0;
  }

  .modal-leave-active {
  opacity: 0;
  }
  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  }
</style>
