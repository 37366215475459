import { update, getDatabase, ref as firebaseRef } from "firebase/database";

const resetRule = async (context, ruleName) => {
  const db = getDatabase();
  const roomId = context.state.userDBInfo.laundryroom;

  await update(firebaseRef(db, `laundryrooms/${roomId}/settings/rules/`), {
    [ruleName]: null,
  });

  context.commit("setRules", {
    ...context.state.roomSettings.rules,
    [ruleName]: null,
  });
};

const disableRulesExceptFor = async (context, ruleName) => {
  const rules = {
    bookingInterval: () => resetRule(context, "bookingInterval"),
    predefinedTimeslots: () => resetRule(context, "predefinedTimeslots"),
    bookingLimits: () => resetRule(context, "bookingLimits"),
  };

  for (const rule in rules) {
    if (ruleName !== rule) rules[rule](context);
  }
};

export { disableRulesExceptFor };
