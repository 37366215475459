<template>
  <div class="backgroundClass">
    <div class="loginBox">
      <img class="logo-wrap" src="../assets/LogoWhite.png" alt="Vaskerum Logo">
      <router-link to="/register">
        <button>Opret bruger</button>
      </router-link>
      <router-link to="/registerroom">
        <button>Opret vaskerum</button>
      </router-link>
      <router-link class="loginLink" to="/login">Cancel</router-link>
    </div>
  </div>
</template>

<script>
export default {
 
}
</script>
<style scoped>
* {
  box-sizing: border-box;
}

.backgroundClass {
  position: absolute;
  height: 100vh;
  left: 0;
  right: 0;
  background: linear-gradient(rgba(0, 186, 224, 1), rgba(0, 186, 224, 0.5));
}

.loginBox {
  border-radius: 4px;
  text-align: center;
  width: 300px;
  height: 300px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.loginLink {
  position: absolute;
  bottom: 5px;
  right: 5px;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.9rem;
}

button {
  width: 200px;
  margin-top: 20px;
  padding: 10px 20px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
  border: 0;
  font-weight: 400;
  background-color: white;
  border-radius: 4px;
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.3);
}

.logo-wrap {
  display: block;
  width: 100%;
  margin-bottom: 30px;
}
</style>
