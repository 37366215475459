<template>
  <div class="main">
    <h1>Tidsbegrænsning på bookinger</h1>
    <p>
      Tidsbegrænsning på bookinger fastsætter den minimale og/eller maksimale længde for hver enkelt booking. 
      <br>Den minimale længde på en booking kan ikke være mindre end 15 minutter.
    </p>
    <p>
      Vær opmærksom på, at hvis du angiver en minimums- eller maksimumslængde for bookinger, 
      vil et eventuelt bookinginterval under <i>"Bookinginterval"</i> blive nulstillet.
    </p>

    <h2>Minimum</h2>
    <div class="list">
      <button
        v-for="(value, label) in minimumTimes"
        class="button"
        :class="{ active: minimumTime === value }"
        @click="minimumTime = value"
      >
        {{ label }}
      </button>
    </div>

    <h2>Maksimum</h2>
    <div class="list">
      <button
        v-for="(value, label) in maximumTimes"
        class="button"
        :class="{ active: maximumTime === value }"
        @click="maximumTime = value"
      >
        {{ label }}
      </button>
    </div>

    <div class="action-buttons">
      <secondary-button :disabled="isLoading" @click="emit('close')">
        Annuller
      </secondary-button>
      <primary-button :disabled="isLoading" @click="handleChangeBookingLimits">
        Gem
      </primary-button>
    </div>
  </div>
</template>

<script setup>
import PrimaryButton from "./PrimaryButton.vue";
import SecondaryButton from "./SecondaryButton.vue";
import { ref } from "vue";
import { useToast } from "vue-toastification";
import { getDatabase, ref as firebaseRef, update } from "firebase/database";
import { useStore } from "vuex";

const minimumTimes = {
  "15 min": 15,
  "30 min": 30,
  "45 min": 45,
  "1 time": 60,
  "1t 15 min": 75,
  "1t 30 min": 90,
  "1t 45 min": 105,
  "2 timer": 120,
};

const maximumTimes = {
  "30 min": 30,
  "45 min": 45,
  "1 time": 60,
  "1t 15 min": 75,
  "1t 30 min": 90,
  "1t 45 min": 105,
  "2 timer": 120,
  "2t 30 min": 150,
  "3 timer": 180,
  Ingen: "No limit",
};

const emit = defineEmits(["close"]);

const store = useStore();
const toast = useToast();
const isLoading = ref(false);
const minimumTime = ref(
  store.state.roomSettings.rules?.bookingLimits?.minimumTime || 15
);
const maximumTime = ref(
  store.state.roomSettings.rules?.bookingLimits?.maximumTime || "No limit"
);

const handleChangeBookingLimits = async () => {
  const minimumTimeNumber = parseInt(minimumTime.value) || null;
  const maximumTimeNumber = parseInt(maximumTime.value) || null;

  if (
    minimumTimeNumber &&
    maximumTimeNumber &&
    minimumTimeNumber >= maximumTimeNumber
  ) {
    toast.error("Minimum længden skal være mindre end maksimum længden");
    return;
  }

  try {
    isLoading.value = true;

    const db = getDatabase();
    const laundryRoomId = store.state.userDBInfo.laundryroom;

    const bookingLimits = {
      minimumTime: minimumTimeNumber,
      maximumTime: maximumTimeNumber,
    };

    await update(
      firebaseRef(
        db,
        `laundryrooms/${laundryRoomId}/settings/rules/bookingLimits`
      ),
      bookingLimits
    );

    store.dispatch("disableRulesExceptFor", "bookingLimits");

    toast.success("Vaskerummets regler er blevet opdateret.");

    store.commit("setRules", {
      ...store.state.roomSettings.rules,
      bookingLimits,
    });

    emit("close");
  } catch (error) {
    toast.error("Vaskerummets regler blev ikke gemt.");
  } finally {
    isLoading.value = false;
  }
};
</script>

<style scoped>
.main {
  padding: 30px 10%;
  margin: auto;
}
h1 {
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0px;
}
h2 {
  margin-top: 20px;
  color: #545454;
  font-weight: 600;
  font-size: 1rem;
}
p {
  margin-top: 24px;
  margin-bottom: 6px;
  font-size: 1rem;
  color: #545454;
}
.action-buttons {
  margin-top: 32px;
  display: flex;
  gap: 20px;
}
.list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 6px;
}
.button {
  background-color: white;
  border: 1px solid #808080;
  border-radius: 4px;
  color: #545454;
  width: 100%;
  font-size: 14px;
}
.active {
  background-color: #808080;
  color: white;
}
</style>
