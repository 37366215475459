<template>
  <div class="backgroundClass">
    <div v-if="showInfo" class="infoBox">
      <edit-field
        title="Navn"
        :content="userDBInfo.name"
        :isEditable="true"
        placeholder="Navn"
        @edit="handleEdit"
      />
      <edit-field
        title="E-mail"
        :content="user.email"
        :isEditable="false"
        placeholder="E-mail"
      />
      <span class="line-class"></span>
      <edit-field
        title="Password"
        content=""
        :isEditable="true"
        type="password"
        placeholder="password"
        :showButton="true"
        buttonText="Skift password"
        @buttonClick="() => handleEdit('password')"
      />
      <edit-field
        v-if="!redirectToNotInARoom"
        title="ID - vaskerum"
        :content="userDBInfo.laundryroom"
        :isEditable="false"
        placeholder="Room ID"
      />
      <edit-field
        v-if="redirectToNotInARoom"
        title="ID - vaskerum"
        content="-"
        :isEditable="false"
        placeholder="Room ID"
      />
      <span class="line-class"></span>
      <router-link to="/deleteaccount" class="delete-user-btn">Slet bruger</router-link>
    </div>
    <change-name
      @cancel="handleNameCancel"
      @confirm="handleNameConfirm"
      v-if="showChangeName"
    />
    <change-password
      @cancel="handlePasswordCancel"
      @confirm="handleChangePassword"
      v-if="showChangePassword"
      :email="user.email"
    />
    
  </div>
</template>

<script>
import EditField from "@/components/EditField";
import ChangeName from "@/components/ChangeName.vue";
import ChangePassword from "@/components/ChangePassword.vue";
import { mapState } from "vuex";

export default {
  components: {
    EditField,
    ChangeName,
    ChangePassword
  },
  data() {
    return {
      showInfo: true,
      showChangeName: false,
      showChangePassword: false,
    };
  },
  methods: {
    handleEdit(title) {
      if (title === "Navn") {
        this.showChangeName = true;
        this.showInfo = false;
      } else if (title === "password") {
        this.showChangePassword = true;
        this.showInfo = false;
      }
    },
    handleNameCancel() {
      this.showChangeName = false;
      this.showInfo = true;
    },
    handlePasswordCancel() {
      this.showChangePassword = false;
      this.showInfo = true;
    },
    handleNameConfirm() {
      this.showChangeName = false;
      this.showInfo = true;
    },
    handleChangePassword() {
      this.showChangePassword = false;
      this.showInfo = true;
    },
  },
  computed: {
    ...mapState(["userDBInfo","user","redirectToNotInARoom"]),
  },
};
</script>

<style scoped>
.line-class {
  border-bottom: 2px solid #e5e7eb;
}
.infoBox {
  border-radius: 4px;
  width: 80%;
  margin: auto;
  height: 100vh;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.backgroundClass {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  max-width: 450px;
  margin: 0 auto;
  overflow: hidden;
}
.delete-user-btn {
  font-size: 13px;
  text-align: left;
  margin-top: 20px;
  cursor: pointer;
  color: gray;
  width: max-content;
}
</style>
