<template>
    <div class="main">
        <h1>Maskiner</h1>
        <p>Vælg antallet af maskiner i vaskerummet.</p>
        <div class="machineSelectorWrap">
            <div class="headTextMachineSelection">Antal vaskemaskiner</div>
            <machine-number :initial-number="machineCounts.washMachineAmount" @change="handleWashMachineAmountChange" />
            <div class="headTextMachineSelection">Antal tørretumblere</div>
            <machine-number :initial-number="machineCounts.dryerMachineAmount" @change="handleDryerMachineAmountChange" />
        </div>
        <div class="button-container">
            <secondary-button :disabled="isLoading" @click="handleCancel">Annuller</secondary-button>
            <primary-button :disabled="isLoading" @click="handleChangeMachineCount" class="margin-left">{{ isLoading ?
                    "Gemmer..." : "OK"
            }}</primary-button>
        </div>
    </div>
</template>

<script setup>
import MachineNumber from './MachineNumber.vue';
import PrimaryButton from './PrimaryButton.vue';
import SecondaryButton from './SecondaryButton.vue';
import {reactive, ref, computed} from 'vue';
import { useToast } from 'vue-toastification';
import { getDatabase, ref as firebaseRef, update } from "firebase/database";
import {useStore} from 'vuex';

const store = useStore();

const emit = defineEmits(['cancel', 'confirm'])
const isLoading = ref(false)
const toast = useToast()
const userDBInfo = computed(() => store.state.userDBInfo);

const handleCancel = () => {
    emit('cancel')
}

const machineCounts = reactive({
    washMachineAmount: store.state.roomSettings?.washMachineAmount ?? 1,
    dryerMachineAmount: store.state.roomSettings?.dryerMachineAmount ?? 1
})

const handleWashMachineAmountChange = (count) => machineCounts.washMachineAmount = count;

const handleDryerMachineAmountChange = (count) => machineCounts.dryerMachineAmount = count;

const handleChangeMachineCount = async () => {
    isLoading.value = true
    try {
        const db = getDatabase();
        const laundryRoomId = store.state.userDBInfo.laundryroom;
        await update(firebaseRef(db,'laundryrooms/' + laundryRoomId + '/settings/'), machineCounts) 
        toast.success('Antallet af maskiner er blevet opdateret');
        store.commit('setRoomSettings', {...store.state.roomSettings, ...machineCounts})
        emit('confirm')
    } catch (error) {
        toast.error('Fejl ved ændring af maskiner')
        console.error(error)
    }
    isLoading.value = false
}


</script>


<style scoped>
.main {
    border-radius: 4px;
    width: 80%;
    height: 100vh;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.margin-left {
    margin-left: 4px;
}

.button-container {
    display: flex;
}

h1 {
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0px;
}

p {
    margin-top: 24px;
    margin-bottom: 24px;
    font-size: 1rem;
    text-align: center;
}

.machineSelectorWrap {
    margin-bottom: 35px;
}

.headTextMachineSelection {
    color: rgba(0, 0, 0, 0.8);
    text-align: left;
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 5px;
}
</style>