<template>
  <div class="bookingLoaderWrap" :class="{extrapadding:extraPadding }">
    <div v-for="(booking, index) in translatedBookings" :key="index">
      <button v-on:click="deleteBooking(booking)" v-if="booking.owner == userObj.uid" class="bookingBoxMe" >
        Min booking<br />
        {{booking.start}} - {{booking.end}}
      </button>
      <!-- <div v-else="booking.owner == $activeUserId" class="bookingBox" > -->
      <div v-else class="bookingBox" >
        <div v-if="booking.ownerText">
          {{booking.ownerText}}
        </div>
        <div v-else>
          Optaget
        </div>
        {{booking.start}} - {{booking.end}}
      </div>
      <!-- {{booking['.key']}} -->
      <!-- Booking Loader -->
      <!-- b: {{fireObj[0].bookingStart}} - a: {{fireObj[0].bookingEnd}}<br />
      b: {{fireObj[1].bookingStart}} - a: {{fireObj[1].bookingEnd}}<br /> -->


    </div>
  </div>
</template>

<script>
// import { db } from '@/firebase'
// import bus from '@/bus.js'
import {mapGetters} from 'vuex'

export default {
  props: ['firebooking', 'selectedDay', 'machineSelection'],
  data () {
    return {
      fireObj: [
        {bookingStart: '', bookingEnd: ''},
        {bookingStart: '', bookingEnd: ''},
      ]
    }
  },
  computed: {
    ...mapGetters({
      userObj:'getUser',
      roomSettings: 'getRoomSettings'
      // machine: 'getSelectedMachine'
      
    }),
    extraPadding: function() {
// && this.roomSettings.washMachineAmounti > 1
      if(this.machineSelection.search('wash') > -1 && this.roomSettings.washMachineAmount > 1) return true
      else if (this.machineSelection.search('dry') > -1 && this.roomSettings.dryerMachineAmount > 1) return true
      else return false
    },
    translatedBookings: function() {
      let bookings = JSON.parse(JSON.stringify(this.firebooking))
      let bookingsSorted = []
      let startOfDay = this.selectedDay
      let endOfDay = new Date(startOfDay);
      const machine = this.machineSelection
      endOfDay.setHours(23)
      endOfDay.setMinutes(59)
      endOfDay.setSeconds(59)
      endOfDay.setMilliseconds(999)
      // var copiedDate = new Date(date.getTime());
      for (var booking in bookings) {
        // console.log(bookings[booking]);
        // if (bookings[booking].ownerText){
        //   alert('ownertest')
        // }
        if (bookings[booking].start > startOfDay.getTime() &&
            bookings[booking].end < endOfDay.getTime() &&
            bookings[booking].machine == machine )
        {

          var tStart = new Date(bookings[booking].start);
          var sHours = tStart.getHours()
          var sMinutes = tStart.getMinutes()
          var tEnd = new Date(bookings[booking].end);
          var eHours = tEnd.getHours()
          var eMinutes = tEnd.getMinutes()

          if (sMinutes == 0) {
            sMinutes = '00'
          }
          if (eMinutes == 0) {
            eMinutes = '00'
          }
          bookings[booking].bookingId = booking
          bookings[booking].startMiliSec = bookings[booking].start
          bookings[booking].start = sHours + ':' + sMinutes
          bookings[booking].end = eHours + ':' + eMinutes

          bookingsSorted.push(bookings[booking])
        } else {

        }//End of if
        // console.log(bookings[booking]);

      }
      // console.log('bookings: ' + typeof bookings);
      // console.log(startOfDay);
      // console.log(endOfDay);
      function compare( a, b ) {
        if ( a.startMiliSec < b.startMiliSec ){
          return -1;
        }
        if ( a.startMiliSec > b.startMiliSec ){
          return 1;
        }
        return 0;
      }

      return bookingsSorted.sort( compare );

      // return bookingsSorted
    } //End of translatedBookings
  },
  methods: {
    deleteBooking(booking) {
      //REPLACE
      this.$store.commit('setDeleteBookingObject', booking)
      this.$store.commit('setShowDeleteBookingModal', true)
      // bus.$emit('showDeleteModal', booking)  
    }
  },
  mounted() {
    let start = new Date();
    let end = new Date();
    start.setFullYear(2018, 3, 20)
    start.setHours(17)
    start.setMinutes(30)
    start.setSeconds(0)
    start.setMilliseconds(0)
    this.fireObj[0].bookingStart = start

    end.setFullYear(2018, 3, 20)
    end.setHours(19)
    end.setMinutes(30)
    end.setSeconds(0)
    end.setMilliseconds(0)
    this.fireObj[0].bookingEnd = end


    let start2 = new Date();
    let end2 = new Date();
    start2.setFullYear(2018, 3, 20)
    start2.setHours(20)
    start2.setMinutes(30)
    start2.setSeconds(0)
    start2.setMilliseconds(0)
    this.fireObj[1].bookingStart = start2

    end2.setFullYear(2018, 3, 20)
    end2.setHours(22)
    end2.setMinutes(30)
    end2.setSeconds(0)
    end2.setMilliseconds(0)
    this.fireObj[1].bookingEnd = end2
  }
}

</script>

<style scoped>
* {
  box-sizing: border-box;
}

button {
  background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.bookingBoxMe {
  text-align: center;
  font-weight: 400;
  border-radius: 4px;
  padding: 10px;
  display: block;
  width: 50%;
  max-width: 450px;
  min-width: 300px;
  margin: 0 auto;
  margin-top: 10px;
  /* background-color: #54bf67; */
  background-color: #00BAE0;
  color: white;
}
.bookingBox {
  text-align: center;
  font-weight: 400;
  border-radius: 4px;
  padding: 10px;
  display: block;
  width: 50%;
  max-width: 450px;
  min-width: 300px;
  margin: 0 auto;
  margin-top: 10px;
  /* background-color: #d8d8d8; */
  background-color: rgba(0, 186, 224, 0.5);
  color: white;
}

.bookingLoaderWrap {
  overflow: scroll;
  position: absolute;
  padding-top: 205px;
  padding-bottom: 100px;

  left: 0;
  right: 0;
  height: 100%;
  margin-bottom: 65px;
}
.extrapadding {
  padding-top: 240px;
}
</style>
