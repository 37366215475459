<template>
  <p v-if="bookingInterval" class="bookingintervalInfoText">
    Bookinginterval er sat til {{ bookingInterval }} min.
  </p>
  <div class="timeSelectorWrap">
    <div class="timerWrap">
      <div class="clockWrap hours">
        <button v-on:click="changeHour('+', 'start')" class="iconWrapPlus">
          <font-awesome-icon class="angle-up" icon="angle-up" />
        </button>
        <button v-on:click="changeHour('-', 'start')" class="iconWrapMinus">
          <font-awesome-icon class="angle-down" icon="angle-down" />
        </button>
        {{ bookingTimeComputed.startHour }}
      </div>
      <div class="colon">:</div>
      <div class="clockWrap minutes">
        <button v-on:click="changeMinutes('+', 'start')" class="iconWrapPlus">
          <font-awesome-icon class="angle-up" icon="angle-up" />
        </button>
        <button v-on:click="changeMinutes('-', 'start')" class="iconWrapMinus">
          <font-awesome-icon class="angle-down" icon="angle-down" />
        </button>
        {{ bookingTimeComputed.startMinutes }}
      </div>
      -
      <div class="clockWrap hours">
        <button
          v-if="!bookingInterval"
          v-on:click="changeHour('+', 'end')"
          class="iconWrapPlus"
        >
          <font-awesome-icon class="angle-up" icon="angle-up" />
        </button>
        <button
          v-if="!bookingInterval"
          v-on:click="changeHour('-', 'end')"
          class="iconWrapMinus"
        >
          <font-awesome-icon class="angle-down" icon="angle-down" />
        </button>
        {{ bookingTimeComputed.endHour }}
      </div>
      <div class="colon">:</div>
      <div class="clockWrap minutes">
        <button
          v-if="!bookingInterval"
          v-on:click="changeMinutes('+', 'end')"
          class="iconWrapPlus"
        >
          <font-awesome-icon class="angle-up" icon="angle-up" />
        </button>
        <button
          v-if="!bookingInterval"
          v-on:click="changeMinutes('-', 'end')"
          class="iconWrapMinus"
        >
          <font-awesome-icon class="angle-down" icon="angle-down" />
        </button>
        {{ bookingTimeComputed.endMinutes }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["bookingTime"],
  mounted() {
    if (this.bookingInterval) this.updateWithBookingInterval();
  },
  methods: {
    changeHour(plusMinus, startEnd) {
      let start = this.bookingTime.startHour;
      let end = this.bookingTime.endHour;
      if (plusMinus == "+") {
        if (startEnd == "start") {
          if (start >= 23) {
            return;
          } else {
            start = start + 1;
          }
        } else if (startEnd == "end") {
          if (end == 24) {
            this.bookingTime.endMinutes = 0;
            return;
          } else {
            end = end + 1;
            if (end == 24) {
              this.bookingTime.endMinutes = 0;
            }
          }
        }
      } else if (plusMinus == "-") {
        if (startEnd == "start") {
          if (start == 1) {
            start = 1;
          } else {
            start = start - 1;
          }
        } else if (startEnd == "end") {
          if (end == 1) {
            end = 1;
          } else {
            end = end - 1;
          }
        }
      }
      this.bookingTime.startHour = start;
      this.bookingTime.endHour = end;

      if (this.bookingInterval) this.updateWithBookingInterval();
    },
    changeMinutes(plusMinus, startEnd) {
      let { startMinutes, endMinutes } = this.bookingTime;

      const adjustTime = (time, plusMinus) => {
        if (plusMinus === "+") return (time + 15) % 60;
        return (time - 15 + 60) % 60;
      };

      if (startEnd === "start")
        startMinutes = adjustTime(startMinutes, plusMinus);
      else endMinutes = adjustTime(endMinutes, plusMinus);

      if (this.bookingTime.endHour === 24) endMinutes = 0;

      this.bookingTime.startMinutes = startMinutes;
      this.bookingTime.endMinutes = endMinutes;

      if (this.bookingInterval) this.updateWithBookingInterval();
    },
    updateWithBookingInterval() {
      const totalMinutes =
        (this.bookingTime.startHour * 60 +
          this.bookingTime.startMinutes +
          this.bookingInterval) %
        1440;
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;

      this.bookingTime.endHour = hours;
      this.bookingTime.endMinutes = minutes;
    },
  },
  computed: {
    ...mapGetters({
      rules: "getRules",
    }),
    bookingInterval: function () {
      if (this.rules?.bookingInterval) return this.rules.bookingInterval;
      return 0;
    },
    bookingTimeComputed: function () {
      let { startHour, startMinutes, endHour, endMinutes } = this.bookingTime;

      startHour = startHour.toString().padStart(2, "0");
      startMinutes = startMinutes.toString().padStart(2, "0");
      endHour = endHour.toString().padStart(2, "0");
      endMinutes = endMinutes.toString().padStart(2, "0");

      return { startHour, startMinutes, endHour, endMinutes };
    },
  },
};
</script>

<style scoped>
button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.bookingintervalInfoText {
  color: rgba(0, 0, 0, 0.6);
}

.timeSelectorWrap {
  margin-top: 50px;
  margin-bottom: 50px;
}

.timerWrap {
  font-size: 24px;
  font-weight: bold;
  width: 250px;
  height: 50px;
  line-height: 50px;
  margin: 0 auto;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.8);
}

.clockWrap {
  width: 30px;
  display: inline-block;
  position: relative;
}
.hours {
  text-align: center;
}

.minutes {
  text-align: center;
}

.iconWrapPlus {
  cursor: pointer;
  width: 30px;
  position: absolute;
  top: -47px;
  color: rgba(0, 0, 0, 0.4);
}

.iconWrapMinus {
  cursor: pointer;
  width: 30px;
  position: absolute;
  top: 45px;
  color: rgba(0, 0, 0, 0.4);
}

.colon {
  display: inline-block;
}
</style>
