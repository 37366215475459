<template>
  <div class="backgroundClass">
    <div class="main">
      <h1>Du er ikke medlem af et vaskerum</h1>  
      <p>Du er ikke en del af et aktivt vaskerum. Dette kan bl.a. skyldes, at vaskerummet er blevet slettet.</p> 
      <p>Skriv et ID for at tilslutte dig et vaskrum</p>
      <!-- <label for="name">Skriv et ID til et vaskerum</label> -->
      <input v-model="roomId" id="roomId" placeholder="ID - vaskerum" type="text" />
      <div v-if="error" class="error">{{ error }}</div>
      <primary-button :disabled="isLoading" @click="handleJoinRoom" class="margin-top">Tilslut</primary-button>
    </div>
  </div>
</template>

<script setup>
import PrimaryButton from '@/components/PrimaryButton.vue';
import {ref} from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const store = useStore();
const router = useRouter();

const roomId = ref('');
const error = ref(null);
const isLoading = ref(false);

const handleJoinRoom = async () => {
  isLoading.value = true;
  error.value = null;
  if (roomId.value.trim().length !== 6) {
    // error.value = "Vaskerummets ID skal være seks karakter langt";
    error.value = "Ugyldigt ID";
    isLoading.value = false;
    return;
  }
  if (roomId.value.trim().indexOf(" ") !== -1) {
    // error.value = "Vaskerummets ID må ikke indeholde mellemrum";
    error.value = "Ugyldigt ID";
    isLoading.value = false;
    return;
  }

  try {
    const joined = await store.dispatch('joinRoom', roomId.value);
    if(!joined) {
      error.value = "Ugyldigt ID";
    } else {
      window.location.href = "/";
    }
  } catch (error) {
    console.log(error);
    error.value = "Ugyldigt ID";
  }

  isLoading.value = false;
}

</script>

<style scoped>
.backgroundClass {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  max-width: 450px;
  margin: 0 auto;
  overflow: hidden;
}
.main {
  border-radius: 4px;
  width: 80%;
  height: 100vh;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

h1 {
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0px;
}

.margin-top {
  margin-top: 16px;
}

input {
  display: block;
  margin: 6px 0;
  max-width: 100%;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  background-color: white;
  font-size: 16px;
  padding: 7px 18px;
  height: 40px;
}
.error {
  font-size: 16px;
  color: #ff5a5f;
}
input:focus {
  outline: none;
}
label {
  margin-bottom: 0px;
  color: #545454;
  font-weight: 600;
  font-size: 1rem;
}
</style>