<template>
  <div>
    <div class="inner-wrap">
      <div class="topnav" id="myTopnav">
        <button v-on:click="isOpen = !isOpen" class="menu-wrap">
          <font-awesome-icon
            v-if="!isOpen"
            class="menu-bars"
            icon="bars"
            :style="{ color: 'white' }"
          />
          <font-awesome-icon
            v-if="isOpen"
            class="menu-close"
            icon="close"
            :style="{ color: 'white' }"
          />
        </button>
        <img
          class="logo-wrap"
          src="../assets/vaskerumLogoWhite.png"
          alt="Vaskerum Logo"
        />
      </div>
      <div class="topnav-shadow">shadow</div>
      <transition name="slide">
        <div v-if="isOpen" class="dropDown">
          <!-- <img class="logo-wrap-hidden" src="../assets/vaskerumLogoBlack.png" alt="Mountain View"> -->
          <div
            @click="isOpen = !isOpen"
            class="textWrap"
            v-for="link in links"
            :key="link.link"
          >
            <router-link class="router-link-wrap" v-bind:to="link.link" v-if="link.showLink">
              <!-- <font-awesome-icon :icon="['fas', 'calendar']" /> -->
              <!-- <font-awesome-icon icon="far fa-calendar" /> -->
              <!-- <menu-icon /> -->
              <!-- <font-awesome-icon class="link-icons"></font-awesome-icon> -->
              {{ link.text }}
            </router-link>
          </div>
          <div class="textWrap logout-wrap-hidden" @click="handleClick">
            Log ud
          </div>
          <div class="emailText">
            {{ user.email }}
          </div>
        </div>
      </transition>
      <transition name="shadow">
        <div v-if="isOpen" class="modal-mask"></div>
      </transition>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import MenuIcon from "vue-material-design-icons/Menu.vue";

export default {
  components: {
    MenuIcon,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const linksArray = ref([
      {
        link: "/booking",
        text: "Booking",
        showLink: true,
        icon: ["fas", "calendar"],
      },
      {
        link: "/roominfo",
        text: "Vaskerum",
        showLink: true,
        icon: "calendar-alt",
      },
      {
        link: "/userinfo",
        text: "Bruger",
        showLink: true,
        icon: "calendar-alt",
      },
      {
        link: "/notinaroom",
        text: "Tilføj vaskerum",
        showLink: false,
        icon: "calendar-alt",
      },
    ]);
    const isOpen = ref(false);
    let user = computed(() => store.state.user);
    let authIsReady = computed(() => store.state.authIsReady);
    let userIsInARoom = computed(() => store.state.userIsInARoom);
    let roomSettings = computed(() => store.state.roomSettings);

    const handleClick = () => {
      store.dispatch("logout");
      router.replace("/login");
      isOpen.value = false;
    };

    const links = computed(() => {
      return linksArray.value.map((element) => {
        if (element.link == "/booking" && (!(userIsInARoom.value) || !(roomSettings.value?.roomIsActive))){
          element.showLink = false;
        } else if (element.link == "/booking") {
          element.showLink = true;
        }
        if (element.link == "/roominfo" && (!(userIsInARoom.value) || !(roomSettings.value?.roomIsActive))){
          element.showLink = false;
        } else if(element.link == "/roominfo") {
          element.showLink = true;
        }
        if (element.link == "/notinaroom" && (!(userIsInARoom.value) || !(roomSettings.value?.roomIsActive))){
          element.showLink = true;
        } else if (element.link == "/notinaroom") {
          element.showLink = false;
        }
        return element;
      });
    });

    return {
      handleClick,
      links,
      user,
      authIsReady,
      userIsInARoom,
      isOpen,
    };
  },
};
</script>

<style scoped>
.router-link-active {
  /* color: orange;
  background-color: orange; */
}

.router-link-wrap {
  /* background-color: orange; */
  display: block;
  /* width: 200px; */
}

.link-icons {
  margin-right: 10px;
}

.modal-mask {
  position: absolute;
  z-index: 19;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  /* display: table; */
}

.menu-bars,
.menu-close {
  transform: scale(1.75);
  position: relative;
  top: 10px;
  right: 20px;
}

.shadow-leave-active,
.shadow-enter-active {
  transition: 0.8s;
}
.shadow-enter-from {
  /* transform: translateX(450px); */
  opacity: 0;
}
.shadow-leave-to {
  /* transform: translateX(100%); */
  opacity: 0;
}

.slide-leave-active,
.slide-enter-active {
  transition: 0.8s;
}
.slide-enter-from {
  transform: translateX(300px);
}
.slide-enter-to {
  transform: translateX(0px);
}

.slide-leave-to {
  transform: translateX(300px);
}

* {
  box-sizing: border-box;
}

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

body {
  margin: 0;
}

.topnav {
  position: absolute;
  padding-bottom: 4px;
  z-index: 21;
  left: 0;
  right: 0;
  overflow: hidden;
  background-color: #00bae0;
  /* box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.24); */
  /* color: orange; */
  height: 40px;
}

.topnav-shadow {
  position: absolute;
  left: 0;
  right: 0;
  padding-bottom: 4px;
  z-index: 18;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.24);
  height: 40px;
}

.dropDown {
  font-weight: 400;
  font-size: 1.1rem;
  position: absolute;
  top: 40px;
  bottom: 0px;
  right: 0px;
  width: 250px;
  z-index: 20;
  text-align: left;
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.24);
  padding: 10px 15px 0px 10px;
  background-color: white;
  color: rgba(0, 0, 0, 0.7);
}

.dropDown a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.7);
  /* margin-top: 5px;
  margin-bottom: 5px; */
  padding: 5px;
}

.textWrap {
  /* display: block; */
  /* width: 150px; */
  /* padding-top: 5px; */
}
.logout-wrap-hidden {
  position: relative;
  bottom: 0px;
  left: 0px;
  cursor: pointer;
  margin-top: 30px;
}

.logo {
  float: left;
  display: block;
}

.logo img {
  height: 100%;
  max-height: 70px;
  padding: 5px;
}

.topnav a,
.logout-wrap,
.name-wrap,
.menu-Wrap {
  font-weight: bold;
  float: right;
  display: block;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  padding: 12px 16px;
  text-decoration: none;
  font-size: 15px;
}

/* .menu-wrap {
  padding: 4px 15px 0px 0px;
} */

.name-wrap {
  float: left;
  color: White;
}

.topnav .icon {
  display: none;
}

.logout-wrap,
.menu-wrap {
  float: right;
  cursor: pointer;
  color: white;
}

.logo-wrap,
.logo-wrap-hidden {
  display: block;
  margin-top: 11px;
  margin-left: 15px;
  height: 20px;
}

.logo-wrap-hidden {
  margin-bottom: 30px;
  margin-left: 0px;
}

.router-link-active {
  font-weight: bold;
}

.emailText {
  position: absolute;
  bottom: 35px;
  left: 15px;
  font-size: 0.8rem;
}

.logout-wrap-hidden {
  position: absolute;
  bottom: 50px;
  left: 15px;
}

.activeLink {
  /* color: orange; */
  background-color: yellow;
}
</style>
