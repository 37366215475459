<template>
  <div id="myModal" class="modal">
    <div class="modal-content" :class="{ shake: animated }">
      <span v-on:click="close" class="close">&times;</span>
      <div class="headerWrap">
        <div class="resavationText">Ny booking</div>
        <div class="dateText">
          {{ computedSelectionDay.weekdayname }}
          {{ computedSelectionDay.weekday }} {{ computedSelectionDay.date }}
          {{ computedSelectionDay.month }} {{ computedSelectionDay.year }}
        </div>
      </div>

      <timeslot-selector
        v-if="this.rules?.predefinedTimeslots"
        :existingBookings="this.firebooking"
        :booking-time="bookingTime"
        :machineSelection="this.machineSelection"
        :toggleBookButton="(value) => (bookBtnDisabled = value)"
      />
      <time-selector v-else :booking-time="bookingTime" />

      <button @click="bookIt" class="btnReserver" :disabled="bookBtnDisabled">
        {{
          bookBtnDisabled
            ? this.rules?.predefinedTimeslots
              ? "Book tid"
              : "Booker..."
            : "Book tid"
        }}
      </button>

      <div
        v-if="modalInfoText"
        v-html="modalInfoText"
        class="warning-text"
      ></div>
    </div>
    <transition name="fade">
      <info-modal v-if="showModal" @close="showModal = false">
        <div slot="body">{{ modalInfoText }}</div>
      </info-modal>
    </transition>
  </div>
</template>

<script>
import infoModal from "@/components/infoModal.vue";
import timeSelector from "@/components/ReservationModal/TimeSelector.vue";
import timeslotSelector from "@/components/ReservationModal/TimeslotSelector.vue";
import { isOverMaxActiveBookings } from "@/utils/is-over-max-active-bookings";
import { mapGetters } from "vuex";
import { getDatabase, ref, push } from "firebase/database";

export default {
  props: [
    "computedSelectionDay",
    "selectedDateRecived",
    "machineSelection",
    "firebooking",
    "fireSettings",
  ],
  data() {
    return {
      bookingTime: {
        startHour: 12,
        startMinutes: 0,
        endHour: 13,
        endMinutes: 0,
      },
      showModal: false,
      modalInfoText: "",
      animated: false,
      bookBtnDisabled: false,
    };
  },
  components: {
    "info-modal": infoModal,
    "time-selector": timeSelector,
    "timeslot-selector": timeslotSelector,
  },
  methods: {
    close() {
      this.modalInfoText = "";
      this.$store.commit("setShowReservationModal", false);
    },
    async bookIt() {
      this.bookBtnDisabled = true;
      let tStart = new Date(this.selectedDateRecived.getTime()); //Til prop
      let tEnd = new Date(this.selectedDateRecived.getTime()); //Til prop
      let openTime = new Date(this.selectedDateRecived.getTime());
      let closeTime = new Date(this.selectedDateRecived.getTime());
      const startHour = this.bookingTime.startHour;
      const startMinutes = this.bookingTime.startMinutes;
      const endHour = this.bookingTime.endHour;
      const endMinutes = this.bookingTime.endMinutes;
      const ownerID = this.userObj.uid; //<---- Changed this
      const laundryRoom = this.userDBInfo.laundryroom; //<----- changed this
      const machineType = this.machineSelection; //Til prop
      const openHour = this.fireSettings.startHour;
      const openMinutes = this.fireSettings.startMinutes;
      const closeHour = this.fireSettings.endHour;
      const closeMinutes = this.fireSettings.endMinutes;
      let bookingObject = { start: 0, end: 0, owner: "", machine: "" };
      let ownerText = "";
      tStart.setHours(startHour);
      tStart.setMinutes(startMinutes);
      tStart.setSeconds(0);
      tStart.setMilliseconds(0);
      tEnd.setHours(endHour);
      if (endHour == 24) {
        tEnd.setMinutes(endMinutes - 1);
      } else {
        tEnd.setMinutes(endMinutes);
      }
      tEnd.setSeconds(0);
      tEnd.setMilliseconds(0);
      //Convert opening hours into milliseconds since 1970
      openTime.setHours(openHour);
      openTime.setMinutes(openMinutes);
      openTime.setSeconds(0);
      openTime.setMilliseconds(0);
      closeTime.setHours(closeHour);
      closeTime.setMinutes(closeMinutes);
      closeTime.setSeconds(0);
      closeTime.setMilliseconds(0);

      //Adding ownerText (apartment), if it exsists in under user/{serID}
      if (this.userDBInfo.apartment) {
        ownerText = this.userDBInfo.apartment;
      } else {
        ownerText = this.userDBInfo.name;
        // let fullUserName = this.userDBInfo.name
        // let fullUserNameArray = fullUserName.split(" ")
        // ownerText = fullUserNameArray[0]
      }

      bookingObject = {
        start: tStart.getTime(),
        end: tEnd.getTime(),
        owner: ownerID,
        machine: machineType,
        ownerText: ownerText,
        openTime: openTime,
        closeTime: closeTime,
      };
      await this.checkForBooking(bookingObject, laundryRoom);
    },
    async checkForBooking(newBooking, laundryRoom) {
      if (!this.userIsConnected) {
        this.shakeIt("Ikke muligt at booke. Du er offline");
        this.bookBtnDisabled = false;
        return;
      }

      const existingBookings = this.firebooking;
      const bookingTimeInterval = (newBooking.end - newBooking.start) / 60000;

      if (
        isOverMaxActiveBookings(
          this.userObj.uid,
          existingBookings,
          this.rules?.maxActiveBookings
        )
      ) {
        this.shakeIt(
          `Du har nået begrænsningen på ${this.rules?.maxActiveBookings} ${this.rules?.maxActiveBookings == 1 ? 'aktiv booking' : 'aktive bookinger'}.`
        );

        this.bookBtnDisabled = false;
        return;
      }

      if (
        newBooking.start > newBooking.end ||
        newBooking.start == newBooking.end
      ) {
        this.shakeIt("Den valgte tid er ikke gyldig");
        this.bookBtnDisabled = false;
        return;
      }

      if (
        newBooking.start < newBooking.openTime ||
        newBooking.end > newBooking.closeTime
      ) {
        this.shakeIt("Den valgte tid er udenfor åbningstiden");
        this.bookBtnDisabled = false;
        return;
      }

      if (
        bookingTimeInterval < this.bookingLimits.minimumTime ||
        bookingTimeInterval > this.bookingLimits.maximumTime
      ) {
        if (this.bookingLimits.maximumTime === Infinity)
          this.shakeIt(
            `Den valgte tid er ikke gyldig.<br/>Din booking skal være mindst ${this.bookingLimits.minimumTime} min.`
          );
        else
          this.shakeIt(
            `Den valgte tid er ikke gyldig.<br/>Din booking skal være mindst ${this.bookingLimits.minimumTime} min. og maks. ${this.bookingLimits.maximumTime} min.`
          );

        this.bookBtnDisabled = false;
        return;
      }

      for (var booking in existingBookings) {
        if (existingBookings[booking].machine == newBooking.machine) {
          if (
            //new start time not in interval
            (newBooking.start > existingBookings[booking].start &&
              newBooking.start < existingBookings[booking].end) ||
            //new end time not in interval
            (newBooking.end > existingBookings[booking].start &&
              newBooking.end < existingBookings[booking].end) ||
            //existing booking not in interval of new booking
            (newBooking.start < existingBookings[booking].start &&
              newBooking.end > existingBookings[booking].end) ||
            //new booking end time not qual to existing booking end
            //time while new booking start time is less than existing booking start
            (newBooking.start < existingBookings[booking].start &&
              newBooking.end == existingBookings[booking].end) ||
            (newBooking.start == existingBookings[booking].start &&
              newBooking.end > existingBookings[booking].end) ||
            (newBooking.start == existingBookings[booking].start &&
              newBooking.end == existingBookings[booking].end)
          ) {
            this.shakeIt("Tidspunktet er optaget");
            this.bookBtnDisabled = false;
            return;
          }
        }
      }

      const db = getDatabase();
      try {
        push(ref(db, "laundryrooms/" + laundryRoom + "/bookings/"), newBooking);
        this.bookBtnDisabled = false;
        this.$store.commit("setShowReservationModal", false);
      } catch (error) {
        this.shakeIt("Noget gik galt, prøv igen");
        this.bookBtnDisabled = false;
      }
    }, //end of checkForBooking
    shakeIt(message) {
      this.modalInfoText = message;
      const self = this;
      self.animated = true;
      setTimeout(() => {
        self.animated = false;
      }, 1000);
    },
  },
  computed: {
    ...mapGetters({
      userObj: "getUser",
      userDBInfo: "getUserDBInfo",
      userIsConnected: "getUserIsConnected",
      rules: "getRules",
    }),
    bookingLimits: function () {
      const limits = this.rules?.bookingLimits;

      return {
        minimumTime: limits?.minimumTime || 15,
        maximumTime: limits?.maximumTime || Infinity,
      };
    },
  },
};
</script>

<style scoped>
.warning-text {
  background-color: orange;
  text-align: center;
  margin: 20px -20px -20px -20px;
  color: white;
  border-radius: 0 0 4px 4px;
  font-size: 17px;
  width: calc(100% + 30px);
  font-weight: 700;
  padding: 10px 5px;
}

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.modal {
  text-align: center;
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 30; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  max-width: 450px;
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.3); /* Black w/ opacity */
}

.modal-content {
  position: relative;
  border-radius: 4px;
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  width: 80%;
  max-width: 400px;
  min-width: 300px;
}

.headerWrap {
  margin: 16px 0px;
}

.resavationText {
  font-size: 1.7rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 5px;
}

.dateText {
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.6);
}

.hours {
  text-align: center;
}

.minutes {
  text-align: center;
}

.close {
  top: 0px;
  right: 12px;
  position: absolute;
  color: #aaaaaa;
  font-size: 35px;
  font-weight: bold;
  z-index: 30;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.btnReserver {
  cursor: pointer;
  font-weight: bold;
  font-size: 1.4rem;
  color: white;
  border-radius: 4px;
  height: 40px;
  line-height: 40px;
  background-color: #00bae0;
  margin: 0 auto;
  width: 250px;
}

.btnReserver:disabled {
  opacity: 0.7;
  pointer-events: none;
}

.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@media screen and (min-width: 455px) {
  .modal {
    top: 15px;
    height: calc(100% - 30px);
    border-radius: 4px;
  }
}
</style>
