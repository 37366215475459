import { createStore } from "vuex";
import { auth } from "../firebase/config";
import {
  getDatabase,
  ref,
  child,
  get,
  onValue,
  update,
  query,
  orderByChild,
  orderByValue,
} from "firebase/database";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import { useToast } from "vue-toastification";
import { disableRulesExceptFor } from "./reset-rules";

const toast = useToast();

const store = createStore({
  state: {
    userIsConnected: false,
    user: null,
    userDBInfo: null,
    userIsInARoom: false,
    redirectToNotInARoom: false,
    authIsReady: false,
    appIsReady: {
      user: false,
      settings: false,
      bookings: false,
      subscription: false,
      allowAccess: false,
    },
    roomSettings: {},
    laundryRoomAllowAccess: true,
    subscription: null,
    roomBookings: {},
    testDate: {},
    date: {},
    selectedMachine: "wash",
    showReservationModal: false,
    showDeleteBookingModal: false,
    deleteBookingObject: {},
  },
  mutations: {
    setUserIsConnected(state, payload) {
      state.userIsConnected = payload;
    },
    setTestDate(state, payload) {
      state.testDate = payload;
    },
    setDate(state, payload) {
      state.date = payload;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setUserDBInfo(state, payload) {
      state.userDBInfo = payload;
    },
    setUserIsInARoom(state, payload) {
      state.userIsInARoom = payload;
    },
    setRedirectToNotInARoom(state, payload) {
      state.redirectToNotInARoom = payload;
    },
    setAuthIsReady(state, payload) {
      state.authIsReady = payload;
    },
    setAppIsReadyUser(state, payload) {
      state.appIsReady.user = payload;
    },
    setAppIsReadyBookings(state, payload) {
      state.appIsReady.bookings = payload;
    },
    setAppIsReadySettings(state, payload) {
      state.appIsReady.settings = payload;
    },
    setAppIsReadySubscription(state, payload) {
      state.appIsReady.subscription = payload;
    },
    setAppIsReadyLaundryRoomAllowAccess(state, payload) {
      state.appIsReady.allowAccess = payload;
    },
    setRoomSettings(state, payload) {
      state.roomSettings = payload;
    },
    setRules(state, payload) {
      state.roomSettings.rules = payload;
    },
    setLaundryRoomAllowAccess(state, payload) {
      state.laundryRoomAllowAccess = payload
    },
    setRoomBookings(state, payload) {
      state.roomBookings = payload;
    },
    setSelectedMachine(state, payload) {
      state.selectedMachine = payload;
    },
    setShowReservationModal(state, payload) {
      state.showReservationModal = payload;
    },
    setShowDeleteBookingModal(state, payload) {
      state.showDeleteBookingModal = payload;
    },
    setDeleteBookingObject(state, playload) {
      state.deleteBookingObject = playload;
    },
    setSubscription(state, payload) {
      state.subscription = payload;
    }
  },
  getters: {
    getUserIsConnected(state) {
      return state.userIsConnected;
    },
    getUserDBInfo(state) {
      return state.userDBInfo;
    },
    getUser(state) {
      return state.user;
    },
    testGetter(state) {
      return state.appIsReady;
    },
    getAppIsReady(state) {
      return state.appIsReady;
    },
    getTestDate(state) {
      return state.testDate;
    },
    getRoomSettings(state) {
      return state.roomSettings;
    },
    getRules(state) {
      return state.roomSettings.rules;
    },
    getLaundryRoomAllowAccess(state) {
      return state.laundryRoomAllowAccess;
    },
    getRoomBookings(state) {
      return state.roomBookings;
    },
    getRoomSubscription(state) {
      return state.subscription
    },
    getDate(state) {
      try {
        var d = new Date();
        d.setFullYear(state.date.year, state.date.month, state.date.date);
        d.setHours(0);
        d.setMinutes(0);
        d.setSeconds(0);
        d.setMilliseconds(0);
      } catch (error) {
        console.log(error);
      }
      return d;
    },
    getShowReservationModal(state) {
      return state.showReservationModal;
    },
    getShowDeleteBookingModal(state) {
      return state.showDeleteBookingModal;
    },
    getSelectedMachine(state) {
      return state.selectedMachine;
    },
    getDeleteBookingObject(state) {
      return state.deleteBookingObject;
    },
  },
  actions: {
    async signup(context, { email, password }) {
      const res = await createUserWithEmailAndPassword(auth, email, password);
      if (res) {
        context.commit("setUser", res.user);
      } else {
        throw new Error("could not complete signup");
      }
    },
    async getRoomBookingsAndSettings(context, roomID) {
      try {
        const db = getDatabase();
        //bookings
        const roomBookingsRef = ref(db, `laundryrooms/${roomID}/bookings`);
        onValue(roomBookingsRef, (snapshot) => {
          const data = snapshot.val();
          context.commit("setRoomBookings", data);
          context.commit("setAppIsReadyBookings", true);
        });

        //Settings
        const roomSettingsRef = ref(db, `laundryrooms/${roomID}/settings`);
        onValue(roomSettingsRef, (snapshot) => {
          const data = snapshot.val();
          context.commit("setRoomSettings", data);
          context.commit("setAppIsReadySettings", true);
        });

        //Subscription
        const subscriptionsRef = ref(db, `subscriptions/${roomID}`);
        onValue(subscriptionsRef, (snapshot) => {
          const data = snapshot.val();
          context.commit("setSubscription", data);
          context.commit("setAppIsReadySubscription", true);
        });
        //laundryRoomAllowAccess
        const laundryRoomAllowAccessRef = ref(db, `laundryRoomAllowAccess/${roomID}`); //  <----------- HERE is somthing wrong
        onValue(laundryRoomAllowAccessRef, (snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            context.commit("setLaundryRoomAllowAccess", data);
            context.commit("setAppIsReadyLaundryRoomAllowAccess", true);
          } else {
            context.commit("setLaundryRoomAllowAccess", false);
            context.commit("setAppIsReadyLaundryRoomAllowAccess", true);
          }
        });

        //Start listner for connectivity
        const connectedRef = ref(getDatabase(), ".info/connected");
        onValue(connectedRef, (snap) => {
          if (snap.val() === true) {
            console.log("connected");
            store.commit("setUserIsConnected", true);
          } else {
            console.log("not connected");
            store.commit("setUserIsConnected", false);
          }
        })
        
      } catch (error) {
        console.log("Something went wrong in getRoomBookingsAndSettings");
      }
    },
    async login(context, { email, password }) {
      const res = await signInWithEmailAndPassword(auth, email, password);
      if (res) {
        context.commit("setUser", res.user);
        try {
          const dbref = ref(getDatabase());
          onValue(
            child(dbref, `users/${context.state.user.uid}`), async (userInfoObj) => { 
          
          context.commit("setUserDBInfo", userInfoObj.val());
          if (userInfoObj.val().laundryroom) {
            const roomID = userInfoObj.val().laundryroom;
            get(child(dbref, `laundryrooms/${roomID}/settings`)).then(laundryroomObj => {
              if (roomID.length > 1 && laundryroomObj.exists() && laundryroomObj.val().roomIsActive) {
                context.commit("setUserIsInARoom", true);
              } else {
                context.commit("setRedirectToNotInARoom", true);
              }
            })
            await context.dispatch("getRoomBookingsAndSettings", roomID); 
          }
        });
        } catch (error) {
          console.log(
            "Something went wrong when getting user info from DB during login"
          );
          context.commit("setUserDBInfo", null);
        }
        context.commit("setAppIsReadyUser", true);
      } else {
        throw new Error("could not complete login");
      }
    },
    async logout(context) {
      await signOut(auth);
      context.commit("setUser", null);
      context.commit("setUserDBInfo", null);
      context.commit("setAppIsReadyUser", false);
      context.commit("setAppIsReadySettings", false);
      context.commit("setAppIsReadyBookings", false);
      context.commit('setAppIsReadySubscription', false);
      context.commit("setUserIsInARoom", false);
      context.commit("setRoomSettings", false);
      context.commit("setRoomBookings", false);
      context.commit("setRedirectToNotInARoom", false);
      context.commit("setLaundryRoomAllowAccess", false);
      context.commit("setUserIsConnected", false);

    },
    testAction(context) {
      console.log("Test Action in store is called!!");
    },
    async setAllInitialInfo(context) {
      const unsub = onAuthStateChanged(auth, async (user) => {
        try {
          const dbref = ref(getDatabase());
          onValue(child(dbref, `users/${user.uid}`), async (userInfoObj) => {
            store.commit("setUserDBInfo", userInfoObj.val());
            if (userInfoObj.val().laundryroom) {
                const roomID = userInfoObj.val().laundryroom;
                get(child(dbref, `laundryrooms/${roomID}/settings`)).then(laundryroomObj => {
                  if (roomID.length > 1 && laundryroomObj.exists() && laundryroomObj.val().roomIsActive) {
                    context.commit("setUserIsInARoom", true);
                  } else {
                    context.commit("setRedirectToNotInARoom", true);
                  }
                })
                await context.dispatch("getRoomBookingsAndSettings", roomID); 
            }
          });
        } catch (error) {
          console.error('There was an error fetching db user', error);
          store.commit("setUserDBInfo", null);
        }
        store.commit("setAuthIsReady", true);
        store.commit("setUser", user);
        if (user) {
          store.commit("setAppIsReadyUser", true);
        } else {
          store.commit("setAppIsReadyUser", false);
        }

        unsub();
      });
    },
    async updateName({ commit, state }, name) {
      try {
        const dbref = ref(getDatabase());
        await update(child(dbref, `users/${state.user.uid}`), {
          name,
        });
        const userInfoObj = await get(child(dbref, `users/${state.user.uid}`));
        commit("setUserDBInfo", userInfoObj.val());
        return userInfoObj.val();
      } catch (error) {
        console.error("There was an error while updating the name", error);
      }
    },
    async createSubscription(context, data) {
      try {
        const {roomID, ...subscription} = data;
        const db = getDatabase();
        const updates = {};
        updates[`subscriptions/${roomID}`] = subscription;
        updates[`laundryrooms/${roomID}/settings/subscription`] = true;
        await update(ref(db), updates);
        return true;
      } catch (error) {
        console.error("There was an error while creating the subscription", error);
        return false;
      }
    },
    async cancelSubscription({state}) {
      try {
        const roomId = state.userDBInfo.laundryroom;
        const db = getDatabase();
        const updates = {};
        updates[`subscriptions/${roomId}/hasCancelled`] = true;
        updates[`subscriptions/${roomId}/cancelledDate`] = new Date().getTime();
        updates[`laundryrooms/${roomId}/settings/subscription`] = false;
        await update(ref(db), updates);
        return true;
      } catch (error) {
        console.error("There was an error while cancelling the subscription", error);
        return false;
      }
    },
    async joinRoom(context, roomID) {
      try {
        const db = getDatabase();
        // Check if room exists
        const roomIsActive = ref(db, `list/${roomID}`);
        const roomIsActiveSnapshot = await get(roomIsActive);
        const room = roomIsActiveSnapshot.val();
        if (room) {  
          const updates = {};
          updates[`users/${context.state.user.uid}/laundryroom`] = roomID;
          await update(ref(db), updates);
          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.error("There was an error while joining the room", error);
        return false;
      }
    },
    async deleteLaundryroom(context, roomID) {
      try {
        const db = getDatabase();
        const updates = {};
        updates[`laundryrooms/${roomID}/settings/admin`] = null;
        updates[`laundryrooms/${roomID}/settings/roomIsActive`] = false;
        updates[`list/${roomID}`] = false;
        await update(ref(db), updates);
        return true;
      } catch (error) {
        console.error("There was an error while deleting the room", error);
        return false;
      }
    },
    async deleteBookings(context, {roomId, owner}) {
      // Delete all bookings from realtime db where owner is the same as the user in the laundryrooms
      try {
        const db = getDatabase();
        const bookingsRef = ref(db, `laundryrooms/${roomId}/bookings`);
        const bookingsSnapshot = await get(bookingsRef);
        const updates = {};
        bookingsSnapshot.forEach((booking) => {
          if (booking.val().owner === owner) {
            updates[`laundryrooms/${roomId}/bookings/${booking.key}`] = null;
          }
        });
        await update(ref(db), updates);
        return true;
      } catch (error) {
        console.error("There was an error while deleting the bookings", error);
        return false;
      }
    },
    async deleteUser(context, userId) {
      try {
        const db = getDatabase();
        const updates = {};
        updates[`users/${userId}`] = null;
        await update(ref(db), updates);
        return true;
      } catch (error) {
        console.error("There was an error while deleting the user", error);
        return false;
      }
    },
    disableRulesExceptFor
  },
});

store.dispatch("setAllInitialInfo");

export default store;
