<template>
<div class="main">
    <h1>Åbningstid</h1>
    <p>Vælg en ny åbningstid for vaskerummet.</p>
    <time-picker @change="handleBookingTimeChange" :startHour="bookingTime.startHour" :startMinutes="bookingTime.startMinutes" :endHour="bookingTime.endHour" :endMinutes="bookingTime.endMinutes"/>
    <div class="button-container">
      <secondary-button :disabled="isLoading" @click="handleCancel">Annuller</secondary-button>
      <primary-button :disabled="isLoading" @click="handleChangeHours" class="margin-left">{{isLoading ? 'Gemmer...' : 'OK'}}</primary-button>
    </div>
</div>
</template>

<script setup>
import TimePicker from '@/components/TimePicker';
import PrimaryButton from './PrimaryButton.vue';
import SecondaryButton from './SecondaryButton.vue';
import {reactive, ref, computed} from 'vue';
import { useToast } from 'vue-toastification';
import { getDatabase, ref as firebaseRef, update } from "firebase/database";
import {useStore} from 'vuex';

const store = useStore();

const emit = defineEmits(['cancel', 'confirm'])
const isLoading = ref(false)
const toast = useToast()
const userDBInfo = computed(() => store.state.userDBInfo);

const handleCancel = () => {
    emit('cancel')
}

const bookingTime = reactive({
    startHour: store.state.roomSettings?.startHour ?? 0,
    startMinutes: store.state.roomSettings?.startMinutes ?? 0,
    endHour: store.state.roomSettings?.endHour ?? 0,
    endMinutes: store.state.roomSettings?.endMinutes ?? 0
})

const handleBookingTimeChange = ({startHour, startMinutes, endHour, endMinutes}) => {
  bookingTime.startHour = startHour
  bookingTime.startMinutes = startMinutes
  bookingTime.endHour = endHour
  bookingTime.endMinutes = endMinutes
}

const handleChangeHours = async () => {
    isLoading.value = true
    try {
        const db = getDatabase();
        const laundryRoomId = store.state.userDBInfo.laundryroom;
        await update(firebaseRef(db,'laundryrooms/' + laundryRoomId + '/settings/'), bookingTime) 
        toast.success('Åbningstiden er blevet opdateret');
        store.commit('setRoomSettings', {...store.state.roomSettings, ...bookingTime})
        emit('confirm')
    } catch (error) {
        toast.error('Fejl ved ændring af åbningstiden')
        console.error(error)
    }
    isLoading.value = false
}
</script>

<style scoped>
.main {
  border-radius: 4px;
  width: 80%;
  height: 100vh;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.margin-left {
  margin-left: 4px;
}
.button-container {
  display: flex;
}
h1 {
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0px;
}
p {
    margin-top: 24px;
    margin-bottom: 24px;
    font-size: 1rem;
    text-align: center;
}
</style>