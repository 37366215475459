import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/main.css";
import normalize from "normalize.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import "./registerServiceWorker";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faBars,
  faClose,
  faExclamationCircle,
  faUserSecret,
  faAngleDown,
  faAngleUp,
  faAngleLeft,
  faAngleRight,
  faPlus,
  faCalendarAlt,
  faCalendar,
  faEdit,
  faCircleQuestion,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import Toast, { POSITION } from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

library.add(
  faUserSecret,
  faBars,
  faClose,
  faExclamationCircle,
  faAngleDown,
  faAngleUp,
  faAngleLeft,
  faAngleRight,
  faPlus,
  faCalendarAlt,
  faCalendar,
  faEdit,
  faCircleQuestion,
  faTrashCan
);

createApp(App)
  .use(store)
  .use(router)
  .use(normalize)
  .use(Toast, {
    position: POSITION.TOP_CENTER,
  })
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
