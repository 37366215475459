<template>
  <div class="margin-top-medium">
    <div class="checkbox-container">
      <input
        :checked="modelValue"
        @change="updateValue"
        type="checkbox"
        :id="id"
      />
      <label :for="id">
        <slot />
      </label>
    </div>
    <div v-if="hasError" class="error">{{ errorMessage }}</div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: Boolean,
    id: String,
    hasError: Boolean,
    errorMessage: String,
  },
  methods: {
    updateValue(event) {
      this.$emit("update:modelValue", event.target.checked);
    },
  },
};
</script>

<style scoped>
label {
  margin-bottom: 0px;
  color: #6a6a6a;
  font-weight: 600;
  font-size: 1rem;
  margin-top: 0px;
}

input {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

input:focus {
  outline: none;
}

.error {
  font-size: 16px;
  color: #ff5a5f;
}

.margin-top-medium {
  margin-top: 8px;
}

.checkbox-container {
  display: flex;
  align-items: center;
}
</style>
