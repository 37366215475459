<template>
  <div>
    <div class="headerWrapWhiteBackground" :style="{ height: wrapperHeight + 'px'}"></div>
      <div class="headerWrap" ref="headerWrapper">
        <calendar></calendar>
        <machine-selection></machine-selection>
      </div>
    <booking-loader v-bind:firebooking="fireBooking" v-bind:selectedDay="selectedDateRecived" v-bind:machineSelection="machineSelection"></booking-loader>
    <br />
    <div class="addButton" v-if="isTheUserAllowed">
      <div v-on:click="addBooking">
        <font-awesome-icon class="iconClass" icon="plus" size="2x"/>
        <!-- <icon class="iconClass" name="plus" scale="2"></icon> -->
        </div>
    </div>

    <transition name="fade">
      <reservation-modal
      v-if="showReservationModal"
      v-bind:computedSelectionDay="computedSelectionDay"
      v-bind:selectedDateRecived="selectedDateRecived"
      v-bind:machineSelection="machineSelection"
      v-bind:firebooking="fireBooking"
      v-bind:fireSettings="fireSettings">
      </reservation-modal>
    </transition>

    <transition name="fade">
      <delete-booking-modal
      v-if="showDeleteBookingModal"
      v-bind:selectedDay="selectedDateRecived">
      </delete-booking-modal>
    </transition>

    <block-none-paying v-if="!isTheUserAllowed"
    v-bind:somedata="fireSettings"
    v-bind:someotherdata="fireSubscription">
      <!-- {{fireSettings}} -->
    </block-none-paying>


      <!-- v-bind:deleteBookingObject="deleteBookingObject" -->


    <!-- <transition name="fade">
      <info-modal v-if="showModal" @close="closeModalSetCookie">
        <div slot="body">Addressændring</div>
        <div slot="smalltext">Fra d. 18 Juni vil webadressen skifte fra vaskerum.dk til <b>app.vaskerum.dk</b> så husk at lave din genvej om, hvis du har lavet en.<br /><br />
          app.vaskerum.dk virker allerede fra idag.<br />
        </div>
      </info-modal>
    </transition> -->    
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
// import { app } from '@/firebase'
// import { db } from '@/firebase'
// import bus from '@/bus.js'
import Calendar from '@/components/calendar.vue'
import machineSelection from '@/components/machineSelection.vue'
import bookingLoader from '@/components/bookingLoader.vue'
import reservationModal from '@/components/ReservationModal'
import deleteBookingModal from '@/components/deleteBookingModal.vue'
import blockNonePaying from '@/components/blockNonePaying.vue'
import * as dayjs from 'dayjs'
import * as relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)
// import infoModal from '@/components/infoModal.vue'

export default {
  data () {
    return {
      // machineSelection: 'wash',
      // computedSelectionDay: {},
      // selectedDateRecived: {},
      bookingInterval: {startHour: 7, startMinutes: 15, endHour: 10, endMinutes: 0},
      bookingTime: {startHour: 7, startMinutes: 15, endHour: 10, endMinutes: 0},
      // showReservationModal: false,
      // showDeleteBookingModal: false,
      // deleteBookingObject: {},
      showModal: false,
      modalInfoText: 'Fra d. 18 Juni vil webadressen være app.vaskerum.dk så husk at lave din genvej om, hvis du har lavet en!',
      wrapperHeight: 5,
      testData: false,
      newTrialDateStart: 1697634000000
    }
  },
  components: {
    'calendar': Calendar,
    'machine-selection': machineSelection,
    'booking-loader': bookingLoader,
    'reservation-modal': reservationModal,
    'delete-booking-modal': deleteBookingModal,
    'block-none-paying':blockNonePaying
    // 'info-modal': infoModal
  },
  computed: {
    ...mapGetters({
      computedSelectionDay:'getTestDate',
      selectedDateRecived: 'getDate',
      fireSettings:'getRoomSettings',
      fireBooking: 'getRoomBookings',
      fireSubscription: 'getRoomSubscription',
      showReservationModal: 'getShowReservationModal',
      showDeleteBookingModal: 'getShowDeleteBookingModal',
      machineSelection: 'getSelectedMachine',
      laundryRoomAllowAccess: 'getLaundryRoomAllowAccess'
    }),
    isTheUserAllowed: function(){ 

      // if(isAppReady.user && isAppReady.bookings && isAppReady.settings && isAppReady.subscription) {
      //   console.log('lets go!')
      // }
      const createdTimeDate = this.fireSettings.createdTimeDate
      console.log('loaded')
      let subscriptionActivated
        if (this.fireSubscription == null) {
          subscriptionActivated = 0
        } else {
          subscriptionActivated = this.fireSubscription.subscriptionActivated
          
        }

      const createdTimeDatePlusTwoMonths = dayjs(createdTimeDate).add(2, 'months')
      const createdTimeDatePlusTwoWeeks = dayjs(createdTimeDate).add(15, 'days')
      const subscriptionActivatedPlusTwoWeeks = dayjs(subscriptionActivated).add(15, 'day')

      if(createdTimeDatePlusTwoMonths.diff(dayjs(), 'days') > 0 && this.fireSettings.createdTimeDate < this.newTrialDateStart)  { //Is trial period active? 
      //If room created less than 60 days ago -> Allow
        console.log("Trial period is active (60 days)")
        return true
      }
      // && this.fireSettings.createdTimeDate > this.newTrialDateStart
      else if(createdTimeDatePlusTwoWeeks.diff(dayjs(), 'days') > 0 && this.fireSettings.createdTimeDate > this.newTrialDateStart)  { //Is trial period active (two weeks)? 
      //If room created less than 14 days ago -> Allow
        console.log("Trial period is active (two weeks) ")
        return true
      } 
      else if (this.fireSubscription != null && subscriptionActivatedPlusTwoWeeks.diff(dayjs(), 'days') > 0) { //Did the user sign-up for subscription less than two weeks ago?
      //Else if subscription node is not null and subscriptioni adate is less than 14 days -> Allow
        console.log("User sign-up for subscription less than two weeks ago")
        return true
      }
      else if(this.laundryRoomAllowAccess) {
      //Else if laundryRoomAllowAccess is true -> Allow 
        console.log("Laundry room access allowed")
        return true
      } 
      else {
      //Else do not allow
      console.log("User not allowed to access")
        return false
      }
      
    }
    // isTheUserAllowedBackup: function(){
    //   //variables
    //   const currentDate = new Date().getTime();
    //   const twoWeeksInMilliseconds = 14 * 24 * 60 * 60 * 1000; // 14 days * 24 hours * 60 minutes * 60 seconds * 1000 milliseconds
    //   const sixtyDaysInMilliseconds = 60 * 24 * 60 * 60 * 1000;
    //   const oneYearInMilliseconds = 365 * 24 * 60 * 60 * 1000    

    //   //Firebase DB values
    //   const createdTimeDate = this.fireSettings.createdTimeDate
    //   const subscription = this.fireSettings.subscription
    //   var roomExist = false
    //   let subscriptionActivated
    //   let lastPayment
    //   let hasCancelled
    //     if (this.fireSubscription == null) {
    //       roomExist = false
    //       subscriptionActivated = 0
    //       lastPayment = 0
    //       hasCancelled = false
    //     } else {
    //       roomExist = true
    //       subscriptionActivated = this.fireSubscription.subscriptionActivated
    //       lastPayment = this.fireSubscription.lastPayment
    //       hasCancelled = this.fireSubscription.hasCancelled
          
    //     }

    //   // const createdTimeDatePlusTwo = new Date(createdTimeDate + twoWeeksInMilliseconds).getTime();
    //   // const createdTimeDatePlusTwoMonths = new Date(createdTimeDate + sixtyDaysInMilliseconds).getTime();
    //   // const lastPaymentPlusOneYear = new Date(lastPayment + oneYearInMilliseconds).getTime();
    //   // const lastPaymentPlusOneYearAndTwo = new Date(lastPayment + (oneYearInMilliseconds + twoWeeksInMilliseconds)).getTime();
    //   // const subscriptionActivatedPlusTwo = new Date(subscriptionActivated + twoWeeksInMilliseconds).getTime();

    //   const createdTimeDatePlusTwoMonths = dayjs(createdTimeDate).add(2, 'months')
    //   const subscriptionActivatedPlusTwoWeeks = dayjs(subscriptionActivated).add(15, 'day')
    //   const subscriptionActivatedPlusOneYear = dayjs(subscriptionActivated).add(1, 'year').diff(dayjs(), 'days)')
    //   let lastPaymentPlusOneYear
    //   if (lastPayment > 0 ) {
    //     lastPaymentPlusOneYear = dayjs(lastPayment).add(1, 'year').diff(dayjs(), 'days)')
    //   } else {
    //     lastPaymentPlusOneYear = 0
    //   }

    //   if(createdTimeDatePlusTwoMonths.diff(dayjs(), 'days') > 0)  { //Is trial period active?
    //     console.log("Trial period is active")
    //     return true
    //   } 
    //   else if(subscription == true){ //Is the user signed up for subscription?
    //     if(subscriptionActivatedPlusTwoWeeks.diff(dayjs(), 'days') > 0){ //Did the user sign-up for subscription less than two weeks ago?
    //       console.log("User sign-up for subscription less than two weeks ago")
    //       return true
    //     }
    //     else if(lastPaymentPlusOneYear > 0 && subscriptionActivatedPlusOneYear > 0) { //Has the user paid for the subscription?
    //       console.log("The user has paid for the subscription")
    //       return true
    //     }
    //     else {
    //       console.log("User has not paid for the subscription")
    //       return false
    //     }
    //   }
    //   else if(roomExist == true && 
    //           hasCancelled == true && 
    //           lastPaymentPlusOneYear > 0 && 
    //           subscriptionActivatedPlusOneYear > 0) { //Did the user cancel the subscription and have days left on subscription?
    //     console.log("User cancled but has days left on subscription")
    //     return true
    //   }
    //   else {
    //     console.log("Trial period ended or user canceled and do not have days left on subscription")
    //     return false
    //   }
    // }
  },
  methods: {
    closeReservationModal() {
    this.showReservationModal = false;
    },
    updateWhiteBackgroundHeight() {
      this.wrapperHeight = this.$refs.headerWrapper.clientHeight
    },
    addBooking() {
      this.$store.commit('setShowReservationModal', true)
    },
    //Cookie Modal Start
    closeModalSetCookie() {
      this.setCookie('infoModal','hide',4)
      this.showModal = false

    },
    getCookie: function (cname) {
      var name = cname + "=";
      var ca = document.cookie.split(';');
      for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    setCookie: function (cname,cvalue,exdays) {
      var d = new Date();
      d.setTime(d.getTime() + (exdays*24*60*60*1000));
      var expires = "expires=" + d.toGMTString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },
    checkCookie: function(cookieName) {
      var cookieValue = this.getCookie(cookieName);
      if (cookieValue == "hide") {
        return false
      } else {
        return true
      }
    }
  },
  watch: {
    machineSelection: function () {
      // this.updateWhiteBackgroundHeight()
      setTimeout(this.updateWhiteBackgroundHeight, 5);
    },
    fireSettings: function(newSettings) {
      this.updateWhiteBackgroundHeight()
      if(!newSettings.roomIsActive){
        window.location.reload()
      }
    }

  },
  mounted () {
    this.updateWhiteBackgroundHeight()

    // this.showModal = this.checkCookie('infoModal')
    // console.log(this.showReservationModal)
  },
  created () {
    //------------------------------------------
    // bus.$on('machine', data => {
    //   this.machineSelection = data
    // })
    //------------------------------------------
    
    //------------------------------------------
    // bus.$on('date', data => {
    //   var d = new Date()
    //   d.setFullYear(data.year, data.month, data.date)
    //   d.setHours(0)
    //   d.setMinutes(0)
    //   d.setSeconds(0)
    //   d.setMilliseconds(0)
    //   this.selectedDateRecived = d
    // })
    //------------------------------------------

    //------------------------------------------
    // bus.$on('closeModal', data => {
    //   this.showReservationModal = false
    // })
    //------------------------------------------


    //------------------------------------------
    // bus.$on('testDate', data => {
    //   this.computedSelectionDay = data
    // })
    //------------------------------------------


    // bus.$on('closeDeleteModal', data => {
    //   this.showDeleteBookingModal = false
    // })
    // bus.$on('showDeleteModal', data => {
    //   this.deleteBookingObject = data
    //   this.showDeleteBookingModal = true
    // })
  }
}




</script>

<style scoped>
* {
  box-sizing: border-box;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

button {
  background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.addButton {
  text-align: center;
  /* box-shadow: 0px 3px 14px 0px rgba(0, 0, 0, 0.4); */
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.3);
  /* box-shadow: 3px 3px 2px 0px rgba(0, 0, 0, 0.3); */
  cursor: pointer;
  position: fixed;
  display: block;
  width: 45px;
  height: 45px;
  line-height: 45px;
  border-radius: 100px;
  bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  background-color: #00BAE0;
}

.iconClass {
  margin: 7px auto;
  color: white;
}

.headerWrap {
  background: linear-gradient(rgba(0, 186, 224, 1), rgba(0, 186, 224, 0.5));
  /* border-radius: 0px 0px 3px 3px; */
  position: absolute;
  width: 100%;
  top: 40px;
  z-index: 18;
}

.headerWrapWhiteBackground {
  position: absolute;
  background: white;
  width: 100%;
  top: 40px;
  z-index: 18;

}


</style>
