<template>
<main class="backgroundClass">
  <section v-if="currentView === 'info'" class="infoBox">
    <div class="border-bottom">
      <edit-field
        title="ID - vaskerum"
        :content="userDBInfo.laundryroom"
        :isEditable="false"
        placeholder="Room ID"
      />
    </div>
    <div class="border-bottom">
      <edit-field
        title="Åbningstid"
        :content="`${formatNumber(roomSettings.startHour)}:${formatNumber(roomSettings.startMinutes)} - ${formatNumber(roomSettings.endHour)}:${formatNumber(roomSettings.endMinutes)}`"
        :isEditable="isAdmin"
        :showBorder="false"
        placeholder="00:00 - 00:00"
        @edit="handleEdit('openingHours')"
      />
    </div>
    <div class="border-bottom">
      <edit-field
        title="Maskiner"
        :isEditable="isAdmin"
        :customSlot="true"
        :showBorder="false"
        @edit="handleEdit('machineCount')"
      >
        <div class="content">
          <div>Vaskemaskiner: {{roomSettings.washMachineAmount}}</div>
          <div class="margin-top">Tørretumbler: {{roomSettings.dryerMachineAmount}}</div>
        </div>
      </edit-field>
    </div>
    <div class="border-bottom">
      <div class="limits-title">
        <div>Vaskerumsregler</div>
        <font-awesome-icon @click="handleEdit('limitsExplained')" icon="circle-question" />
      </div>
      <edit-field
        :isEditable="isAdmin"
        :customSlot="true"
        :showBorder="false"
        @edit="handleEdit('changeBookingLimits')"
      >
        <div class="content">
          <b>Tidsbegrænsning på bookinger</b>
          <div>Min: {{ minimumBookingLimit }} minutter</div>
          <div>Maks: {{ maximumBookingLimit ? `${maximumBookingLimit} minutter` : "Ingen" }}</div>
        </div>
      </edit-field>
      <edit-field
        :isEditable="isAdmin"
        :customSlot="true"
        :showBorder="false"
        @edit="handleEdit('changeBookingInterval')"
      >
        <div class="content">
          <b>Bookinginterval</b>
          <div>{{ bookingInterval ? `${bookingInterval} minutter` : 'Deaktiveret' }}</div>
        </div>
      </edit-field>
      <edit-field
        :isEditable="isAdmin"
        :customSlot="true"
        :showBorder="false"
        @edit="handleEdit('changePredefinedTimeslots')"
      >
        <div class="content">
          <b>Timeslots</b>
          <div v-if="predefinedTimeslots.length">
            <p class="timeslot" v-for="timeslot in predefinedTimeslots">
              {{ timeslot }}
            </p>
          </div>
          <div v-else>Deaktiveret</div>
        </div>
      </edit-field>
      <edit-field
        :isEditable="isAdmin"
        :customSlot="true"
        :showBorder="false"
        @edit="handleEdit('changeMaxActiveBookings')"
      >
        <div class="content">
          <b>Tilladte aktive bookinger</b>
          <div v-if="!maxActiveBookings">Deaktiveret</div>
          <div v-else-if="maxActiveBookings === 1">1 booking</div>
          <div v-else>{{ maxActiveBookings }} bookinger</div>
        </div>
      </edit-field>
    </div>
    <edit-field
      title="Abonnement"
      :isEditable="false"
      :customSlot="true"
      :showBorder="false"
    >
      <div class="content">
        <div>
          <b>Type:</b> {{roomSettings?.subscription ? 'Betalende' : 'Prøve'}}
        </div>
        <div v-if="isAdmin && roomSettings?.subscription">
          <b>Sidste betaling: </b> {{((subscription?.lastPayment == 0) ? 'Ingen' : lastPaymentDate)}}
        </div>
        <div v-if="isAdmin"><b>Status: </b>{{ statusText }}</div>
        <div v-if="isAdmin && roomSettings?.subscription && subscription.subscriptionActivated">
          <b>Abonnement oprettet: </b>
          {{ dayjs(subscription.subscriptionActivated).format('DD-MM-YYYY') }}
        </div>
        <div v-if="isAdmin">
          <primary-button @click="handleEdit('subscription')" v-if="!roomSettings?.subscription">
            Opret abonnement
          </primary-button>
          <secondary-button @click="handleEdit('subscription')" v-else >
            Ret abonnement
          </secondary-button>
        </div>
      </div>
    </edit-field>
  </section>
  <change-hours @cancel="handleCloseEdit" @confirm="handleCloseEdit" v-if="currentView === 'openingHours'" />
  <change-machines @cancel="handleCloseEdit" @confirm="handleCloseEdit" v-if="currentView === 'machineCount'" />
  <subscription-form @cancel="handleCloseEdit" @confirm="handleCloseEdit" @cancel-subscription="handleEdit('cancelSubscription')" v-if="currentView === 'subscription'" />
  <cancel-subscription @close-cancel-subscription="handleCloseEdit" v-if="currentView === 'cancelSubscription'" />
  <limits-explained @close="handleCloseEdit" v-if="currentView === 'limitsExplained'" />
  <change-booking-limits @close="handleCloseEdit" v-if="currentView === 'changeBookingLimits'"/>
  <change-booking-interval @close="handleCloseEdit" v-if="currentView === 'changeBookingInterval'"/>
  <change-predefined-timeslots @close="handleCloseEdit" v-if="currentView === 'changePredefinedTimeslots'"/>
  <change-max-active-bookings @close="handleCloseEdit" v-if="currentView === 'changeMaxActiveBookings'"/>
</main>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import EditField from '../components/EditField.vue';
import ChangeHours from '../components/ChangeHours.vue';
import ChangeMachines from '../components/ChangeMachines.vue';
import PrimaryButton from '../components/PrimaryButton.vue';
import SecondaryButton from '../components/SecondaryButton.vue';
import SubscriptionForm from '../components/SubscriptionForm';
import CancelSubscription from '../components/CancelSubscription.vue';
import * as dayjs from 'dayjs'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import LimitsExplained from '../components/LimitsExplained.vue';
import ChangeBookingLimits from '../components/ChangeBookingLimits.vue';
import ChangeBookingInterval from '../components/ChangeBookingInterval.vue';
import ChangePredefinedTimeslots from "../components/ChangePredefinedTimeslots";
import ChangeMaxActiveBookings from "../components/ChangeMaxActiveBookings";
dayjs.extend(relativeTime)

const store = useStore();
const userDBInfo = computed(() => store.state.userDBInfo);
const roomSettings = computed(() => store.state.roomSettings);
const isAdmin = computed(() => store.state.user.uid === store.state.roomSettings.admin);
const currentView = ref('info');
const subscription = computed(() => store.state.subscription);
const lastPaymentDate = computed(() => {
  const months = ['januar','februar','marts','april','maj','juni','juli','august','september','oktober','november','december']
  const dateObj = new Date(store.state.subscription?.lastPayment)
  const dateInString = `${dateObj.getDate()} ${months[dateObj.getMonth()]} ${dateObj.getFullYear()}`
  return dateInString
})

const newTrialDateStart = computed(()=> {
  const startDate = 1697634000000
  return startDate
})

const maxActiveBookings = computed(() => store.state.roomSettings.rules?.maxActiveBookings);
const minimumBookingLimit = computed(() => store.state.roomSettings.rules?.bookingLimits?.minimumTime || 15);
const maximumBookingLimit = computed(() => store.state.roomSettings.rules?.bookingLimits?.maximumTime);
const bookingInterval = computed(() => store.state.roomSettings.rules?.bookingInterval || 0);
const predefinedTimeslots = computed(() => {
  const predefinedTimeslots =
    store.state.roomSettings.rules?.predefinedTimeslots;

  if (!predefinedTimeslots) return [];

  const timeslots = Object.values(predefinedTimeslots);
  const result = [];

  for (let i = 0; i < timeslots.length; i += 2) {
    const curr = `${timeslots[i].startHour
      .toString()
      .padStart(2, "0")}:${timeslots[i].startMinutes
      .toString()
      .padStart(2, "0")}-${timeslots[i].endHour
      .toString()
      .padStart(2, "0")}:${timeslots[i].endMinutes
      .toString()
      .padStart(2, "0")}`;

    if (i + 1 === timeslots.length) {
      result.push(curr);
    } else {
      const next = `${timeslots[i + 1].startHour
        .toString()
        .padStart(2, "0")}:${timeslots[i + 1].startMinutes
        .toString()
        .padStart(2, "0")}-${timeslots[i + 1].endHour
        .toString()
        .padStart(2, "0")}:${timeslots[i + 1].endMinutes
        .toString()
        .padStart(2, "0")}`;

      result.push(curr + " / " + next);
    }
  }

  return result;
});

const formatNumber = (number) => {
  return number.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  })
}

const handleEdit = (view) => currentView.value = view;

const handleCloseEdit = () => currentView.value = 'info';

const getRemainingDays = (startDate, duration, unit) => dayjs(startDate).add(duration, unit).diff(dayjs(), 'days');

const getTrialDays = () => {
  const { createdTimeDate } = roomSettings.value;

  if (createdTimeDate < newTrialDateStart.value) return getRemainingDays(createdTimeDate, 2, 'months');

  return getRemainingDays(createdTimeDate, 14, 'days');
};

const statusText = computed(() => {
  if (subscription.value) {
    const { subscriptionActivated, hasCancelled, cancelledDate } = subscription.value;
    const remainingDays = getRemainingDays(subscriptionActivated, 1, 'year');

    if (hasCancelled && cancelledDate) {
      const cancelled = dayjs(cancelledDate);
      const activated = dayjs(subscriptionActivated);
      const diff = cancelled.diff(activated, 'days');
      const fullRenewals = Math.floor(diff / 365);
      const renewalDate = activated.set('year', activated.get('year') + fullRenewals);
      const remainingDays = getRemainingDays(renewalDate, 1, 'year');
      if (remainingDays  > 0) return remainingDays + ' dage til udløb';
      return 'Automatic renewal disabled';
    } else if (roomSettings?.value?.subscription) {
      if (remainingDays <= 0) {
        const newSubscriptionDate = dayjs().subtract(Math.abs(remainingDays) % 365, 'days').valueOf();
        const newRemainingDays = getRemainingDays(newSubscriptionDate, 1, 'year');
        return newRemainingDays + ' dage til fornyelse';
      }

      return remainingDays + ' dage til fornyelse';
    }
  }

  const remainingTrialDays = getTrialDays();

  if (remainingTrialDays > 0) return remainingTrialDays + ' dage tilbage';

  return 'Udløbet';
});
</script>

<style scoped>
.infoBox {
  padding: 30px 10%;
  margin: auto;
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  gap: 6px;
}
.limits-title {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 6px;
  color: #545454;
  font-weight: 600;
  font-size: 1rem;
  
  & > svg {
    color: #9ca3af;
    height: 18px;
    cursor: pointer;
  }
}
.backgroundClass {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  max-width: 450px;
  margin: 0 auto;
  overflow: scroll;
  display: flex;
  padding-top: 50px;
  padding-bottom: 50px;
}
.border-bottom {
  border-bottom: 2px solid #e5e7eb;
}
.content {
  color: #838383;
  font-size: 0.9rem;
  padding: 6px 0;
  gap: 8px;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.timeslot {
  margin: 0 0 10px;
}
</style>
