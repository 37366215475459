<template>
<div class="timeSelectorWrap">
          <div class="timerWrap">
            <div class="clockWrap hours">
              <button v-on:click="changeHour('+', 'start')" class="iconWrapPlus">
                <font-awesome-icon class="angle-up" icon="angle-up"></font-awesome-icon>
              </button>
              <button v-on:click="changeHour('-', 'start')" class="iconWrapMinus">
                <font-awesome-icon class="angle-down" icon="angle-down"></font-awesome-icon>
              </button>
              {{formattedStartHour}}
            </div>
            <div class="colon">
              :
            </div>
            <div class="clockWrap minutes">
              <button v-on:click="changeMinutes('+', 'start')" class="iconWrapPlus">
                <font-awesome-icon class="angle-up" icon="angle-up"></font-awesome-icon>
              </button>
              <button v-on:click="changeMinutes('-', 'start')" class="iconWrapMinus">
                <font-awesome-icon class="angle-down" icon="angle-down"></font-awesome-icon>
              </button>
              {{formattedStartMinutes}}
            </div>
            -
            <div class="clockWrap hours">
              <button v-on:click="changeHour('+', 'end')" class="iconWrapPlus">
                <font-awesome-icon class="angle-up" icon="angle-up"></font-awesome-icon>
              </button>
              <button v-on:click="changeHour('-', 'end')" class="iconWrapMinus">
                <font-awesome-icon class="angle-down" icon="angle-down"></font-awesome-icon>
              </button>
              {{formattedEndHour}}
            </div>
            <div class="colon">
              :
            </div>
            <div class="clockWrap minutes">
              <button v-on:click="changeMinutes('+', 'end')" class="iconWrapPlus">
                <font-awesome-icon class="angle-up" icon="angle-up"></font-awesome-icon>
              </button>
              <button v-on:click="changeMinutes('-', 'end')" class="iconWrapMinus">
                <font-awesome-icon class="angle-down" icon="angle-down"></font-awesome-icon>
              </button>
              {{formattedEndMinutes}}
            </div>
          </div>
        </div> 
</template>

<script setup>
import {computed} from 'vue';
const emit = defineEmits(['change'])
const props = defineProps(['startHour', 'startMinutes', 'endHour', 'endMinutes'])

const formattedStartHour = computed(() => {
    return formatNumber(props?.startHour ?? 0)
})
const formattedStartMinutes = computed(() => {
    return formatNumber(props?.startMinutes ?? 0) 
})
const formattedEndHour = computed(() => {
    return formatNumber(props?.endHour ?? 0) 
})
const formattedEndMinutes = computed(() => {
    return formatNumber(props?.endMinutes ?? 0) 
})

const changeHour = (plusMinus, startEnd) => {
    let start = props.startHour
    let end = props.endHour
    if(plusMinus == '+'){
    if (startEnd == 'start') {
        if (start == 23 ) {
        start = 1
        } else {
        start = start + 1
        }
    } else if (startEnd == 'end') {
        if (end == 23 ) {
        end = 1
        } else {
        end = end + 1
        }
    }
    } else if (plusMinus == '-') {
    if (startEnd == 'start') {
        if (start == 1) {
        start = 23
        } else {
        start = start - 1
        }
    } else if (startEnd == 'end') {
        if (end == 1) {
        end = 23
        } else {
        end = end - 1
        }
    }
    }
    emit('change',{startHour: start, endHour: end, startMinutes: props.startMinutes, endMinutes: props.endMinutes})
}

const changeMinutes = (plusMinus, startEnd) => {
    let start = props.startMinutes
    let end = props.endMinutes
    if(plusMinus == '+'){
    if (startEnd == 'start') {
        if (start == 45) {
        start = 0
        } else {
        start = start + 15
        }
    } else if (startEnd == 'end') {
        if (end == 45) {
        end = 0
        } else {
        end = end + 15
        }
    }
    } else if (plusMinus == '-') {
    if (startEnd == 'start') {
        if (start == 0) {
        start = 45
        } else {
        start = start - 15
        }
    } else if (startEnd == 'end') {
        if (end == 0) {
        end = 45
        } else {
        end = end - 15
        }
    }
    }
    emit('change',{startHour: props.startHour, endHour: props.endHour, startMinutes: start, endMinutes: end})
};

const formatNumber = (number) => {
  return number.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  })
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.timeSelectorWrap button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

button {
  outline: inherit;
  cursor: pointer;
}

p {
  /* font-weight: 600; */
  color: rgba(255, 255, 255, 0.9);
  /* font-size: 22.4px; */
}

.timeSelectorWrap {
  margin-top: 30px;
  margin-bottom: 50px;
}
.timerWrap {
  /* background-color: orange; */
  font-size: 24px;
  font-weight: bold;
  width: 250px;
  height: 50px;
  line-height: 50px;
  margin: 0 auto;
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 4px;
  color: rgba(0,0,0,0.8);
  text-align: center;
}
.clockWrap {
  width: 30px;
  display: inline-block;
  position: relative;
}
.hours {
  text-align: center;
  /* margin-right: -5px; */
}
.minutes {
  text-align: center;
  /* margin-left: -5px; */
}
.timeSelectorWrap .iconWrapPlus {
  cursor: pointer;
  width: 30px;
  position: absolute;
  top: -40px;
  color: rgba(0,0,0,0.4);
  /* left: 6px; */
}
.timeSelectorWrap  .iconWrapMinus {
  cursor: pointer;
  width: 30px;
  position: absolute;
  top: 40px;
  color: rgba(0,0,0,0.4);
  /* left: 6px; */
}
.colon {
  display: inline-block;
}
</style>