<template>
  <div class="main">
    <label for="name" class="label-name">Navn</label>
    <span class="name-text-wrap"><p class="text-name">Ændre navnet på brugeren, dette vil fremgå på brugerens bookinger. </p></span>
    <input v-model="name" id="name" placeholder="Name" type="text" />
    <div v-if="error" class="error">{{ error }}</div>
    <div class="button-container">
      <button
        v-if="!isSaving"
        type="button"
        class="cancel"
        @click.prevent="handleCancel"
      >
        Annuller
      </button>
      <button
        type="button"
        class="confirm"
        @click.prevent="handleConfirm"
        :disabled="isSaving"
      >
        {{ isSaving ? "Gemmer..." : "OK" }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "ChangeName",
  data() {
    return {
      name: "",
      isSaving: false,
      error: null,
    };
  },
  methods: {
    ...mapActions(["updateName"]),
    handleCancel() {
      this.$emit("cancel");
    },
    async handleConfirm() {
      if (this.name.trim().length < 2) {
        this.error = "Navnet skal være mindst to karakter";
        return;
      }
      if (this.name.trim().length > 20) {
        this.error = "Navnet må ikke være mere end 20 karakter";
        return;
      }
      // if (this.name.trim().indexOf(" ") !== -1) {
      //   this.error = "Name should not contain spaces";
      //   return;
      // }

      this.isSaving = true;
      await this.updateName(this.name);
      this.isSaving = false;
      this.$emit("confirm");
    },
  },
  computed: {
    ...mapState(["userDBInfo"]),
  },
  mounted() {
    this.name = this.userDBInfo.name;
  },
};
</script>

<style scoped>
.label-name {
  text-align: center;
  padding-bottom: 20px;
  font-size: 19px;
  color: #000000;
}
.text-name {
  text-align: left;
  max-width: 275px;
  padding-top:18px;
  padding-bottom: 18px;
  margin: 0 auto;
  margin-left:0px;
}
.name-text-wrap {
  /* text-align: center; */
}
.main {
  border-radius: 4px;
  width: 80%;
  height: 100vh;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
input {
  display: block;
  margin: 6px 0;
  max-width: 100%;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  background-color: white;
  font-size: 16px;
  padding: 7px 18px;
  height: 40px;
}
.error {
  font-size: 16px;
  color: #ff5a5f;
}
.confirm {
  margin-left: 4px;
  background-color: #00bae0;
  color: white;
}
.cancel {
  margin-right: 4px;
  color: #838383;
  background-color: #d0cfcf33;
}
.button-container {
  margin-top: 20px;
  display: flex;
}
button {
  padding: 10px 20px;
  cursor: pointer;
  font-weight: 400;
  border-radius: 4px;
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.3);
  width: 100%;
}

input:focus {
  outline: none;
}
label {
  margin-bottom: 0px;
  color: #545454;
  font-weight: 600;
  font-size: 1rem;
}
</style>
