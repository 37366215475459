<template>
  <div class="machineOuterWrap">
    <div class="btnOuterWrap">
      <button v-if="washButtonCheck" v-on:click="() => setSelectedMachine('wash')" class="washingMachine" v-bind:class="{ active: washActive }">
        Vaske
      </button>
      <button v-if="dryerButtonCheck" v-on:click="() => setSelectedMachine('dry')" class="dryingMachine" v-bind:class="{ active: dryAactive }">
        Tørre
      </button>
    </div>
    <div class="subMachine">
      <div class="subMachineText" v-if="washActive && fireSettings.washMachineAmount > 1">
          Maskine:
          <div class="machineNumberBtn" v-for="machine in getMachineButtons">
            <button v-if="selectedMachine === machine.dbName " class="machineNumberSelection" v-on:click="() => setSelectedMachine(machine.dbName)">{{machine.machineNumber}}</button>
            <button v-else v-on:click="() => setSelectedMachine(machine.dbName)">{{machine.machineNumber}}</button>
          </div>
      </div>
      <div class="subMachineText" v-if="dryAactive && fireSettings.dryerMachineAmount > 1">
          Maskine:
          <div class="machineNumberBtn" v-for="machine in getMachineButtons">
            <button v-if="selectedMachine === machine.dbName " class="machineNumberSelection" v-on:click="() => setSelectedMachine(machine.dbName)">{{machine.machineNumber}}</button>
            <button v-else v-on:click="() => setSelectedMachine(machine.dbName)">{{machine.machineNumber}}</button>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'

export default {
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      fireSettings: 'getRoomSettings',
      selectedMachine: 'getSelectedMachine'
    }),
    
    dryerButtonCheck: function() {
      if(this.fireSettings.dryerMachineAmount === 0) {
        return false
      } else  {
        return true
      }
    },
    washButtonCheck: function() {
      if(this.fireSettings.washMachineAmount === 0) {
        return false
      } else  {
        return true
      }
    },
    washActive() {
      return this.selectedMachine ?  this.selectedMachine.search('wash') !== -1 : false;
    },
    dryAactive() {
      return this.selectedMachine ? this.selectedMachine.search('dry') !== -1 : false;
    },
    getMachineButtons: function() {
      const settings = this.fireSettings
      let machineButtons = []
      if (this.washActive) {
        for (var i = 1; i <= settings.washMachineAmount; i++) {
          if (i == 1) {
            machineButtons.push({dbName: 'wash', machineNumber: i.toString()})
          } else {
            machineButtons.push({dbName: 'wash' + i, machineNumber: i.toString()})
          }
        }
      } else if (this.dryAactive) {
        for (var i = 1; i <= settings.dryerMachineAmount; i++) {
          if (i == 1) {
            machineButtons.push({dbName: 'dry', machineNumber: i.toString()})
          } else {
            machineButtons.push({dbName: 'dry' + i, machineNumber: i.toString()})
          }
        }
      }
      return machineButtons
    },
  },
  methods: {
    ...mapMutations({
      setSelectedMachine: 'setSelectedMachine'
    }),
  },
  mounted () {
    if(this.fireSettings.washMachineAmount <= 0 && this.selectedMachine.search('wash') !== -1 && this.fireSettings.dryerMachineAmount > 0) {
        this.setSelectedMachine('dry')
      } 
      if(this.fireSettings.dryerMachineAmount <= 0 && this.selectedMachine.search('dry') !== -1 && this.fireSettings.washMachineAmount > 0) {
        this.setSelectedMachine('wash')
      }
  },
  watch: {
    fireSettings() {
      if(this.fireSettings.washMachineAmount <= 0 && this.selectedMachine.search('wash') !== -1 && this.fireSettings.dryerMachineAmount > 0) {
        this.setSelectedMachine('dry')
      } 
      if(this.fireSettings.dryerMachineAmount <= 0 && this.selectedMachine.search('dry') !== -1 && this.fireSettings.washMachineAmount > 0) {
        this.setSelectedMachine('wash')
      }
    }
  },
}

</script>

<style scoped>
* {
  box-sizing: border-box;
}

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

button:focus {
  outline: none;
}

.btnOuterWrap {
  /* margin-top: 30px; */
  margin-bottom: 10px;
  /* background-color: orange; */
}
.washingMachine, .dryingMachine {
  font-weight: 500;
  position: relative;
  cursor: pointer;
  display: inline-block;
  line-height: 40px;
  /* color: rgba(0, 0, 0, 0.5); */
  /* color: rgba(0, 186, 224, 0.7); */
  color: rgba(255, 255, 255, 1);
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  /* border: 1px solid rgba(0, 186, 224, 0.7); */
  /* background-color: #54bf67; */
  /* background-color: white; */
  /* background-color: rgba(255, 255, 255, 0.25); */
  border: 1px solid rgba(255, 255, 255, 1);
  width: 140px;
  height: 40px;
  border-radius: 3px;
  /* border-radius: 100px; */
}
.dryingMachine {
  margin-left: 20px;
}

.machineOuterWrap {
  padding: 2px 0px 2px 0px;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
  width: 100%;
  /* height: 200px; */
  max-width: 450px;
  margin: 0 auto;
  z-index: 10;
  text-align: center;
  /* box-shadow: 0px 10px 11px -4px rgba(0, 0, 0, 0.16); */
  box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.24);
  /* border-radius: 0px 0px 3px 3px; */
  /* background: linear-gradient(rgba(0, 186, 224, 1), rgba(0, 186, 224, 0.2)) */
  /* border-radius: 0px 0px 3px 3px; */
}

.subMachine {
  margin: 0;
  margin-bottom: 5px;
  text-align: left;
  color: rgba(255, 255, 255, 1);
}

.subMachineText {
  padding: 0px 0px 0px 10px;
}

.machineNumberBtn {
  width: 40px;
  display: inline-block;
  /* margin: 0px 20px; */
}

.machineNumberBtn button {
  width: 100%;
  padding: 5px 0px;
}

.machineNumberSelection {
  background-color: white;
  color: rgba(0, 186, 224, 1);
  /* border: 1px solid rgba(0,0,0,0.3); */
  border-radius: 4px;
}

.machineOuterWrap::after {

}

.active {
  border: 0px;
  /* background-color: #54bf67; */
  /* background-color: #00BAE0; */
  background-color: rgba(255, 255, 255, 1);
  color: rgba(0, 186, 224, 1);
}

img {
  position: absolute;
  height: 35px;
  top: 4px;
  left: 12px;
}


</style>
