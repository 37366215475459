export default [
  {
    label: "Navn, forening eller CVR-nummer",
    id: "name",
    placeholder: "Navn",
    type: "text",
    errorMessage: "Navn mangler",
    formName: "name",
  },
  {
    label: "Gadenavn",
    id: "street-address",
    placeholder: "Gade",
    type: "text",
    errorMessage: "Gade mangler",
    formName: "streetAddress",
  },
  {
    label: "Gadenummer",
    id: "street-no",
    placeholder: "Nummer",
    type: "text",
    errorMessage: "Nummer mangler",
    formName: "streetNo",
  },
  {
    label: "Postnummer",
    id: "zip-code",
    placeholder: "Post",
    type: "number",
    errorMessage: "Postnummer mangler",
    formName: "zipCode",
  },
  {
    label: "By",
    id: "city",
    placeholder: "By",
    type: "text",
    errorMessage: "By mangler",
    formName: "city",
  },
];
