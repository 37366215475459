<template>
  <div id="myModal" class="modal">
  <!-- Modal content -->
  <div class="modal-content">
    <h2>Adgangen er udløbet</h2>

    <div class="info-wrap">
      <p>
        Der er ikke længere adgang til vaskerummet med ID: {{userDBInfo.laundryroom}}
      </p>
      <p>
        Dette kan skyldes, at prøveperioden eller abonnementet er udløbet.
      </p>
      <p>
        Har du spørgsmål, kontakt den ansvarlige administrator af vaskerummet.
      </p>
      <p>Er der brug for support, så kontakt: <b>info@vaskerum.dk</b></p>
    </div>

  </div>
</div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  props: ['somedata','someotherdata'],
  data () {
    return {
    }
  },
  methods: {
  },
  computed: {  
    ...mapGetters({
      userDBInfo: 'getUserDBInfo'
    }),
  }
}


</script>

<style scoped>
* {
  box-sizing: border-box;
}

.info-wrap {
 display: flex;
 flex-direction: column;
 align-items: center;
}

.info-wrap p {
  margin-bottom: 0;
  max-width: 220px;
  text-align: left;
}

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

/* The Modal (background) */
.modal {
  text-align: center;
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 18; /* Sit on top */
  padding-top: 60px; /* Location of the box */
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
  width: 100%; /* Full width */
  height: 100%; 
  max-width: 450px;
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.3); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  position: relative;
  border-radius: 4px;
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  padding-bottom: 40px;
  width: 85%;
  max-width: 400px;
  min-width: 300px;
}

/* Header */
.headerWrap {
  margin: 16px 0px;
}

.resavationText {
  font-size: 1.7rem;
  font-weight: 400;
  color: rgba(0,0,0,0.8);
  margin-bottom: 5px;
}

.dateText {
  font-size: 0.8rem;
  color: rgba(0,0,0,0.6);
}

/* time selection */
.timeSelectorWrap {
  margin-top: 30px;
}
.timerWrap {
  font-size: 1.5rem;
  font-weight: bold;
  width: 250px;
  height: 50px;
  line-height: 50px;
  margin: 0 auto;
  border: 1px solid rgba(0,0,0,0.3);
  border-radius: 4px;
  color: rgba(0,0,0,0.8);
}

.clockWrap {
  width: 30px;
  display: inline-block;
  position: relative;
}
.hours {
  text-align: center;
  margin-right: -5px;
}
.minutes {
  text-align: center;
  margin-left: -5px;
}
.iconWrapPlus {
  cursor: pointer;
  width: 30px;
  position: absolute;
  top: -40px;
  color: rgba(0,0,0,0.4);
  /* left: 6px; */
}

.iconWrapMinus {
  cursor: pointer;
  width: 30px;
  position: absolute;
  top: 45px;
  color: rgba(0,0,0,0.4);
  /* left: 6px; */
}

.colon {
  display: inline-block;
}

/* The Close Button */
.close {
  top: 0px;
  right: 12px;
  position: absolute;
  color: #aaaaaa;
  /* float: right; */
  font-size: 35px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.btnReserver {
  cursor: pointer;
  font-weight: bold;
  font-size: 1.4rem;
  color: white;
  border-radius: 4px;
  height: 40px;
  line-height: 40px;
  /* background-color: #54bf67; */
  background-color: #00BAE0;
  margin: 0 auto;
  width: 250px;
}

.bookingWrap {
  display: inline-block;
  margin: 0 auto;
  margin-top: 10px;
  padding: 10px;
  border-radius: 4px;
}

.bookingWrap h3 {
  margin: 0;
}

@media screen and (min-width: 455px) {
  .modal {
    top: 15px;
    height: calc(100% - 30px);
    border-radius: 4px;
  }
}

</style>
