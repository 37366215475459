<template>
  <div class="container">
    <router-view/>
  </div>
</template>

<script>
// import Navbar from './components/Navbar'


export default {
  // components: {Navigation}
}
</script>

<style>
#app {
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  touch-action: pan-y;
}
</style>