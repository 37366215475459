<template>
<div class="machineNumberWrap">
    <button v-for="(button, index) in machineBtn" v-on:click="machineBtnClick(index)" :key="button"
        v-bind:class="{ machineNumberSelection: button.isActive }" class="machineNumberBtn">
        {{ button.value }}
    </button>
</div>
</template>

<script setup>
import { ref } from "@vue/reactivity"

const emit = defineEmits(['change'])

const props = defineProps(['initialNumber'])

const machineBtn = ref({
    btn1: { isActive: props.initialNumber === 0, value: 0 },
    btn2: { isActive: props.initialNumber === 1, value: 1 },
    btn3: { isActive: props.initialNumber === 2, value: 2 },
    btn4: { isActive: props.initialNumber === 3, value: 3 },
    btn5: { isActive: props.initialNumber === 4, value: 4 },
    btn6: { isActive: props.initialNumber === 5, value: 5 },
});

const machineBtnClick = function(btnName) {
      let buttons = machineBtn.value
      for (var button in buttons) {
        if(button == btnName) {
          buttons[button].isActive = true
          emit('change',buttons[button].value)
        } else {
          buttons[button].isActive = false
        }
      }
      machineBtn.value = buttons
    };
</script>

<style scoped>
button {
    outline: inherit;
    cursor: pointer;
}

.machineNumberWrap {
    /* background-color: orange; */
    background-color: white;
    width: 100%;
}

.machineNumberBtn {
    color: rgba(0, 0, 0, 0.8);
    background-color: white;
    width: 15%;
    padding: 0;
    border: 0;
    outline: none;
}

.machineNumberSelection {
    /* background-color: orange; */
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 4px;
}
</style>