<template>
  <div class="margin-top-medium">
    <label :for="field.id">
      {{ field.label }}
      <span v-if="field.smallText?.length" class="small-tekst">
        {{ field.smallText }}
      </span>
    </label>
    <input
      :value="modelValue"
      @input="updateValue"
      :id="field.id"
      :placeholder="field.placeholder"
      :type="field.type"
    />
    <div v-if="hasError" class="error">{{ field.errorMessage }}</div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: String,
    field: Object,
    hasError: Boolean,
  },
  methods: {
    updateValue(event) {
      this.$emit("update:modelValue", event.target.value);
    },
  },
};
</script>

<style scoped>
label {
  margin-bottom: 0px;
  color: #6a6a6a;
  font-weight: 600;
  font-size: 1rem;
}

.small-tekst {
  font-weight: 300;
}

input {
  display: block;
  margin: 6px 0;
  width: 88%;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  background-color: white;
  font-size: 16px;
  padding: 0px 10px;
  height: 40px;
}

input:focus {
  outline: none;
}

.error {
  font-size: 16px;
  color: #ff5a5f;
}

.margin-top-medium {
  margin-top: 8px;
}
</style>
