<template>
  <div v-if="!isReady" class="loading-main-view">
    <div class="loading-box">Loading...</div>
  </div>
  <div v-if="isReady" id="appFrame" class="main-logged-in-view.vue">
    <div class="main-view-ready">
      <Navigation />
      <router-view/>
    </div>
  </div>
</template>

<script>
import Navigation from '../components/Navigation'
import { useStore } from 'vuex'
import { computed, ref, watch, onMounted, onUpdated, onActivated, onBeforeUpdate } from 'vue'
import { useRouter } from 'vue-router'

export default {
  components: {Navigation},
  setup() {
    const store = useStore()
    const router = useRouter()
    const isReady = ref(false)

    const redirectTocorrectView = () => {
      if(store.state.userIsInARoom && isReady.value) {
        router.push('booking')
      } 
      else if(store.state.redirectToNotInARoom) {
        isReady.value = true
        router.push('notinaroom')
      }
    }
    watch(
      ()=>store.state.appIsReady, () => {
        // console.log(`Watcher says: AppIsReady ${store.state.appIsReady}`)
        if(store.state.appIsReady.user && store.state.appIsReady.bookings && store.state.appIsReady.settings && store.state.appIsReady.subscription)
          isReady.value = true

        redirectTocorrectView()
      },
      {deep: true},
      ()=>store.state.redirectToNotInARoom, () => { 
        redirectTocorrectView()
      },
    )
    onMounted(()=> {
      if(store.state.appIsReady.user && store.state.appIsReady.bookings && store.state.appIsReady.settings && store.state.appIsReady.subscription)
        isReady.value = true

      redirectTocorrectView()
    })
    return {
      appIsReady: computed(() => store.state.appIsReady), isReady
    }
  }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}
body {
  height: 100%;
}
.loading-main-view {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.loading-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#appFrame {
  margin: 0 auto;
}
.navSticky {
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 200;
}

.waitingIcon {
  border-radius: 4px;
  width: 100px;
  height: 100px;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  margin: auto;
}

@media screen and (min-width: 455px) {
  #appFrame {
    position: absolute;
    bottom: 15px;
    top: 15px;
    left: 0px;
    right: 0px;
    overflow: auto;
    box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    max-width: 450px;
  }
}



</style>