<template>
    <main class="main">
        <!-- <div class="center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z" clip-rule="evenodd" />
            </svg>
        </div> -->
        <h1 class="margin-top-large text-center margin-bottom-large">Opsig abonnementet</h1>
        <p class="margin-top-medium">
            Du er ved at opsige abonnementet på vaskerummet. Vaskerum.dk vil stadig være tilgængeligt i den betalte periode, men vil ikke blive fornyet.
        </p>
        <p class="margin-top-large margin-bottom-large">
            Vil du opsige abonnementet?
        </p>
        <div class="button-container">
            <secondary-button :disabled="isLoading" @click="handleNo">Nej</secondary-button>
            <primary-button  :disabled="isLoading" @click="handleYes" class="margin-left">{{isLoading ? 'Cancelling...' : 'Ja'}}</primary-button>
        </div>
    </main>
</template>

<script setup>
    import PrimaryButton from './PrimaryButton.vue';
    import SecondaryButton from './SecondaryButton.vue';
    import { ref } from 'vue';
    import { useStore } from 'vuex';
    import { useToast } from 'vue-toastification';

    const emit = defineEmits(['close-cancel-subscription'])

    const store = useStore();
    const toast = useToast()

    const isLoading = ref(false);

    const handleNo = () => {
        emit('close-cancel-subscription')
    }

    const handleYes = async () => {
        const sucess = await store.dispatch('cancelSubscription');
        if (sucess) {
            toast.success('Abonnementet er opsagt')
            emit('close-cancel-subscription')
        } else {
            toast.error('Noget gik galt. Prøv igen senere eller kontakt supporten')
        }
    }
</script>

<style scoped>
.main {
    border-radius: 4px;
    padding-top: 40px; 
    padding-left: 10%;
    padding-right: 10%;
    height: calc(100vh - 40px);
    display: flex;
    flex-direction: column;
    justify-content: center;
}
h1, p {
    margin: 0px;
}
h1 {
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
}
.margin-top-medium {
    margin-top: 8px;
}
.margin-top-large {
    margin-top: 16px;
}
.margin-bottom-large {
    margin-bottom: 16px;
}
.center{
    display: flex;
    justify-content: center;
    align-items: center;
}
.w-6 {
    width: 1.8rem;
}
.h-6 {
    height: 1.8rem;
}
.text-center {
    text-align: center;
}
.margin-left {
    margin-left: 4px;
}

.button-container {
    margin-top: 16px;
    display: flex;
    margin-bottom: 16px;
}
</style>