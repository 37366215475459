import { createRouter, createWebHistory } from "vue-router";
import MainLoggedInView from "../views/MainLoggedInView.vue";
import Login from "../views/Login.vue";
import RegistreChoice from "../views/registreChoice.vue";
import Register from "../views/register.vue";
import RegisterRoom from "../views/registerRoom.vue";
import ResetPassword from "../views/resetPassword.vue";
import LandingBooking from "../views/landingBooking.vue";
import NotInARoom from "../views/notInARoom.vue";
import UserInfo from "../views/UserInfo.vue";
import RoomInfo from "../views/RoomInfo.vue";
import DeleteAccount from "../views/DeleteAccount.vue";
import { auth } from "../firebase/config";
import { onAuthStateChanged } from "firebase/auth";

const routes = [
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: MainLoggedInView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/",
    name: "MainLoggedInView",
    component: MainLoggedInView,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/booking",
        name: "LandingBooking",
        component: LandingBooking,
      },
      {
        path: "/notinaroom",
        name: "NotInARoom",
        component: NotInARoom,
      },
      {
        path: "/userinfo",
        name: "UserInfo",
        component: UserInfo,
      },
      {
        path: "/roominfo",
        name: "RoomInfo",
        component: RoomInfo,
      },
      {
        path: "/deleteaccount",
        name: "DeleteAccount",
        component: DeleteAccount,
      }
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/choose",
    name: "RegistreChoice",
    component: RegistreChoice,
  },
  {
    path: "/register",
    name: "CreateUser",
    component: Register,
  },
  {
    path: "/registerroom",
    name: "RegisterRoom",
    component: RegisterRoom,
  },
  {
    path: "/resetpassword",
    name: "ResetPassword",
    component: ResetPassword,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const unsub = onAuthStateChanged(auth, (user) => {
    let requiresAuth = to.meta.requiresAuth;
    if (requiresAuth && !user) next("login");
    else if ((user && to.name == "Login") || (user && to.name == "CreateUser"))
      next("/");
    else next();
    unsub();
  });
});

export default router;
