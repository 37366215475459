<template>
  <div class="backgroundClass">
    <div class="box-wrap">
      <div class="loginBox">
        <transition name="error-box">
          <div @click="messageBox = null" class="error-message" v-if="messageBox">
            <font-awesome-icon class="exclamation-icon" icon="exclamation-circle" size="lg"/>
            {{ messageBox }}
            <font-awesome-icon class="close-icon" icon="close" size="lg"/>
            </div>
        </transition>
        <!-- <img class="logo-wrap" src="/static/LogoTest.png" alt="Mountain View"> -->
        <div class="headerText">Nulstil din adgangskode</div>
        <div class="descriptionText">
          Skriv din email, så sender vi et link som du kan bruge til at ændre din adgangskode.
        </div>
        <input v-model="email" placeholder="E-mail" type="email" :disabled="inputDisabled" >
      </div>
      <div class="buttons-wrap">
        <button type="button" v-on:click="resetPassword" :disabled="disabledActive">Send link</button>
        <router-link class="loginLink" to="/login">Cancel</router-link>
      </div>
    </div>
  </div>
</template>

<script>

import {sendPasswordResetEmail } from "firebase/auth";
import { ref, onMounted } from 'vue'
import { auth } from '../firebase/config'
import { useRoute } from 'vue-router';

export default {
  setup() {
    const email = ref('')
    const disabledActive = ref(false)
    const messageBox = ref('')
    const inputDisabled = ref(false)
    const route = useRoute()

    const errorMessageObj = [
      {EN: 'auth/user-not-found', DK: 'Der findes ingen bruger med den email'},
      {EN: 'auth/invalid-email', DK: 'Ugyldig email'},
    ]

    const resetPassword = async () => {
      disabledActive.value = true
      messageBox.value = ''
      try {
        await sendPasswordResetEmail(auth, email.value)
        messageBox.value = 'Der er blevet sendt et link til din email'      
      } catch (error) {
        if (error.code == 'auth/invalid-email') messageBox.value = 'Ugyldig email'
        else if (error.code == 'auth/user-not-found') messageBox.value = 'Der findes ingen bruger med den email'
        else if (error.code == 'auth/missing-email') messageBox.value = 'Indtast en email'
        else messageBox.value = 'Fejl'
      }
      disabledActive.value = false
    }

    onMounted(() => {
      if(route.query.email) {
        email.value = route.query.email
        inputDisabled.value = true
      }
    })

    return {
      email, resetPassword, disabledActive,messageBox, inputDisabled
    }
  }

}
</script>
<style scoped>
* {
  box-sizing: border-box;
}

.close-icon {
  margin-left:10px;
  color: rgb(0 0 0 / 30%);
}

.exclamation-icon {
  margin-right: 10px;
}

.error-message {
  cursor: pointer;
  text-align: left;
  position: absolute;
  top: -75px;
  color: #000000;
  font-weight: 500;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-color: black;
  padding: 11px;
  border-radius: 4px;
  box-shadow: 5px 5px 5px 0px rgb(0 0 0 / 30%); 
}
.error-box-enter-active {
  animation: wobble 0.5s ease;
}
.error-box-leave-from {
  opacity: 1;
}
.error-box-leave-to {
  opacity: 0;
}
.error-box-leave-active {
  transition: all .5s ease;
}

@keyframes wobble {
  0% {transform: translateY(-300px); opacity: 0}
  50% {transform: translateY(0); opacity: 1}
  60% {transform: translateX(8px)}
  70% {transform: translateX(-8px)}
  80% {transform: translateX(4px)}
  90% {transform: translateX(-4px)}
  100% {transform: translateX(0)}
}


/* .fade-enter-active, .fade-leave-active {
  transition: opacity .4s;
}
.fade-enter, .fade-leave-to  {
  opacity: 0;
} */

h2 {
  margin-top: 0px;
  margin-bottom: 10px;
}

input {
  margin: 20px 0 0 0;
  width: 100%;
  background-color: white;
  border: 0;
  border-bottom: 1.3px solid #e8e7e3;
  border-radius: 0px;
  font-size: 16px;
  padding: 7px 10px;
  height: 30px;
}

input:focus {
  outline: none;
}


.backgroundClass {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* width: 100%; */
  flex-direction: column;
  background: linear-gradient(rgba(0, 186, 224, 1), rgba(0, 186, 224, 0.5));
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
}

.loginBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 300px;
  margin: auto;
  
  background-color: white;
  padding: 25px 20px;
  border-radius: 4px;
  box-shadow: 3px 3px 5px 0px rgb(0 0 0 / 30%);
}

.loginLink {
  position: absolute;
  bottom: -50px;
  right: 5px;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.9rem;
}

button {
  width: 130px;
  margin-top: 40px;
  padding: 10px 20px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
  border: 0;
  font-weight: 400;
  background-color: white;
  border-radius: 4px;
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.3);
}

.box-wrap {
  position: relative;
}

.logo-wrap {
  display: block;
  width: 100%;
  margin-bottom: 30px;
}

.contentText {
  text-align: left;
}

.headerText {
  /* text-align: left; */
  font-size: 24px;
  font-weight: 400;
  color: rgba(0,0,0,0.8);
  margin-bottom: 15px;
}

.descriptionText {
  padding: 0 10px;
  text-align: center;
  font-size: 14.4px;
  color: rgba(0,0,0,0.8);
}
</style>
