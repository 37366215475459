<template>
  <div class="calendarOuterWrap">
    <div class="calendarInnerWrap">
      <div v-on:click="backward" class="leftArrowWrap">
        <div class="iconWrap">
          <!-- <icon name="angle-left" scale="2.2"></icon> -->
          <font-awesome-icon class="angle-left" icon="angle-left" size="2x"/>
        </div>
      </div>
      <div class="weekWrap">
        <div class="weekName" v-for="(day, index) in dateArray" :key="index">
            {{day.short}}
        </div>
        <div class="weekNumber" v-for="(day, index) in dateArray" :key="index">
            <!-- <div v-on:click="getSelection(day, index)" v-if="day.active" class="activeSelection" > -->

              <button v-on:click="getSelection(day, index)" v-if="day.active" class="activeSelection">{{day.date}}</button>
            <!-- </div> -->

            <button v-on:click="getSelection(day, index)" v-else class="noSelection">
              {{day.date}}
            </button>
        </div>
      </div>
      <div v-on:click="forward" class="rightArrowWrap">
        <div class="iconWrap">
          <!-- <icon name="angle-right" scale="2.2"></icon> -->
          <font-awesome-icon class="angle-right" icon="angle-right" size="2x"/>
        </div>
      </div>
    </div>
    <div class="selectionDayInfo">
      <div class="selectionWeek" >
        Uge {{getSelectedDay.weeknr}}
      </div>
      <div class="selectionDate" >
        {{getSelectedDay.weekdayname}} {{getSelectedDay.weekday}} {{getSelectedDay.date}} {{getSelectedDay.month}} {{getSelectedDay.year}}
      </div>
    </div>
  </div>
</template>

<script>
// import bus from '@/bus.js'
export default {
  data () {
    return {
      dateArray: [
        {short: 'none', year: 'none', month: 'none', date: 'none', week: 'none', monthNumber: 'none', active: true},
        {short: 'none', year: 'none', month: 'none', date: 'none', week: 'none', monthNumber: 'none', active: false},
        {short: 'none', year: 'none', month: 'none', date: 'none', week: 'none', monthNumber: 'none', active: false},
        {short: 'none', year: 'none', month: 'none', date: 'none', week: 'none', monthNumber: 'none', active: false},
        {short: 'none', year: 'none', month: 'none', date: 'none', week: 'none', monthNumber: 'none', active: false},
        {short: 'none', year: 'none', month: 'none', date: 'none', week: 'none', monthNumber: 'none', active: false},
        {short: 'none', year: 'none', month: 'none', date: 'none', week: 'none', monthNumber: 'none', active: false},
      ],
      selectedDay: {year: 'none', month: 'none', date: 'none', weeknr: 'none', weekdayname: 'none', monthNumber: 'none'},
      dayName: ['Søn','Man','Tir','Ons','Tor','Fre','Lør'],
      dayNameLong: ['Søndag','Mandag','Tirsdag','Onsdag','Torsdag','Fredag','Lørdag'],
      monthName: ['januar','februar','marts','april','maj','juni','juli','august','september','oktober','november','december'],
      dayCounter: 7,
    }
  },
  computed: {
    getSelectedDay: function () {
      let temp = this.selectedDay
      let tempFullYear = new Date()
      tempFullYear.setFullYear(temp.year, temp.month, temp.date);
      temp.monthNumber = temp.month
      temp.month = this.monthName[temp.month]
      temp.weeknr = this.getWeekNumber(tempFullYear)
      temp.weekdayname = this.dayNameLong[tempFullYear.getDay()]

      // bus.$emit('testDate', temp)
      this.$store.commit('setTestDate', temp)

      return temp
    }
  },
  methods: {
    changeSeven () {
      var i = this.dayCounter - 7
      var k = this.dayCounter
      var calCounter = 0
      for (i; i < k; i++){
        var d = new Date();
        d.setDate(d.getDate()+i)
        this.dateArray[calCounter].date = d.getDate() //(January 14, 2020) = d.setFullYear(2020, 0, 14);
        this.dateArray[calCounter].short = this.dayName[d.getDay()]
        this.dateArray[calCounter].year = d.getFullYear()
        this.dateArray[calCounter].month = d.getMonth()
        // this.dateArray
        calCounter++
      }
      // console.log(this.dateArray);
    },
    forward () {
      this.dayCounter = this.dayCounter + 7
      this.changeSeven()
      this.getSelection(this.dateArray[0], 0)
    },
    backward () {
      this.dayCounter = this.dayCounter - 7
      this.changeSeven()
      this.getSelection(this.dateArray[0], 0)
    },
    getSelection (day, index) {
      this.selectedDay.year = day.year
      this.selectedDay.month = day.month
      this.selectedDay.date = day.date
      // bus.$emit('date', day)
      this.$store.commit('setDate', day)
      for(var i = 0; i < 7; i++) {
        this.dateArray[i].active = false
      }
      this.dateArray[index].active = true
      // alert('get selection: ' + day.month )
    },
    getWeekNumber(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
    // Return array of year and week number
    // return [d.getUTCFullYear(), weekNo];
    return weekNo;
}
  },
  mounted() {
    this.changeSeven()
    this.getSelection(this.dateArray[0], 0)
    // bus.$emit('date', this.selectedDay)
    // console.log('-------> ' + this.dateArray[0].date);
    // this.selectedDay.year = this.dateArray[0].year
    // this.selectedDay.month = this.dateArray[0].month
    // this.selectedDay.date = this.dateArray[0].date
  }// end of mounted
}

</script>

<style scoped>
* {
  box-sizing: border-box;
}

button {
  background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}
.leftArrowWrap, .weekWrap, .weekDay, .rightArrowWrap, .calendarInnerWrap, .calendarOuterWrap {
  /* border: none !important; */
  /* white-space: nowrap; */
}
.leftArrowWrap, .rightArrowWrap {
  cursor: pointer;
  height: 75px;
  vertical-align: top;
  width: 8%;
  color: rgba(0, 0, 0, 0.3);
}

.leftArrowWrap  {
  display: inline-block;
  /* background-color: red; */
  /* width: 10%; */
}

.rightArrowWrap {
  display: inline-block;
  /* background-color: red; */
  /* width: 10%; */
}
.iconWrap {
  margin-top: 28px;
  color: white;
  /* font-weight: bold; */
}

.weekWrap {
  display: inline-block;
  /* background-color: yellow; */
  /* margin-top: 15px; */
  max-width: 340px;
  width: 80%;
}

.weekName {
  font-weight: 600;
  /* color: rgba(0, 0, 0, 0.45); */
  color: white;
}

.weekNumber {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.45);
}

.weekName, .weekNumber {
  /* margin-top: 15px; */
  display: inline-block;
  width: 14%;
  line-height: 43px;
  max-width: 43px;
  height: 43px;
}

.weekName {
  line-height: 33px;
  height: 33px;
}

.calendarInnerWrap {
  /* margin-top: 15px; */
  /* background-color: orange; */
  display: block;
  width: 100%;
}

.selectionDayInfo {
  color: rgba(0, 0, 0, 0.3);
  font-weight: 500;
  font-size: 0.8rem;
  padding-top: 5px;
  position: relative;
  /* background-color: grey; */
  height: 30px;
  width: 60%;
  margin: 0 auto;
  color: white;
}

.selectionWeek {
  display: inline-block;
  float: left;
}

.selectionDate {
  display: inline-block;
  float: right;
}

.calendarOuterWrap {
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
  /* border-bottom: 1px solid #05D8F0; */
  /* border-bottom: 1.1px solid rgba(5, 216, 240, 0.3); */
  max-width: 450px;
  margin: 0 auto;
  text-align: center;
  /* background-color: #00BAE0; */
  /* background: linear-gradient(#00BAE0, white) */
}

.activeSelection {

  background-color: white;
  color: #00BAE0;
  border-radius: 100px;
  width: 43px;
  height: 100%;
  line-height: 43px;
}
.noSelection {
  /* background-color: white; */
  /* color: black; */
  /* border-radius: 100px; */
  width: 43px;
  height: 100%;
  line-height: 43px;
  cursor: pointer;
  color: white;
}

</style>
