<template>
  <div class="main">
    <h1>Tilladte aktive bookinger</h1>
    <p>
      'Tilladte aktive bookinger' angiver det samlede antal aktive bookinger, 
      en bruger kan have. Dette gælder på tværs af alle maskiner, både vaskemaskiner og 
      tørremaskiner. Denne grænse tager kun højde for antallet af bookinger og ikke for 
      varigheden af hver enkelt booking.
    </p>
    <p>
      Sættes feltet til nul, eller efterlades det tomt, 
      deaktiveres 'Tilladte aktive bookinger', og derved fjernes begrænsningen for brugerne.
    </p>



    <div class="input-container">
      <h2>Tilladte aktive bookinger</h2>
      <div>
        <input
          v-model="currentMaxActiveBookings"
          @keypress="isNumber($event)"
          maxlength="3"
        />
        <span>Booking(er)</span>
      </div>
    </div>

    <div class="action-buttons">
      <secondary-button :disabled="isLoading" @click="emit('close')">
        Annuller
      </secondary-button>
      <primary-button
        :disabled="isLoading"
        @click="handleChangeMaxActiveBookings"
      >
        Gem
      </primary-button>
    </div>
  </div>
</template>

<script setup>
import PrimaryButton from "./PrimaryButton.vue";
import SecondaryButton from "./SecondaryButton.vue";
import { ref } from "vue";
import { useToast } from "vue-toastification";
import { getDatabase, ref as firebaseRef, update } from "firebase/database";
import { useStore } from "vuex";

const emit = defineEmits(["close"]);

const store = useStore();
const toast = useToast();
const isLoading = ref(false);
const currentMaxActiveBookings = ref(
  store.state.roomSettings.rules?.maxActiveBookings || null
);

const isNumber = (event) => {
  var charCode = event.keyCode || event.which;
  if (charCode < 48 || charCode > 57) event.preventDefault();
};

const handleChangeMaxActiveBookings = async () => {
  const maxActiveBookings = parseInt(currentMaxActiveBookings.value) || null;

  try {
    isLoading.value = true;

    const db = getDatabase();
    const laundryRoomId = store.state.userDBInfo.laundryroom;

    await update(
      firebaseRef(db, `laundryrooms/${laundryRoomId}/settings/rules/`),
      { maxActiveBookings }
    );

    toast.success("Tilladte aktive bookinger er blevet opdateret");

    store.commit("setRules", {
      ...store.state.roomSettings.rules,
      maxActiveBookings,
    });

    emit("close");
  } catch (error) {
    toast.error("Fejl: Kan ikke gemme 'Tilladte aktive bookinger'.");
  } finally {
    isLoading.value = false;
  }
};
</script>

<style scoped>
.main {
  padding: 30px 10%;
  margin: auto;
}

h1 {
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0px;
}

h2 {
  margin-top: 20px;
  color: #545454;
  font-weight: 600;
  font-size: 1rem;
}

.input-container {
  margin: 60px 0;
}

.input-container > div {
  display: flex;
  gap: 8px;
  align-items: center;
}

.input-container input {
  width: 60px;
  padding: 4px 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
}

p {
  margin-top: 24px;
  margin-bottom: 6px;
  font-size: 1rem;
  color: #545454;
}

.action-buttons {
  margin-top: 32px;
  display: flex;
  gap: 20px;
}
</style>
