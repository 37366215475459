<template>
  <div class="backgroundClass">
    <div class="loginBox">
      <transition name="error-box">
        <div @click="error = null" class="error-message" v-if="error">
          <font-awesome-icon class="exclamation-icon" icon="exclamation-circle" size="lg"/>
          {{ error }}
          <font-awesome-icon class="close-icon" icon="close" size="lg"/>
          </div>
      </transition>
      <img class="logo-wrap" src="../assets/LogoWhite.png" alt="Vaskerum Logo">
      <input v-model="name" placeholder="Fornavn" type="text" >
      <input v-model="email" placeholder="E-mail" type="email" >
      <input v-model="password" placeholder="Password" type="password">
      <input v-model="rePassword" placeholder="Retype Password" type="password">
      <input v-model="laundryRoom" placeholder="Vaskerum ID" type="text" />
      <button @click="createUser()" :disabled="signupnBtnDisabled">Opret bruger</button>
      <router-link class="loginLink" to="/login">Cancel</router-link>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { getDatabase, ref as FbRef, child, get, set } from "firebase/database"
import { createUserWithEmailAndPassword, deleteUser } from 'firebase/auth'
import { auth } from '../firebase/config'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  setup() {
    const name = ref('')
    const email = ref('')
    const password = ref('')
    const rePassword = ref('')
    const laundryRoom = ref('')
    const error = ref('')
    const signupnBtnDisabled = ref(false)
    const errorMessageObj = [
      {EN: 'auth/weak-password', DK: 'Koden skal være mindst 6 tegn'},
      {EN: 'auth/email-already-in-use', DK: 'E-mailen er allerede i brug'},
      {EN: 'auth/invalid-email', DK: 'Ugyldig email'},
    ]

    const store = useStore()
    const router = useRouter()

    const createUser = async () => {
      if (name.value.length < 2) { error.value = 'Indtast fornavn'; return null}
      if (password.value.length <1) { error.value = 'Indtast kodeord'; return null}
      if (password.value != rePassword.value) { error.value = 'Koderne er ikke ens'; return null}
      if (laundryRoom.value.length <= 1) { error.value = 'Ugyldig vaskerum-ID'; return null}
      try {
        signupnBtnDisabled.value = true
        const fireDbRef = FbRef(getDatabase())
        const laundryRoomExist = await get(child(fireDbRef, `list/${laundryRoom.value}`))
        if(laundryRoomExist.exists() && laundryRoomExist.val()){
          try {
            const res = await createUserWithEmailAndPassword(auth, email.value, password.value)
            if (res) {
              const db = getDatabase()
              try {
                await set(FbRef(db, 'users/' + res.user.uid), {
                  name: name.value,
                  laundryroom: laundryRoom.value
                })
              } catch (err) {
                //Trying again
                try {
                  await set(FbRef(db, 'users/' + res.user.uid), {
                    name: name.value,
                    laundryroom: laundryRoom.value
                  })
                } catch (error) {
                  throw 'ErrorUserDBWriting'
                }
              }
                await store.dispatch('setAllInitialInfo')
                router.push('/')
            } else {
              error.value = 'Fejl'
            }
          } catch (err) {
            for (const element of errorMessageObj) {
              if(element.EN == err.code) error.value = element.DK
            }
            if(err == 'ErrorUserDBWriting') {
              const user = auth.currentUser
              await deleteUser(user)
              error.value = 'Fejl, prøv igen'
            } 
            //If ErrorUserDBWriting happens, then delete user and tell user to try again.
            if (!error.value) error.value = 'Fejl'
          }
        } else {
          error.value = 'Forkert vaskerum-ID'
        }
      } catch (err) {
        console.error('Error registering user: ', err)
        error.value = 'Fejl'
      }
      signupnBtnDisabled.value = false
    }

    return {
      name, email, password, rePassword, laundryRoom, error, 
      errorMessageObj, createUser, signupnBtnDisabled
    }

  }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.close-icon {
  margin-left:10px;
  color: rgb(0 0 0 / 30%);
}

.exclamation-icon {
  margin-right: 10px;
}

.error-message {
  cursor: pointer;
  text-align: left;
  position: absolute;
  top: -63px;
  color: #000000;
  font-weight: 500;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-color: black;
  padding: 11px;
  border-radius: 4px;
  box-shadow: 5px 5px 5px 0px rgb(0 0 0 / 30%); 
}
.error-box-enter-active {
  animation: wobble 0.5s ease;
}
.error-box-leave-from {
  opacity: 1;
}
.error-box-leave-to {
  opacity: 0;
}
.error-box-leave-active {
  transition: all .1s ease;
}

@keyframes wobble {
  0% {transform: translateY(-300px); opacity: 0}
  50% {transform: translateY(0); opacity: 1}
  60% {transform: translateX(8px)}
  70% {transform: translateX(-8px)}
  80% {transform: translateX(4px)}
  90% {transform: translateX(-4px)}
  100% {transform: translateX(0)}
}

.backgroundClass {
  position: absolute;
  height: 100vh;
  left: 0;
  right: 0;
  background-color: #00BAE0;
  background: linear-gradient(rgba(0, 186, 224, 1), rgba(0, 186, 224, 0.5));
}

.loginBox {
  border-radius: 4px;
  width: 300px;
  height: 450px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.loginLink {
  position: absolute;
  bottom: 5px;
  right: 5px;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.9rem;
}

.errorMsg {
  color: rgba(223,77,80,0.9);
  text-align: left;
}

button {
  margin-top: 20px;
  padding: 10px 20px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
  border: 0;
  font-weight: 400;
  background: white;
  border-radius: 4px;
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.3);
}

input {
  margin: 6px 0;
  width: 100%;
  background-color: white;
  border: 0px;
  border-radius: 4px;
  /* color: #66615b; */
  font-size: 16px;
  padding: 7px 18px;
  height: 40px;
}
input:focus {
  outline: none;
}

.logo-wrap {
  display: block;
  width: 100%;
  margin-bottom: 30px;
}
</style>
