<template>
  <p class="selected-time">
    <span v-if="activeTimeslot">
      {{ activeTimeslot.startHour }}:{{ activeTimeslot.startMinutes }}
      -
      {{ activeTimeslot.endHour }}:{{ activeTimeslot.endMinutes }}
    </span>
  </p>

  <div v-if="this.filteredTimeslots.length === 0" class="timeslot-wrap">
    Der er ikke flere ledige tider i dag.
  </div>
  <div v-else class="timeslot-wrap">
    <font-awesome-icon
      icon="angle-up"
      class="icon"
      :class="{ 'disabled-icon': this.startingIndex === 0 }"
      @click="this.startingIndex--"
    />
    <ul class="list">
      <li
        v-for="timeslot in predefinedTimeslots"
        @click="this.updateTime(timeslot)"
        :class="{ active: this.isActiveTimeslot(timeslot) }"
      >
        {{ timeslot.startHour }}:{{ timeslot.startMinutes }}
        -
        {{ timeslot.endHour }}:{{ timeslot.endMinutes }}
      </li>
    </ul>
    <font-awesome-icon
      icon="angle-down"
      class="icon"
      :class="{
        'disabled-icon':
          this.startingIndex + 4 >= this.filteredTimeslots.length,
      }"
      @click="this.startingIndex++"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: [
    "bookingTime",
    "existingBookings",
    "machineSelection",
    "toggleBookButton",
  ],
  data() {
    return { activeTimeslot: null, startingIndex: 0 };
  },
  mounted: function () {
    this.toggleBookButton(true);
  },
  unmounted: function () {
    this.toggleBookButton(false);
  },
  methods: {
    updateTime(timeslot) {
      this.bookingTime.startHour = timeslot.startHour;
      this.bookingTime.startMinutes = timeslot.startMinutes;
      this.bookingTime.endHour = timeslot.endHour;
      this.bookingTime.endMinutes = timeslot.endMinutes;

      this.activeTimeslot = timeslot;

      this.toggleBookButton(false);
    },
    isActiveTimeslot(timeslot) {
      for (const prop in timeslot) {
        if (timeslot[prop] !== this.bookingTime[prop]) return false;
      }
      return true;
    },
    getTimeForInterval(startHour, startMinutes, endHour, endMinutes) {
      const start = new Date(this.selectedDate);
      const end = new Date(this.selectedDate);

      start.setHours(startHour, startMinutes, 0, 0);
      end.setHours(endHour, endMinutes, 0, 0);

      return {
        start: start.getTime(),
        end: end.getTime(),
      };
    },
  },
  computed: {
    ...mapGetters({
      rules: "getRules",
      selectedDate: "getDate",
    }),
    filteredTimeslots: function () {
      return Object.values(this.rules.predefinedTimeslots).filter(
        (timeslot) => {
          const timeslotTime = this.getTimeForInterval(
            timeslot.startHour,
            timeslot.startMinutes,
            timeslot.endHour,
            timeslot.endMinutes
          );

          for (const booking in this.existingBookings) {
            const start1 = this.existingBookings[booking].start;
            const end1 = this.existingBookings[booking].end;
            const start2 = timeslotTime.start;
            const end2 = timeslotTime.end;

            if (
              start1 < end2 &&
              end1 > start2 &&
              this.machineSelection === this.existingBookings[booking].machine
            ) {
              return false;
            }
          }

          return true;
        }
      );
    },
    predefinedTimeslots: function () {
      return this.filteredTimeslots
        .sort((first, second) => {
          if (first.startHour === second.startHour)
            return first.startMinutes - second.startMinutes;
          return first.startHour - second.startHour;
        })
        .slice(this.startingIndex, this.startingIndex + 4)
        .map(({ startHour, startMinutes, endHour, endMinutes }) => ({
          startHour: startHour.toString().padStart(2, "0"),
          startMinutes: startMinutes.toString().padStart(2, "0"),
          endHour: endHour.toString().padStart(2, "0"),
          endMinutes: endMinutes.toString().padStart(2, "0"),
        }));
    },
  },
};
</script>

<style scoped>
.selected-time {
  font-weight: bold;
  height: 20px;
}

.timeslot-wrap {
  margin-top: 20px;
  margin-bottom: 40px;
}

.list {
  list-style: none;
  padding: 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 0;
}

.list li {
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
}

.icon {
  cursor: pointer;
  height: 1.75em;
  padding: 15px;
}

.disabled-icon {
  cursor: default;
  pointer-events: none;
  opacity: 0.3;
}

.active {
  background-color: rgba(221, 221, 221, 0.75);
  outline: 1px solid rgba(161, 161, 161, 0.75);
}
</style>
