<template>
  <main class="main">
    <h1>Abonnement</h1>

    <template v-for="field in baseFields">
      <FormInput
        v-model="data.form[field.formName]"
        :field="field"
        :hasError="data.errors[field.formName]"
      />
    </template>

    <template v-if="!data.form.addressesAreTheSame">
      <h2>Fakturaadress</h2>
      <template v-for="field in invoiceFields">
        <FormInput
          v-model="data.form[field.formName]"
          :field="field"
          :hasError="data.errors[field.formName]"
        />
      </template>
    </template>

    <FormInput
      v-model="data.form.invoiceEmail"
      :field="{
        label: 'E-mail',
        id: 'email',
        placeholder: 'Email',
        type: 'email',
        errorMessage: 'Email mangler',
        smallText: '(til modtagelse af faktura)',
      }"
      :hasError="data.errors.invoiceEmail"
    />

    <CheckboxInput
      v-model="data.form.addressesAreTheSame"
      id="addresses-are-the-same"
    >
      Leveringsadressen og fakturaadressen er den samme
    </CheckboxInput>

    <div v-if="!roomSettings?.subscription" class="margin-top-medium">
      <p>
        <u>Abonnement:</u> 12 måneder<br />12 måneders adgang til vaskerummet
        med ID: <i>{{ userDBInfo.laundryroom }}</i
        >. Adgangen gælder for alle brugere tilknyttet rummet, dog højst 40
        brugere. Abonnementet vil automatisk blive fornyet efter et år.<br /><br />
        <u>Betalingsmetode:</u> Faktura.<br />
        Fakturaen vil blive sendt til den angivne e-mailadresse inden for to
        arbejdsdage. <br /><br /><b>Total:</b> 720 kr.
      </p>
    </div>

    <CheckboxInput
      v-if="!roomSettings?.subscription"
      v-model="data.termsAndConditions"
      id="terms-and-conditions"
      :hasError="data.errors.termsAndConditions"
      errorMessage="Accept af betingelser mangler"
    >
      Jeg accepterer
      <a href="https://vaskerum.dk/conditions.html" target="_blank">
        betingelserne
      </a>
    </CheckboxInput>

    <div v-if="roomSettings?.subscription" class="margin-top-medium">
      <p @click="handleCancelSubscriptionClick" class="cancel-button">
        Stop abonnement
      </p>
    </div>

    <div class="button-container">
      <secondary-button :disabled="data.isLoading" @click="handleCancel">
        {{ roomSettings?.subscription ? "Tilbage" : "Annuller" }}
      </secondary-button>
      <primary-button
        v-if="!roomSettings?.subscription"
        :disabled="data.isLoading"
        @click="handleConfirm"
        class="margin-left"
      >
        {{ data.isLoading ? "Subscribing..." : "Start abonnement" }}
      </primary-button>
      <primary-button
        v-else
        :disabled="data.isLoading || isEditAndContentHasntChanged"
        @click="handleConfirm"
        class="margin-left"
      >
        {{ data.isLoading ? "Updating..." : "Opdater" }}
      </primary-button>
    </div>
  </main>
</template>

<script setup>
import PrimaryButton from "../PrimaryButton.vue";
import SecondaryButton from "../SecondaryButton.vue";
import { useStore } from "vuex";
import { computed, reactive, onMounted } from "vue";
import { cloneDeep } from "lodash-es";
import { useToast } from "vue-toastification";
import FormInput from "./FormInput.vue";
import CheckboxInput from "./CheckboxInput.vue";
import baseFields from "./BaseFields.js";
import invoiceFields from "./InvoiceFields.js";

const emit = defineEmits(["cancel", "confirm", "cancel-subscription"]);
const store = useStore();
const toast = useToast();

const fieldNames = [
  ...baseFields.map((field) => field.formName),
  ...invoiceFields.map((field) => field.formName),
  "addressesAreTheSame",
  "invoiceEmail",
];

const roomSettings = computed(() => store.state.roomSettings);
const userDBInfo = computed(() => store.state.userDBInfo);
const user = computed(() => store.state.user);
const subscription = computed(() => store.state.subscription);
const isEditAndContentHasntChanged = computed(() => {
  const subscriptionValue = roomSettings.value?.subscription;
  const formData = data.form;
  const subscriptionData = subscription.value;

  return (
    subscriptionValue &&
    fieldNames.every((key) => subscriptionData?.[key] === formData[key])
  );
});

const data = reactive({
  form: {
    name: "",
    streetAddress: "",
    streetNo: "",
    zipCode: "",
    city: "",
    invoiceEmail: user.value.email,
    hasCancelled: false,
    cancelledDate: null,
    lastPayment: 0,
    ownderId: user.value.uid,
    subscriptionActivated: new Date().getTime(),
    addressesAreTheSame: true,
    invoiceName: "",
    invoiceStreetAddress: "",
    invoiceStreetNo: "",
    invoiceZipCode: "",
    invoiceCity: "",
  },
  isLoading: false,
  termsAndConditions: false,
  errors: fieldNames.map((field) => ({ [field]: false })),
});

onMounted(() => {
  if (
    subscription.value &&
    typeof subscription.value === "object" &&
    subscription.value?.hasCancelled === false
  ) {
    data.form = cloneDeep(subscription.value);
    data.termsAndConditions = true;
  }
});

const validateForm = () => {
  const fieldsToValidate = [
    "name",
    "streetAddress",
    "streetNo",
    "zipCode",
    "city",
    "invoiceEmail",
  ];

  if (!data.form.addressesAreTheSame) {
    fieldsToValidate.push(
      "invoiceName",
      "invoiceStreetAddress",
      "invoiceStreetNo",
      "invoiceZipCode",
      "invoiceCity"
    );
  }

  let isValid = true;

  fieldsToValidate.forEach((field) => {
    if (!data.form[field]) {
      data.errors[field] = true;
      isValid = false;
    } else {
      data.errors[field] = false;
    }
  });

  if (!data.termsAndConditions) {
    data.errors.termsAndConditions = true;
    isValid = false;
  } else {
    data.errors.termsAndConditions = false;
  }

  return isValid;
};

const handleCancel = () => {
  emit("cancel");
};

const handleCancelSubscriptionClick = () => {
  emit("cancel-subscription");
};

const handleConfirm = async () => {
  const isValid = validateForm();

  if (isValid) {
    data.isLoading = true;
    const newSubscription = !roomSettings.value?.subscription;

    if (newSubscription) {
      data.form.subscriptionActivated = new Date().getTime();
    }

    if (data.form.addressesAreTheSame) {
      const {
        invoiceCity,
        invoiceName,
        invoiceStreetAddress,
        invoiceStreetNo,
        invoiceZipCode,
        ...rest
      } = data.form;
      data.form = rest;
    }

    const success = await store.dispatch("createSubscription", {
      ...data.form,
      roomID: userDBInfo.value.laundryroom,
    });

    if (success) {
      if (newSubscription) {
        toast.success("Abonnementet er nu startet");
      } else {
        toast.success("Abonnement info er opdateret");
      }
      emit("confirm");
    } else {
      toast.error("Noget gik galt prøv igen senere, eller kontakt supporten");
    }
  }
};
</script>

<style scoped>
a:link {
  color: black;
}

a:visited {
  color: black;
}

.main {
  border-radius: 4px;
  padding-top: 40px;
  padding-left: 10%;
  padding-right: 10%;
  overflow-y: auto;
  height: calc(100vh - 40px);
}

h1 {
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 16px;
}

h2 {
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 16px;
  margin-top: 32px;
}

.margin-left {
  margin-left: 4px;
}

.button-container {
  margin-top: 16px;
  display: flex;
  margin-bottom: 100px;
  padding-bottom: 40px;
}

.margin-top-medium {
  margin-top: 8px;
}

.cancel-button {
  text-decoration: underline;
  color: #545454;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
