export const isOverMaxActiveBookings = (
  ownerId,
  existingBookings,
  maxActiveBookings
) => {
  if (!maxActiveBookings) return false;
  if (!existingBookings) return false;

  const currentTime = Date.now();

  let alreadyBooked = 0;

  for (const booking in existingBookings) {
    const { owner, end } = existingBookings[booking];
    if (owner !== ownerId) continue;
    if (currentTime < end) alreadyBooked += 1;
  }

  return alreadyBooked >= maxActiveBookings;
};
