<template>
  <div id="myModal" class="modal">

  <!-- Modal content -->
  <div class="modal-content">
    <span v-on:click="close" class="close">&times;</span>
    <div class="headerWrap">
      <div class="resavationText">
        Slet booking
      </div>
      <!-- {{deleteBookingObject.start}} - {{deleteBookingObject.end}}<br /> -->
      <div class="bookingWrap">
        <h3>
          {{bookingToDelete.startClock}} - {{bookingToDelete.endClock}}
        </h3>
        <div class="dateText">
          {{bookingToDelete.day}} {{bookingToDelete.date}} {{bookingToDelete.month}} {{bookingToDelete.year}}
        </div>
      </div>

      <!-- {{deleteBookingObject}}<br /> -->
      <!-- {{selectedDay}} -->
    </div>
    <div class="timeSelectorWrap">
      <button v-on:click="deleteBooking" class="btnReserver">
        Slet
      </button>
    </div>
  </div>
</div>
</template>

<script>
// import bus from '@/bus.js'
// import { db } from '@/firebase'
import { getDatabase, ref, child, remove } from "firebase/database"
import {mapGetters} from 'vuex'

export default {
  props: ['selectedDay'],
  data () {
    return {
      bookingTime: {startHour: 7, startMinutes: 15, endHour: 10, endMinutes: 0},
      iconScale: 1.8,
      dayNameLong: ['Søndag','Mandag','Tirsdag','Onsdag','Torsdag','Fredag','Lørdag'],
      monthName: ['Januar','Februar','Marts','April','Maj','Juni','Juli','August','September','Oktober','November','December'],
    }
  },
  methods: {
    close() {
      this.$store.commit('setShowDeleteBookingModal', false)
    },
    deleteBooking(){

      try {
        const db = getDatabase()
        const laundryRoom = this.userDBInfo.laundryroom
        const bookingId = this.deleteBookingObject.bookingId
        remove(ref(db, `laundryrooms/${laundryRoom}/bookings/${bookingId}`)).then(() => {
          this.$store.commit('setShowDeleteBookingModal', false)
        })
    
      } catch (error) {
        console.log(error)
      }
    }
  },
  computed: {  
    ...mapGetters({
      userDBInfo: 'getUserDBInfo',
      deleteBookingObject: 'getDeleteBookingObject'
    }),
    bookingToDelete: function() {
      let completeBooking = {}
      const startClock = this.deleteBookingObject.start
      const endClock = this.deleteBookingObject.end
      const fullDate = new Date(this.selectedDay)
      const date = fullDate.getDate()
      const day = this.dayNameLong[fullDate.getDay()]
      const month = this.monthName[fullDate.getMonth()]
      const year = fullDate.getFullYear()



      completeBooking = {
        startClock: startClock,
        endClock: endClock,
        fullDate: fullDate,
        day:day,
        date:date,
        month:month,
        year:year,}
      return completeBooking
    }
  }
}


</script>

<style scoped>
* {
  box-sizing: border-box;
}

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

/* The Modal (background) */
.modal {
  text-align: center;
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 30; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  max-width: 450px;
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.3); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  position: relative;
  border-radius: 4px;
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  width: 80%;
  max-width: 400px;
  min-width: 300px;
}

/* Header */
.headerWrap {
  margin: 16px 0px;
}

.resavationText {
  font-size: 1.7rem;
  font-weight: 400;
  color: rgba(0,0,0,0.8);
  margin-bottom: 5px;
}

.dateText {
  font-size: 0.8rem;
  color: rgba(0,0,0,0.6);
}

/* time selection */
.timeSelectorWrap {
  margin-top: 30px;
}
.timerWrap {
  font-size: 1.5rem;
  font-weight: bold;
  width: 250px;
  height: 50px;
  line-height: 50px;
  margin: 0 auto;
  border: 1px solid rgba(0,0,0,0.3);
  border-radius: 4px;
  color: rgba(0,0,0,0.8);
}

.clockWrap {
  width: 30px;
  display: inline-block;
  position: relative;
}
.hours {
  text-align: center;
  margin-right: -5px;
}
.minutes {
  text-align: center;
  margin-left: -5px;
}
.iconWrapPlus {
  cursor: pointer;
  width: 30px;
  position: absolute;
  top: -40px;
  color: rgba(0,0,0,0.4);
  /* left: 6px; */
}

.iconWrapMinus {
  cursor: pointer;
  width: 30px;
  position: absolute;
  top: 45px;
  color: rgba(0,0,0,0.4);
  /* left: 6px; */
}

.colon {
  display: inline-block;
}

/* The Close Button */
.close {
  top: 0px;
  right: 12px;
  position: absolute;
  color: #aaaaaa;
  /* float: right; */
  font-size: 35px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.btnReserver {
  cursor: pointer;
  font-weight: bold;
  font-size: 1.4rem;
  color: white;
  border-radius: 4px;
  height: 40px;
  line-height: 40px;
  /* background-color: #54bf67; */
  background-color: #00BAE0;
  margin: 0 auto;
  width: 250px;
}

.bookingWrap {
  display: inline-block;
  margin: 0 auto;
  margin-top: 10px;
  padding: 10px;
  border-radius: 4px;
}

.bookingWrap h3 {
  margin: 0;
}

@media screen and (min-width: 455px) {
  .modal {
    top: 15px;
    height: calc(100% - 30px);
    border-radius: 4px;
  }
}

</style>
