<template>
    <button :class="disabled ? 'disabled': ''" :disabled="disabled">
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: 'SecondaryButton',
    props: ['disabled'],
}
</script>

<style scoped>
button {
  padding: 10px 20px;
  cursor: pointer;
  font-weight: 400;
  border-radius: 4px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  width: 100%;
  margin-right: 4px;
  color: #838383;
  background-color: #d0cfcf33;
}
.disabled{
    opacity: 0.5;
}
</style>