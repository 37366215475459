<template>
  <div class="main">
    <h1>Timeslots</h1>
    <p>
      Timeslots er foruddefinerede vasketider, som brugerne af vaskerummet kan vælge mellem, når de skal bestille tid. Når Timeslots er aktiv, er det derfor ikke muligt for brugerne at bestemme start- og sluttidspunktet samt længden af deres bookinger.
    </p>
    <p>
      Vær opmærksom på, at hvis Timeslots er aktiv, vil de andre regler i vaskerummet blive nulstillet. Vaskerummets indstillinger for åbningstiden vil dog ikke blive ændret. Derfor vil eventuelle timeslots, som er uden for åbningstiden, ikke kunne reserveres af brugerne.
    </p>

    <h2>Start tid</h2>
    <VueDatePicker v-model="startTime" time-picker />

    <h2>Slut tid</h2>
    <VueDatePicker v-model="endTime" time-picker />

    <primary-button
      class="add-timeslot"
      :disabled="isLoading"
      @click="addTimeSlot"
    >
      Tilføj timeslot
    </primary-button>

    <h2>Timeslots</h2>
    <ul class="timeslots" v-if="timeslots.length">
      <li v-for="timeslot in timeslots">
        <span>
          {{ timeslot.startHour }}:{{ timeslot.startMinutes }}
          -
          {{ timeslot.endHour }}:{{ timeslot.endMinutes }}
        </span>
        <font-awesome-icon
          icon="trash-can"
          @click="selectedTimeslotForRemoval = timeslot"
        />
      </li>
    </ul>
    <p v-if="!timeslots.length">Der er ikke oprettet nogle timeslots.</p>

    <div class="action-buttons">
      <secondary-button :disabled="isLoading" @click="emit('close')">
        Annuller
      </secondary-button>
      <primary-button
        :disabled="isLoading"
        @click="handleChangePredefinedTimeslots"
      >
        Gem
      </primary-button>
    </div>
  </div>

  <transition name="fade">
    <timeslot-delete-modal
      v-if="selectedTimeslotForRemoval"
      :selectedTimeslotForRemoval="selectedTimeslotForRemoval"
      @close="selectedTimeslotForRemoval = null"
      @deleteTimeslot="deleteTimeslot"
    />
  </transition>
</template>

<script setup>
import PrimaryButton from "@/components/PrimaryButton.vue";
import SecondaryButton from "@/components/SecondaryButton.vue";
import TimeslotDeleteModal from "@/components/ChangePredefinedTimeslots/TimeslotDeleteModal.vue";
import { ref, computed, onMounted } from "vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import { useToast } from "vue-toastification";
import { getDatabase, ref as firebaseRef, set } from "firebase/database";
import { useStore } from "vuex";
import "@vuepic/vue-datepicker/dist/main.css";

const emit = defineEmits(["close"]);

const store = useStore();
const toast = useToast();

const startTime = ref();
const endTime = ref();
const selectedTimeslotForRemoval = ref(null);
const isLoading = ref(false);
const predefinedTimeslots = ref([]);

onMounted(() => {
  const timeslots = store.state.roomSettings.rules?.predefinedTimeslots || [];
  predefinedTimeslots.value = Object.values(timeslots);
});

const timeslots = computed(() => {
  return [...predefinedTimeslots.value]
    .sort((first, second) => {
      if (first.startHour === second.startHour)
        return first.startMinutes - second.startMinutes;
      return first.startHour - second.startHour;
    })
    .map(({ startHour, startMinutes, endHour, endMinutes, key }) => ({
      key,
      startHour: startHour.toString().padStart(2, "0"),
      startMinutes: startMinutes.toString().padStart(2, "0"),
      endHour: endHour.toString().padStart(2, "0"),
      endMinutes: endMinutes.toString().padStart(2, "0"),
    }));
});

const canInsertTimeslot = (timeslot) => {
  for (let i = 0; i < predefinedTimeslots.value.length; i++) {
    const existingTimeslot = predefinedTimeslots.value[i];
    if (
      (timeslot.startHour < existingTimeslot.endHour ||
        (timeslot.startHour === existingTimeslot.endHour &&
          timeslot.startMinutes < existingTimeslot.endMinutes)) &&
      (timeslot.endHour > existingTimeslot.startHour ||
        (timeslot.endHour === existingTimeslot.startHour &&
          timeslot.endMinutes > existingTimeslot.startMinutes))
    ) {
      return false;
    }
  }

  return true;
};

const addTimeSlot = () => {
  if (!startTime.value) {
    toast.error("Angiv starttid.");
    return;
  }

  if (!endTime.value) {
    toast.error("Angiv sluttid.");
    return;
  }

  const startMinutes = startTime.value.hours * 60 + startTime.value.minutes;
  const endMinutes = endTime.value.hours * 60 + endTime.value.minutes;

  if (startMinutes + 15 > endMinutes) {
    toast.error(
      "Længden på timeslots skal være mindst 15 minutter."
    );
    return;
  }

  const timeslot = {
    startHour: startTime.value.hours,
    startMinutes: startTime.value.minutes,
    endHour: endTime.value.hours,
    endMinutes: endTime.value.minutes,
  };

  if (canInsertTimeslot(timeslot)) {
    predefinedTimeslots.value.push(timeslot);
    startTime.value = null;
    endTime.value = null;
  } else {
    toast.error(
      "Timeslots må ikke overlappe hinanden."
    );
  }
};

const deleteTimeslot = () => {
  predefinedTimeslots.value = predefinedTimeslots.value.filter((timeslot) => {
    return !(
      timeslot.startHour == +selectedTimeslotForRemoval.value.startHour &&
      timeslot.startMinutes == +selectedTimeslotForRemoval.value.startMinutes
    );
  });

  selectedTimeslotForRemoval.value = null;
};

const handleChangePredefinedTimeslots = async () => {
  try {
    isLoading.value = true;

    const db = getDatabase();
    const laundryRoomId = store.state.userDBInfo.laundryroom;

    const slots = {};

    timeslots.value.forEach((timeslot, index) => {
      slots[`t${index + 1}`] = {
        startHour: +timeslot.startHour,
        startMinutes: +timeslot.startMinutes,
        endHour: +timeslot.endHour,
        endMinutes: +timeslot.endMinutes,
      };
    });

    await set(
      firebaseRef(
        db,
        `laundryrooms/${laundryRoomId}/settings/rules/predefinedTimeslots`
      ),
      slots
    );

    store.dispatch("disableRulesExceptFor", "predefinedTimeslots");

    toast.success("Timeslots er blevet opdateret.");

    store.commit("setRules", {
      ...store.state.roomSettings.rules,
      predefinedTimeslots: Object.keys(slots).length ? slots : null,
    });
  } catch (error) {
    toast.error("Fejl: Kan ikke gemme timeslot.");
  } finally {
    isLoading.value = false;
  }

  emit("close");
};
</script>

<style scoped>
.main {
  padding: 30px 10%;
  margin: auto;
}
h1 {
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0px;
}
h2 {
  margin-top: 20px;
  margin-bottom: 6px;
  color: #545454;
  font-weight: 600;
  font-size: 1rem;
}
p {
  margin-top: 24px;
  margin-bottom: 6px;
  font-size: 1rem;
  color: #545454;
}
.action-buttons {
  margin-top: 32px;
  display: flex;
  gap: 20px;
}
.add-timeslot {
  margin-top: 20px;
  width: 60%;
  margin-right: 0;
  margin-left: auto;
  display: block;
}
.timeslots {
  list-style: none;
  padding: 0;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.timeslots li {
  padding: 10px;
  display: flex;
  justify-content: space-between;
}
.timeslots li svg {
  color: rgba(115, 115, 115, 0.5);
  cursor: pointer;
}
.timeslots li svg:hover {
  color: rgb(215, 83, 83);
}
</style>
